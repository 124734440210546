import React, { Component } from 'react';
import { withRouter } from 'react-router';

import './Dashboard.scss';
import MainLayout from '../layout/MainLayout';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { withMediaQuery, withUser } from '../../utils/ReactWrappers';
import UnfilledMeetingList from '../lists/UnfilledMeetingList';
import MeetingList from '../lists/MeetingList';
import ReminderList from '../lists/ReminderList';

import { getText } from '../../utils/LocaleHelper';
import { capitalize } from 'lodash';
import ProjectList from '../lists/ProjectList';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reminderType: 0,
        };
    }

    static importantReminders = [
        {
            label: getText('menu-reminders'),
        },
        {
            label: getText('menu-projects'),
        },
    ];

    render() {
        const { user } = this.props;

        return (
            <MainLayout title={getText('dashboard-welcome') + ' ' + user.first_name}>
                {this.renderContent()}
            </MainLayout>
        );
    }

    renderContent() {
        return (
            <Box m={1}>
                <Grid container spacing={1}>
                    <Grid item lg={4} md={6} sm={12}>
                        {this.renderMeetings()}
                    </Grid>
                    <Grid item lg={4} md={6} sm={12}>
                        {this.renderReminders()}
                    </Grid>
                    <Grid item lg={4} md={6} sm={12}>
                        {this.renderUnfilledMeetings()}
                    </Grid>
                </Grid>
            </Box>
        );
    }

    renderUnfilledMeetings() {
        return (
            <Card>
                <CardContent>
                    <Typography component="h2" variant="h5">
                        {capitalize(getText('unfilled-meeting-result_plural'))}
                    </Typography>
                    <UnfilledMeetingList
                        oneColumn={true}
                        linkToClient={true}
                        renderPastMeetings={false}
                        showSearch={false}
                        showHeader={false}
                        daysLimit={7}
                    />
                </CardContent>
            </Card>
        );
    }

    renderMeetings() {
        return (
            <Card>
                <CardContent>
                    <Typography component="h2" variant="h5">
                        {capitalize(getText('meeting-futur_result_plural'))}
                    </Typography>
                    <MeetingList
                        oneColumn={true}
                        linkToClient={true}
                        renderPastMeetings={false}
                        showSearch={false}
                        showHeader={false}
                        daysLimit={7}
                    />
                </CardContent>
            </Card>
        );
    }

    tabProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    renderReminders() {
        const { reminderType } = this.state;
        const currYear = new Date().getFullYear();
        return (
            <Card>
                <CardContent>
                    <Typography component="h2" variant="h5">
                        {capitalize(getText('reminder-important_plural'))}
                    </Typography>
                    <Tabs
                        value={reminderType}
                        indicatorColor="primary"
                        scrollButtons="auto"
                        variant="scrollable"
                        aria-label="scrollable auto tabs example">
                        {Dashboard.importantReminders.map((reminder, index) => {
                            return (
                                <Tab
                                    key={index}
                                    label={reminder.label}
                                    {...this.tabProps(index)}
                                    onClick={() => this.setState({ reminderType: index })}
                                />
                            );
                        })}
                    </Tabs>
                    {reminderType === 0 ? (
                        <ReminderList
                            daysLimit={37} // 5 weeks
                            oneColumn={true}
                            linkToClient={true}
                            showSearch={false}
                            showHeader={false}
                        />
                    ) : reminderType === 1 ? (
                        <ProjectList
                            params={`date_lte=${currYear}&status_in=future&status_in=in_progress`}
                            withoutControls
                            fullWidth
                            hideOnEmptyCategory
                        />
                    ) : null}
                </CardContent>
            </Card>
        );
    }

    onViewMeetingsClick() {
        const { history } = this.props;

        history.push('/meetings');
    }
}

export default withRouter(
    withUser(
        withMediaQuery([
            [
                'isDesktop',
                (theme) => theme.breakpoints.up('lg'),
                {
                    defaultMatches: true,
                },
            ],
        ])(Dashboard),
    ),
);

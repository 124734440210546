import React, { Component } from 'react';
import { withRouter } from 'react-router';
import AdminLayout from '../../layout/AdminLayout';
import { withUser } from '../../../utils/ReactWrappers';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Api from '../../../api/Api';
import Loader from '../../widgets/Loader';
import './ActivityHistory.css';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

class ActivityHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            activities: null,
            clients: [],
            users: [],
            selectedClient: 'all',
            selectedUser: 'all',
            search: '',
            page: 0,
            start: 0,
            totalActivities: 0,
            fetchedPages: [0],
        };
        this.pageSize = 50;
        this.searchTimeout = null;
    }

    getBreadcrumbs() {
        return [
            {
                label: 'Activities',
                link: '/admin/activities',
            },
        ];
    }

    renderCustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    renderPageControls() {
        const { selectedClient, selectedUser, clients, users, search } = this.state;

        return (
            <Grid className="filter-container">
                <Grid>
                    <Typography className="filter-label" variant="subtitle1">
                        By:
                    </Typography>
                    <Select
                        className="filter-select"
                        value={selectedUser}
                        onChange={this.handleUserFilterChange}>
                        <MenuItem value={'all'}>All</MenuItem>
                        {users.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                                {user.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid>
                    <Typography className="filter-label" variant="subtitle1">
                        Client:
                    </Typography>
                    <Select
                        className="filter-select"
                        value={selectedClient}
                        onChange={this.handleClientFilterChange}>
                        <MenuItem value="all">All</MenuItem>
                        {clients.map((client) => (
                            <MenuItem key={client.id} value={client.id}>
                                {client.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid className="filter-search">
                    <Typography className="filter-label" variant="subtitle1">
                        Search:
                    </Typography>
                    <TextField value={search} onChange={this.handleSearchChange} />
                    <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        color="primary"
                        className="filter-search-button"
                        onClick={this.onSearchSubmit}>
                        Search
                    </Button>
                </Grid>
            </Grid>
        );
    }

    handleClientFilterChange = (event) => {
        this.setState({
            selectedClient: event.target.value,
        });
    };

    handleUserFilterChange = (event) => {
        this.setState({
            selectedUser: event.target.value,
        });
    };

    handleSearchChange = (event) => {
        this.setState({
            search: event.target.value,
        });
    };

    handlePageChange = (value) => {
        this.setState({
            page: value,
            start: value * this.pageSize,
        });
    };

    onSearchSubmit = () => {
        const { selectedClient, selectedUser } = this.state;
        const clientQuery = selectedClient !== 'all' ? `&client_id=${selectedClient}` : '';
        const userQuery = selectedUser !== 'all' ? `&user_id=${selectedUser}` : '';
        const searchQuery = this.state.search ? `&_q=${this.state.search}` : '';

        this.setState({
            isLoading: true,
        });

        Api.call('get', `/activities/count?${clientQuery}${userQuery}${searchQuery}`).then(
            (response) => {
                this.setState({
                    totalActivities: response.data,
                });
            },
        );

        Api.call(
            'get',
            `/activities?_sort=date_time:DESC&_start=0&_limit=${this.pageSize.toString()}${clientQuery}${userQuery}${searchQuery}`,
        ).then((response) => {
            this.setState({
                activities: response.data,
                isLoading: false,
                page: 0,
                start: 0,
                fetchedPages: [],
            });
        });
    };

    componentDidMount() {
        Promise.allSettled([
            Api.call('get', `/activities?_sort=date_time:DESC&_start=${this.state.start}&_limit=${this.pageSize}`),
            Api.call('get', '/clients?_limit=0&_sort=name'),
            Api.call('get', '/users'),
            Api.call('get', '/activities/count'),
        ]).then((results) => {
            if (results[0].status === 'fulfilled') {
                this.setState({
                    activities: results[0].value.data,
                    isLoading: false,
                });
            }

            if (results[1].status === 'fulfilled') {
                this.setState({
                    clients: results[1].value.data,
                });
            }

            if (results[2].status === 'fulfilled') {
                const formattedUsers = results[2].value.data
                    ?.filter((user) => {
                        // get only confirmed and not blocked users
                        return user.confirmed && !user.blocked;
                    })
                    .map((user) => {
                        const { id, first_name, last_name } = user;
                        return {
                            id,
                            name: `${first_name} ${last_name}`,
                        };
                    });

                this.setState({
                    users: formattedUsers,
                });
            }

            if (results[3].status === 'fulfilled') {
                this.setState({
                    totalActivities: results[3].value.data,
                });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedClient, selectedUser } = this.state;

        const newFilter =
            selectedClient !== prevState.selectedClient || selectedUser !== prevState.selectedUser;

        if (
            newFilter ||
            (prevState.page < this.state.page && !this.state.fetchedPages.includes(this.state.page))
        ) {
            this.setState({
                isLoading: true,
            });

            let start = this.state.start;
            let page = this.state.page;

            if (newFilter) {
                start = 0;
                page = 0;
            }

            const clientQuery = selectedClient !== 'all' ? `&client_id=${selectedClient}` : '';
            const userQuery = selectedUser !== 'all' ? `&user_id=${selectedUser}` : '';
            const searchQuery = this.state.search ? `&_q=${this.state.search}` : '';

            if (newFilter) {
                Api.call('get', `/activities/count?${clientQuery}${userQuery}`).then((response) => {
                    this.setState({
                        totalActivities: response.data,
                    });
                });
            }

            Api.call(
                'get',
                `/activities?_sort=date_time:DESC&_start=${start}&_limit=${this.pageSize}${clientQuery}${userQuery}${searchQuery}`,
            ).then((response) => {
                const prevActivities = this.state.activities;

                const newFetchedPages = [...this.state.fetchedPages];

                if (!newFetchedPages.includes(this.state.page)) {
                    newFetchedPages.push(this.state.page);
                }

                let newActivities = [...prevActivities, ...response.data];

                if (newFilter) {
                    newActivities = [...response.data];
                }

                this.setState({
                    activities: newActivities,
                    isLoading: false,
                    fetchedPages: newFilter ? [] : newFetchedPages,
                    page: page,
                    start: page * this.pageSize,
                });
            });
        }
    }

    render() {
        const { isLoading, activities } = this.state;

        const formattedActivities = activities?.map((activity) => {
            return {
                ...activity,
                // for formatting purposes
                details: activity.details.replace(/\;;/g, '<br><br/>'),
                date_time: new Date(activity.date_time).toLocaleString(),
                ip_address:
                    !activity.ip_address || activity.ip_address === 'undefined'
                        ? 'N/D'
                        : activity.ip_address,
            };
        });

        const columns = [
            { field: 'user_name', headerName: 'By', width: 180 },
            { field: 'client_name', headerName: 'Client', width: 220 },
            {
                field: 'type',
                headerName: 'Type',
                width: 100,
                renderCell: (params) => {
                    const color =
                        params.value === 'create'
                            ? 'green'
                            : params.value === 'update'
                            ? 'yellow'
                            : params.value === 'delete'
                            ? 'red'
                            : 'blue';

                    return <div className={`activity-type ${color}-type`}>{params.value}</div>;
                },
            },
            {
                field: 'details',
                headerName: 'Details',
                width: 400,
                renderCell: (params) => {
                    return <div dangerouslySetInnerHTML={{ __html: params.value }} />;
                },
            },
            { field: 'date_time', headerName: 'Date', width: 180 },
            { field: 'ip_address', headerName: 'IP Adress', width: 160 },
        ];

        return (
            <AdminLayout title="Activities" breadcrumbs={this.getBreadcrumbs()}>
                {!isLoading ? (
                    <>
                        {this.renderPageControls()}
                        <DataGrid
                            rows={formattedActivities}
                            columns={columns}
                            getRowHeight={() => 'auto'}
                            rowCount={this.state.totalActivities}
                            pageSize={this.pageSize}
                            page={this.state.page}
                            rowsPerPageOptions={[this.pageSize]}
                            onPageChange={(params) => {
                                this.handlePageChange(params);
                            }}
                            sx={{
                                '& .MuiDataGrid-main': {
                                    height: 500,
                                },
                                '& .MuiDataGrid-cell': {
                                    padding: '10px',
                                    lineHeight: '1',
                                },
                            }}
                            components={{
                                Toolbar: this.renderCustomToolbar,
                            }}
                        />
                    </>
                ) : (
                    <Loader />
                )}
            </AdminLayout>
        );
    }

    impersonateUser(id) {
        Api.impersonate(id);
    }
}

export default withRouter(withUser(ActivityHistory));

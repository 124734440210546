import React, { Component } from 'react';
import { withRouter } from 'react-router';
import AdminLayout from '../../../layout/AdminLayout';
import { withUser } from '../../../../utils/ReactWrappers';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Api from '../../../../api/Api';
import Loader from '../../../widgets/Loader';

class ClientsPerformance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            clients: null,
        };
    }

    getBreadcrumbs() {
        return [
            {
                label: 'Reports',
                url: '/reports',
            },
            {
                label: 'Clients Performance',
                url: '/reports/clients-performance',
            },
        ];
    }

    renderCustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    componentDidMount() {
        Promise.allSettled([Api.call('get', '/clients/score')]).then((results) => {
            if (results[0].status === 'fulfilled') {
                this.setState({
                    clients: results[0].value.data,
                    isLoading: false,
                });
            }
        });
    }

    renderContent() {
        const { isLoading, clients } = this.state;

        if (isLoading) {
            return <Loader />;
        }

        const formattedClients = clients.map((client) => {
            const { score } = client;

            return {
                id: client.id,
                client: client.name,
                salesRep: client.sales_representative
                    ? client.sales_representative.first_name +
                      ' ' +
                      client.sales_representative.last_name
                    : '',
                population: client.population ?? '',
                salesConversion: score?.total_sales_conversion,
                salesSkus: score?.total_sales_skus,
                technicalVisits: score?.total_technical_visits,
                technicalEquipment: score?.total_technical_equipment,
                technicalContacts: score?.total_technical_contacts,
                salesY2YRatio: score?.total_sales_y2y_ratio,
                technicalProjects: score?.total_technical_projects,
                percentageVisitHit: score?.percentage_visit_hit,
                percentageFilledEquipment: score?.percentage_filled_equipment,
                percentageProjectWinLost: score?.percentage_project_win_lost,
                totalScore: score?.total,
            };
        });

        const columns = [
            { field: 'id', headerName: 'ID', width: 60 },
            { field: 'client', headerName: 'Client', width: 150 },
            { field: 'salesRep', headerName: 'Sales rep', width: 150 },
            { field: 'population', headerName: 'Population', width: 85 },
            { field: 'salesConversion', headerName: 'Sales conversion', width: 100 },
            { field: 'salesSkus', headerName: 'Sales Skus', width: 100 },
            { field: 'technicalVisits', headerName: 'Technical Visits', width: 100 },
            { field: 'technicalEquipment', headerName: 'Technical Equipment', width: 100 },
            { field: 'technicalContacts', headerName: 'Technical Contacts', width: 100 },
            { field: 'salesY2YRatio', headerName: 'Sales Y2Y Ratio', width: 100 },
            { field: 'technicalProjects', headerName: 'Technical Projects', width: 100 },
            {
                field: 'percentageVisitHit',
                headerName: 'Visit Hit',
                width: 100,
                renderCell: (params) => {
                    if (
                        params.row.percentageVisitHit === null ||
                        params.row.percentageVisitHit === undefined
                    ) {
                        return null;
                    }
                    return params.row.percentageVisitHit + '%';
                },
            },
            {
                field: 'percentageFilledEquipment',
                headerName: 'Filled Equipment',
                width: 100,
                renderCell: (params) => {
                    if (
                        params.row.percentageFilledEquipment === null ||
                        params.row.percentageFilledEquipment === undefined
                    ) {
                        return null;
                    }
                    return params.row.percentageFilledEquipment + '%';
                },
            },
            {
                field: 'percentageProjectWinLost',
                headerName: 'Project Win Lost',
                width: 100,
                renderCell: (params) => {
                    if (
                        params.row.percentageProjectWinLost === null ||
                        params.row.percentageProjectWinLost === undefined
                    ) {
                        return null;
                    }
                    return params.row.percentageProjectWinLost + '%';
                },
            },
            {
                field: 'totalScore',
                headerName: 'Total Score',
                width: 90,
                cellClassName: (params) => {
                    const client = clients.find((client) => client.id === params.id);
                    const score = client?.score;
                    if (score) {
                        const { color } = score;
                        return color;
                    }
                },
            },
        ];

        return (
            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={formattedClients}
                    columns={columns}
                    components={{
                        Toolbar: this.renderCustomToolbar,
                    }}
                />
            </div>
        );
    }

    render() {
        return (
            <AdminLayout breadcrumbs={this.getBreadcrumbs()} title="Clients Performance">
                {this.renderContent()}
            </AdminLayout>
        );
    }
}

export default withRouter(withUser(ClientsPerformance));

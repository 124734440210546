let alertMethod = null;
let confirmMethod = null;

export function setAlertMethod(method) {
    alertMethod = method;
}

export function setConfirmMethod(method) {
    confirmMethod = method;
}

/**
 * 
 * @param {string} type success, error, warning
 * @param {string} message 
 */
export function alert(type, message) {
    alertMethod(type, message);
}

/**
 * 
 * @param {Object} data 
 * {
 *    title: null,
 *    text: '',
 *    confirmLabel: 'OK',
 *    cancelLabel: 'Cancel',
 *    onClose: (confirm) => {},
 * }
 */
export function confirm(data) {
    confirmMethod(data);
}
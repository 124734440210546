import React, {Component} from 'react';
import { withRouter } from 'react-router';
import AdminLayout from '../../../layout/AdminLayout';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

class AdminReportsIndex extends Component {

    getBreadcrumbs() {
        return [
            {
                'label': 'Reports',
                'url': '/reports',
            },
        ]
    }

    renderReportList() {
        const reports = [
            {
                'name': 'Missing Fidelio IDs',
                'url': '/missing-fidelio-ids',
            },
            {
                'name': 'Clients Performance',
                'url': '/clients-performance',
            },
            {
                'name': 'Reps Performance',
                'url': '/reps-performance',
            }
        ]

        return (
            <List>
                {reports.map((report) => (
                    <ListItem key={report.url} button style={{padding: '9px 18px'}} onClick={this.openReport.bind(this, report)}>
                        <ListItemText primary={report.name} />
                    </ListItem>
                ))}
            </List>
        )
    }
    
    render() {
        return (
            <AdminLayout breadcrumbs={this.getBreadcrumbs()} title="Reports">
                {this.renderReportList()}
            </AdminLayout>
        );
    }

    openReport(report) {
        const { history } = this.props;

        history.push('/admin/reports' + report.url);
    }
}

export default withRouter(AdminReportsIndex);
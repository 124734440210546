import React, {Component} from 'react';
import { Redirect } from 'react-router';
import { withRouter } from "react-router";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Api from '../../api/Api';

import logo from '../../images/1200_Logo_Noir.svg';
import { parseErrors } from '../../utils/ResponseHelper';
import { withUser } from '../../utils/ReactWrappers';

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			identifier: '',
			password: '',
			error: null,
		}
	}

	render() {
		const {error} = this.state;

		// If connected, redirect to dashboard
		if(this.props.user) {
			return (
				<Redirect
					to={{
						pathname: "/dashboard",
					}}
				/>
			)
		}

		const logoStyle = {
			width: '250px',
			maxWidth: '55%',
		}

		return (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div style={{ padding: 50 }}>
					<Grid container justifyContent="center" alignItems="center" margin={3}>
						<img src={logo} alt="Logo" style={logoStyle} />
					</Grid>
				</div>
				<Card>
					<CardContent>
						<Typography component="h1" variant="h5">
							Connexion
						</Typography>
						<form noValidate onSubmit={this.onFormSubmit.bind(this)}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								label="Courriel"
								autoFocus
								value={this.state.identifier}
								onChange={this.onInputChange.bind(this, 'identifier')}
								error={error !== null}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								label="Mot de passe"
								type="password"
								value={this.state.password}
								onChange={this.onInputChange.bind(this, 'password')}
								error={error !== null}
							/>

							{error !== null ? <Typography color="error">{error}</Typography> : null}

							<Grid container justifyContent="space-between" alignItems="center">
								<Grid item>
									<FormControlLabel
										control={<Checkbox value="remember" color="primary" />}
										label="Remember me"
									/>
								</Grid>
								<Grid item>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
									>
										Se connecter
									</Button>
								</Grid>
							</Grid>
							<Grid container justifyContent="flex-end">
								<Grid item>
									<Link href="#" variant="body2">
										J'ai oublié mon mot de passe
									</Link>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>
			</Container>
		);
	}

	onInputChange(field, e) {
		const state = {};
		
		state[field] = e.target.value;

		this.setState(state);
	}

	onFormSubmit(e) {
		const { history } = this.props;

		e.preventDefault();

		this.setState({
			isLoading: true,
			error: null,
		});

		Api.login(this.state.identifier, this.state.password)
			.then(connected => {
				if(connected === true) {
					history.push('/dashboard');
				}
			})
			.catch(error => {
				console.log(error)
                // Handle error.
				const message = parseErrors(error.response.data?.message)[0] || JSON.stringify(error.response);

				this.setState({
					error: message,
				});

                return false;
            })
			.finally(response => {
                this.setState({
					isLoading: false,
				});
			});
	}
}

export default withRouter(withUser(Login));

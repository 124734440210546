import * as _ from 'lodash';
import messages from '../data/messages';

let currentLang = 'fr';

export function setLang(lang) {
    currentLang = lang;
}

export function getLang() {
    return currentLang;
}

export function getText(slug) {
    return _.get(messages, slug + '.' + currentLang, slug);
}
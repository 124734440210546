import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import moment from 'moment';

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';

import { updateRelation, deleteRelation } from '../../utils/ObjectHelper';

import Api from '../../api/Api';
import ReminderForm from '../form/ReminderForm';
import SearchResults from '../layout/SearchResults';
import ReminderCard from '../cards/ReminderCard';
import { splitListItems } from '../../utils/DatedListHelper';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { upperFirst } from '../../utils/StringHelper';
import ListFilter from './ListFilter';
import { filterList } from '../../utils/ListHelper';
import { getLang, getText } from '../../utils/LocaleHelper';
import { confirm } from '../../utils/UIHelper';
import EmptyList from '../layout/EmptyList';
import { withUser } from '../../utils/ReactWrappers';
import Loader from '../widgets/Loader';
import HttpHelper from '../../utils/HttpHelper';

class ReminderList extends Component {
    static defaultProps = {
        oneColumn: false,
        renderPastReminders: true,
        labelSingle: null,
        labelPlural: null,
        buttonLabel: null,
        onButtonClick: null,
        clientId: null,
        linkToClient: false,
        showSearch: true,
        showHeader: true,
        daysLimit: 0, // 0 means no limit
        reminders: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            reminders: props.reminders,
            reminderFilters: null,
            clientFilters: null,
            reminderFormOpened: false,
            reminderFormData: {
                id: null,
                clientId: null,
            },
            canEdit: props.user.role.type !== 'consultation',
        };
    }

    render() {
        const { showSearch, clientId } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            return <Loader />;
        }

        const types = ['demo', 'follow_up', 'courtesy', 'commissioning', 'other'];

        return (
            <Box>
                {!showSearch ? null : (
                    <Grid container spacing={0} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <ListFilter
                                uniqueSearchType="reminder"
                                onFilterUpdate={this.onFilterUpdate.bind(this)}
                                filtersProps={this.state.reminderFilters}
                            />
                        </Grid>
                        {clientId ? null : (
                            <Grid item>
                                <ListFilter
                                    uniqueSearchType="client"
                                    filtersProps={this.state.clientFilters}
                                    onFilterUpdate={this.onFilterUpdate.bind(this)}
                                    iconElement={<StoreMallDirectoryIcon />}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}

                {this.renderReminders()}
                {this.renderReminderForm()}
            </Box>
        );
    }

    onFilterUpdate(filters, type, emptyValue) {
        const { reminderFilters, clientFilters } = this.state;

        this.setState({
            reminderFilters: type === 'reminder' ? filters : emptyValue ? reminderFilters : '',
            clientFilters: type === 'client' ? filters : emptyValue ? clientFilters : '',
        });
    }

    renderReminderForm() {
        const { reminderFormOpened, reminderFormData } = this.state;

        if (!reminderFormOpened) {
            return null;
        }

        return (
            <ReminderForm
                closeForm={this.closeReminderForm.bind(this)}
                updateReminder={this.updateReminder.bind(this)}
                {...reminderFormData}
            />
        );
    }

    renderReminders() {
        let { reminders, clientFilters, reminderFilters, canEdit } = this.state;
        let { oneColumn, renderPastReminders, showSearch, showHeader, divideByMonth } = this.props;

        let filteredReminders = reminders;

        // Filter reminders
        if (showSearch && reminderFilters) {
            filteredReminders = filterList(reminders, reminderFilters, [
                'client.name',
                'client.address.city',
                'description',
                'date',
            ]);

            filteredReminders = filteredReminders?.filter((reminder) => {
                if (!reminderFilters.type) {
                    return true;
                }

                return reminder.type === reminderFilters.type;
            });
        }

        // Filter clients
        if (showSearch && clientFilters) {
            filteredReminders = filterList(reminders, clientFilters, ['client.name']);
        }

        // Sort reminders
        const { pastListItems, futurListItems } = splitListItems(filteredReminders);

        let pastReminders = pastListItems;
        let futurReminders = futurListItems;

        let loopDate = null;

        const columnsWidth = oneColumn
            ? { xs: 12 }
            : {
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 4,
              };

        if (!renderPastReminders) {
            pastReminders = [];
        }

        return (
            <Box>
                {!showHeader ? null : (
                    <Box m={1}>
                        <SearchResults
                            value={
                                renderPastReminders
                                    ? filteredReminders?.length
                                    : futurReminders?.length
                            }
                            labelSingle={
                                this.props.labelSingle || getText('reminder-result_singular')
                            }
                            labelPlural={
                                this.props.labelPlural || getText('reminder-result_plural')
                            }
                            buttonLabel={
                                canEdit ? this.props.buttonLabel || getText('action-create') : null
                            }
                            onButtonClick={
                                this.props.onButtonClick || this.openReminderForm.bind(this, null)
                            }
                        />
                    </Box>
                )}
                <Grid container>
                    {(renderPastReminders ? filteredReminders?.length : futurReminders.length) >
                    0 ? null : (
                        <EmptyList message={getText('reminder-empty_list')} />
                    )}
                    {futurReminders.map((reminder, i) => {
                        if (loopDate !== reminder.date) {
                            loopDate = reminder.date;
              
                            return (
                                <Fragment key={reminder.id}>
                                    {
                                        this.checkIfFirstOfMonth(futurReminders, reminder) && (
                                            <> 
                                                <Grid item sm={12}>
                                                    <Box m={1} mt={4}>
                                                        <Typography
                                                            component="h3"
                                                            style={{
                                                                textTransform: 'uppercase',
                                                            }}>
                                                            {getText(moment(loopDate).format('MMMM').toLowerCase())}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                            </>
                                        )}
                                    <Grid item {...columnsWidth}>
                                        <ReminderCard
                                            canEdit={canEdit}
                                            showDate={true}
                                            reminder={reminder}
                                            openReminderForm={this.openReminderForm.bind(
                                                this,
                                                reminder._id,
                                            )}
                                            linkToClient={this.props.linkToClient}
                                            deleteReminder={this.deleteReminder.bind(this)}
                                        />
                                    </Grid>
                                </Fragment>
                            );
                        }

                        return (
                            <Grid item key={reminder.id} {...columnsWidth}>
                                <ReminderCard
                                    canEdit={canEdit}
                                    showDate={true}
                                    reminder={reminder}
                                    openReminderForm={this.openReminderForm.bind(
                                        this,
                                        reminder._id,
                                    )}
                                    linkToClient={this.props.linkToClient}
                                    deleteReminder={this.deleteReminder.bind(this)}
                                />
                            </Grid>
                        );
                    })}
                    {pastReminders.map((reminder, i) => {
                        if (i === 0) {
                            return (
                                <Fragment key={i}>
                                    <Grid item sm={12}>
                                        <Box m={1} mt={2}>
                                            <Typography variant="subtitle1" component="h2">
                                                {getText('reminder-old')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item {...columnsWidth}>
                                        <ReminderCard
                                            canEdit={canEdit}
                                            showDate={true}
                                            reminder={reminder}
                                            openReminderForm={this.openReminderForm.bind(
                                                this,
                                                reminder._id,
                                            )}
                                            linkToClient={this.props.linkToClient}
                                            deleteReminder={this.deleteReminder.bind(this)}
                                        />
                                    </Grid>
                                </Fragment>
                            );
                        }

                        return (
                            <Grid item key={i} {...columnsWidth}>
                                <ReminderCard
                                    key={i}
                                    canEdit={canEdit}
                                    showDate={true}
                                    reminder={reminder}
                                    openReminderForm={this.openReminderForm.bind(
                                        this,
                                        reminder._id,
                                    )}
                                    linkToClient={this.props.linkToClient}
                                    deleteReminder={this.deleteReminder.bind(this)}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        );
    }

    formatDate(loopDate) {
        const fnsLocales = {
            fr: fr,
            en: null,
        };

        try {
            let date = Date.parse(loopDate + ' EST');

            return (
                loopDate.replaceAll('-', '/') +
                ' - ' +
                upperFirst(format(date, 'EEEE', { locale: fnsLocales[getLang()] }))
            );
        } catch (err) {
            return loopDate.replaceAll('-', '/');
        }
    }

    checkIfFirstOfMonth(reminders, reminder) {
        const selectedMonth = moment(reminder.date).month();
        const selectedYear = moment(reminder.date).year();
        const selectedDay = moment(reminder.date).date();
        const selectedIndex = reminder._id;

        let isFirstOfMonth = true;

        reminders.forEach((r) => {
            // dont check the same reminder
            if (r._id === selectedIndex) return;

            const reminderMonth = moment(r.date).month();
            const reminderYear = moment(r.date).year();
            const reminderDay = moment(r.date).date();

            if (
                reminderMonth === selectedMonth &&
                reminderYear === selectedYear &&
                reminderDay < selectedDay
            ) {
                isFirstOfMonth = false;
            }
        });

        return isFirstOfMonth;
    }

    componentDidMount() {
        const { daysLimit, clientId } = this.props;

        const promises = [Api.call('get', '/equipment-types?_limit=0')];

        // Fetch reminders only if not set as props
        if (this.state.reminders === null) {
            const filters = {
                _limit: '0',
            };

            if (clientId) {
                filters['client'] = clientId;
            }

            if (daysLimit > 0) {
                const dateLimit = moment().add(daysLimit, 'day').format('YYYY-MM-DD');
                const today = moment().format('YYYY-MM-DD');

                filters['date_lte'] = dateLimit;
                filters['date_gte'] = today;
            }

            promises.push(
                Api.call('get', `/client-reminders?${HttpHelper.toQueryString(filters)}`),
            );
        }

        Promise.allSettled(promises).then((results) => {
            const state = {
                isLoading: false,
                opened: {},
            };

            if (results[0].status === 'fulfilled') {
                state.types = results[0].value.data;
            }

            if (results.length > 1 && results[1].status === 'fulfilled') {
                state.reminders = results[1].value.data;
            }

            this.setState(state);
        });
    }

    // Forms
    openReminderForm(id, e) {
        this.setState({
            reminderFormOpened: true,
            reminderFormData: {
                id: id,
                clientId: this.props.clientId,
            },
        });
    }

    closeReminderForm(e) {
        this.setState({
            reminderFormOpened: false,
        });
    }

    updateReminder(reminder) {
        this.setState(updateRelation(this.state, 'reminders', reminder));
    }

    deleteReminder(id) {
        confirm({
            title: getText('reminder-delete_title'),
            text: getText('reminder-delete_message'),
            confirmLabel: getText('action-yes'),
            cancelLabel: getText('action-no'),
            onClose: (confirm) => {
                if (confirm) {
                    Api.call('delete', '/client-reminders/' + id).then((response) => {
                        this.setState(deleteRelation(this.state, 'reminders', id));
                    });
                }
            },
        });
    }
}

export default withRouter(withUser(ReminderList));

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import moment from 'moment';

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';

import { updateRelation, deleteRelation } from '../../utils/ObjectHelper';

import Api from '../../api/Api';
import MeetingForm from '../form/MeetingForm';
import SearchResults from '../layout/SearchResults';
import UnfilledMeetingCard from '../cards/UnfilledMeetingCard';
import { sortListItems } from '../../utils/DatedListHelper';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { upperFirst } from '../../utils/StringHelper';
import ListFilter from './ListFilter';
import { filterList } from '../../utils/ListHelper';
import { getLang, getText } from '../../utils/LocaleHelper';
import { confirm } from '../../utils/UIHelper';
import EmptyList from '../layout/EmptyList';
import { withUser } from '../../utils/ReactWrappers';
import Loader from '../widgets/Loader';
import HttpHelper from '../../utils/HttpHelper';

class UnfilledMeetingList extends Component {
    static defaultProps = {
        oneColumn: false,
        renderPastMeetings: true,
        labelSingle: null,
        labelPlural: null,
        buttonLabel: null,
        clientId: null,
        onButtonClick: null,
        linkToClient: false,
        showSearch: true,
        showHeader: true,
        daysLimit: 0, // 0 means no limit
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            meetings: null,
            types: null,
            meetingFilters: null,
            clientFilters: null,
            meetingFormOpened: false,
            meetingFormData: {
                id: null,
                clientId: null,
            },
            canEdit: props.user.role.type !== 'consultation',
        };
    }

    render() {
        const { showSearch, clientId } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            return <Loader />;
        }

        const types = ['demo', 'follow_up', 'courtesy', 'commissioning', 'other'];

        return (
            <Box>
                {!showSearch ? null : (
                    <Grid container spacing={0} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <ListFilter
                                uniqueSearchType="meeting"
                                onFilterUpdate={this.onFilterUpdate.bind(this)}
                                filtersProps={this.state.meetingFilters}
                                fields={[
                                    {
                                        filter: 'type',
                                        defaultValue: '',
                                        element: (
                                            <Select
                                                labelId="type"
                                                size="small"
                                                label="test"
                                                displayEmpty={true}>
                                                <MenuItem value="">Type...</MenuItem>
                                                {types.map((type, i) => (
                                                    <MenuItem value={type} key={i}>
                                                        {getText('meeting-status_' + type)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        ),
                                    },
                                ]}
                            />
                        </Grid>
                        {clientId ? null : (
                            <Grid item>
                                <ListFilter
                                    uniqueSearchType="client"
                                    filtersProps={this.state.clientFilters}
                                    onFilterUpdate={this.onFilterUpdate.bind(this)}
                                    iconElement={<StoreMallDirectoryIcon />}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}

                {this.renderMeetings()}
                {this.renderMeetingForm()}
            </Box>
        );
    }

    onFilterUpdate(filters, type, emptyValue) {
        const { meetingFilters, clientFilters } = this.state;

        this.setState({
            meetingFilters: type === 'meeting' ? filters : emptyValue ? meetingFilters : '',
            clientFilters: type === 'client' ? filters : emptyValue ? clientFilters : '',
        });
    }

    renderMeetingForm() {
        const { meetingFormOpened, meetingFormData } = this.state;

        if (!meetingFormOpened) {
            return null;
        }

        return (
            <MeetingForm
                closeForm={this.closeMeetingForm.bind(this)}
                updateMeeting={this.updateMeeting.bind(this)}
                {...meetingFormData}
            />
        );
    }

    renderMeetings() {
        let { meetings, clientFilters, meetingFilters, canEdit } = this.state;
        let { oneColumn, showSearch, showHeader } = this.props;

        let filteredMeetings = meetings;

        // Filter meetings
        if (showSearch && meetingFilters) {
            filteredMeetings = filterList(meetings, meetingFilters, [
                'client.name',
                'client.address.city',
                'notes',
                'location',
                'date',
                'type',
            ]);

            filteredMeetings = filteredMeetings.filter((meeting) => {
                if (!meetingFilters.type) {
                    return true;
                }

                return meeting.type === meetingFilters.type;
            });
        }

        // Filter clients
        if (showSearch && clientFilters) {
            filteredMeetings = filterList(meetings, clientFilters, ['client.name']);
        }

        // Remove closed meetings or future meetings
        const currentTime = moment().format('YYYY-MM-DD hh:mm') + ':00.000';

        filteredMeetings = filteredMeetings.filter((meeting) => {
            const meetingTime = meeting.date + ' ' + meeting.time;

            if (meeting.markAsClosed) {
                return false;
            }

            if (moment().isBefore(meetingTime)) {
                return false;
            }

            return true;
        });

        // Sort meetings
        sortListItems(filteredMeetings, 'DESC');

        const columnsWidth = oneColumn
            ? { xs: 12 }
            : {
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 4,
              };

        return (
            <Box>
                {!showHeader ? null : (
                    <Box m={1}>
                        <SearchResults
                            value={filteredMeetings.length}
                            labelSingle={
                                this.props.labelSingle ||
                                getText('unfilled-meeting-result_singular')
                            }
                            labelPlural={
                                this.props.labelPlural || getText('unfilled-meeting-result_plural')
                            }
                            buttonLabel={
                                canEdit ? this.props.buttonLabel || getText('action-create') : null
                            }
                            onButtonClick={
                                this.props.onButtonClick || this.openMeetingForm.bind(this, null)
                            }
                        />
                    </Box>
                )}
                <Grid container>
                    {filteredMeetings.length > 0 ? null : (
                        <EmptyList message={getText('meeting-empty_list')} />
                    )}
                    {filteredMeetings.map((meeting, i) => {
                        return (
                            <Grid item key={meeting.id} {...columnsWidth}>
                                <UnfilledMeetingCard
                                    canEdit={canEdit}
                                    showDate={true}
                                    meeting={meeting}
                                    openMeetingForm={this.openMeetingForm.bind(this, meeting._id)}
                                    linkToClient={this.props.linkToClient}
                                    closeMeeting={this.closeMeeting.bind(this)}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        );
    }

    componentDidMount() {
        const { clientId } = this.props;

        const today = moment().format('YYYY-MM-DD');

        const filters = {
            _limit: '0',
            date_gte: '2022-06-08',
            date_lte: today,
            markAsClosed: 0,
        };

        if (clientId) {
            filters['client'] = clientId;
        }

        Promise.allSettled([
            //Api.call('get', '/regions2'),
            Api.call('get', `/client-meetings?${HttpHelper.toQueryString(filters)}`),
            Api.call('get', '/equipment-types?_limit=0'),
        ]).then((results) => {
            const state = {
                isLoading: false,
                opened: {},
            };

            if (results[0].status === 'fulfilled') {
                state.meetings = results[0].value.data;
            }

            if (results[1].status === 'fulfilled') {
                state.types = results[1].value.data;
            }

            this.setState(state);
        });
    }

    // Forms
    openMeetingForm(id, e) {
        this.setState({
            meetingFormOpened: true,
            meetingFormData: {
                id: id,
                clientId: this.props.clientId,
            },
        });
    }

    closeMeetingForm(e) {
        this.setState({
            meetingFormOpened: false,
        });
    }

    // Contact
    updateMeeting(meeting) {
        this.setState(updateRelation(this.state, 'meetings', meeting));
    }

    closeMeeting(meeting) {
        confirm({
            title: getText('meeting-close_title'),
            text: getText('meeting-close_message'),
            confirmLabel: getText('action-yes'),
            cancelLabel: getText('action-no'),
            onClose: (confirm) => {
                if (confirm) {
                    Api.call('put', '/client-meetings/' + meeting._id, {
                        ...meeting,
                        markAsClosed: true,
                    }).then((response) => {
                        this.setState(deleteRelation(this.state, 'meetings', meeting._id));
                    });
                }
            },
        });
    }
}

export default withRouter(withUser(UnfilledMeetingList));

import Box from '@material-ui/core/Box';
import React, {Component} from 'react';
import { withRouter } from 'react-router';
import VisibilitySensor from 'react-visibility-sensor';

import Api from '../../api/Api';
import { getText } from '../../utils/LocaleHelper';
import { splitListItems } from '../../utils/DatedListHelper';
import ClientScoreToken from '../widgets/ClientScoreToken';
import Loader from '../widgets/Loader';

class ClientInfoCard extends Component {
	static defaultProps = {
        client: null,
    }

	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			isLoading: true,
			meetings: null,
			projects: null,
			editable: true,
		}
	}

	render() {
		const {name, region, population} = this.props.client;
		const {isLoading, meetings, projects} = this.state;

		if(isLoading) {
            return (
				<VisibilitySensor
					onChange={(isVisible) => {
						if(isVisible) {
							this.loadContent()
						}
					}}
				>
					<Loader visible={this.state.isLoaded}/>
				</VisibilitySensor>
			);
        }

		const { pastListItems, futurListItems } = splitListItems(meetings);

		let pastMeetings = pastListItems;
		let futurMeetings = futurListItems;
		return (	
			<Box>
				<table style={{width: '100%', textAlign: 'left'}}>
					<tbody>
						<tr>
							<th style={{width: '40%'}}>{getText('client-name')}</th>
							<td><strong>{name}</strong> <ClientScoreToken client={this.props.client} /></td>
						</tr>
						<tr>
							<th style={{verticalAlign: 'top'}}>{getText('client-region_abbr')}</th>
							<td>
								{region ? region.name : 'N/D'}<br/>
								Pop. {population ? population : 'N/D'}
							</td>
						</tr>
						<tr>
							<th>{getText('menu-projects')}</th>
							<td>
								{projects?.filter(project => project.status === 'in_progress').length} en cours | {projects?.filter(project => project.status === 'future').length} futurs
							</td>
						</tr>
						<tr>
							<th>{getText('client-next_meeting')}</th>
							<td>
								{futurMeetings.length > 0 ? futurMeetings[0].date.replaceAll('-', '/') : 'N/D'}
							</td>
						</tr>
						<tr>
							<th>{getText('client-last_meeting')}</th>
							<td>
								{pastMeetings.length > 0 ? pastMeetings[0].date.replaceAll('-', '/') : 'N/D'}
							</td>
						</tr>
						<tr>
							<th>{getText('client-notes')}</th>
							<td>
								{this.props.client.notes ? this.props.client.notes : 'N/D'}
							</td>
						</tr>
					</tbody>
				</table>
			</Box>
		);
	}

	loadContent() {
		const { id } = this.props.client;

		if(this.state.isLoaded) {
			return;
		}

		this.setState({
			isLoaded: true,
		});

		Promise.allSettled([
			//Api.call('get', '/regions2'),
			Api.call('get', '/client-meetings?_limit=0&client='+id),
			Api.call('get', '/client-projects?_limit=0&client='+id),
		])
		.then((results) => {
			const state = {
				isLoading: false,
			}

			if(results[0].status === 'fulfilled') {
				state.meetings = results[0].value.data;
			}

			if(results[1].status === 'fulfilled') {
				state.projects = results[1].value.data;
			}

			if(this.state.isLoaded) {
				this.setState(state);
			}
		});
	}
}

export default withRouter(ClientInfoCard);
export function updateCoordinates(client) {
    return new Promise((resolve, reject) => {
        resolve(client);
    });
}

export function hasAddress(client) {
    if(client.address.length === 0) {
        return false;
    }

    const address = client.address[0];

    return address.address1 && (address.city || address.postal_code) && address.province;
}

export function getFormattedAddress(address) {
    if(!address) {
        return '';
    }

    const addressParts = [];
    
    if(address.address1) {
        addressParts.push(address.address1);
    }

    if(address.city) {
        addressParts.push(address.city);
    }

    if(address.province || address.postal_code) {
        const parts = [];

        if(address.province) {
            parts.push(address.province);
        }

        if(address.postal_code) {
            parts.push(address.postal_code);
        }
        
        if(parts.length > 0) {
            addressParts.push(parts.join(' '));
        }
    }

    if(address.country) {
        addressParts.push(address.country);
    }

    return addressParts.join(', ');
}
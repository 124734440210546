import React, {Component} from 'react';
import { withRouter } from 'react-router';

import ProjectList from '../lists/ProjectList';
import MainLayout from '../layout/MainLayout';
import { getText } from '../../utils/LocaleHelper';
import { withUser } from '../../utils/ReactWrappers';

class Projects extends Component {
	constructor(props) {
		super(props);

		this.state = {
			
		}
	}

	render() {
		return (
			<MainLayout title={getText('menu-projects')}>
				{this.renderProjects()}
			</MainLayout>
		);
	}

	renderProjects() {
        return <ProjectList linkToClient={true} />;
    }
}

export default withRouter(withUser(Projects));

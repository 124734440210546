import React, {Component} from 'react';
import { withRouter } from 'react-router';
import AdminLayout from '../../layout/AdminLayout';

class AdminIndex extends Component {
    
    render() {
        return (
            <AdminLayout title="Dashboard">
                Future Dashboard
            </AdminLayout>
        );
    }
}

export default withRouter(AdminIndex);
import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormControl';

import Api from '../../api/Api';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { confirm } from '../../utils/UIHelper';
import { getText } from '../../utils/LocaleHelper';
import Loader from '../widgets/Loader';

class ContactForm extends Component {
    static defaultProps = {
        id: null,
        client: null,
        closeForm: null,
        updateContact: null,
    }

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
            hasChanged: false,
            contact: null,
            opened: true,
            formErrors: null,
            formData: {
                address_id: '',
                name: '',
                position: '',
                phone: '',
                mobile: '',
                email: '',
            }
		}
	}

	render() {
		return (
            <Dialog
                onClose={this.onDialogClose}
                disableEscapeKeyDown={true}
                maxWidth='sm'
                fullWidth={true}
                open={this.state.opened}
                disableEnforceFocus
                TransitionProps={{
                    onExited: this.props.closeForm
                }}
            >
                <DialogTitle>{"Contact"}</DialogTitle>
                {this.renderContent()}
            </Dialog>
		);
	}

    onDialogClose(e, reason) {
        if(reason === 'backdropClick') {
            return;
        }
    }

    renderContent() {
        const { isLoading, formData } = this.state;
        const { client } = this.props;

        if(isLoading) {
            return <Loader />;
        }

        return (
            <Fragment>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label={getText('contact-name')}
                                required={true}
                                fullWidth={true}
                                size="small"
                                value={formData.name}
                                onChange={this.onFieldChange.bind(this, 'name')}
                                {...this.getFieldError('name')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={getText('contact-position')}
                                required={true}
                                fullWidth={true}
                                size="small"
                                value={formData.position}
                                onChange={this.onFieldChange.bind(this, 'position')}
                                {...this.getFieldError('position')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth={true} required={true} {...this.getFieldError('address_id', false)}>
                                <InputLabel id="region-label">{getText('contact-location')}</InputLabel>
                                <Select
                                    labelId="region-label"
                                    size="small"
                                    value={formData.address_id}
                                    onChange={this.onFieldChange.bind(this, 'address_id')}
                                >
                                    <MenuItem value="">{getText('general-none')}</MenuItem>
                                    {client.address.map((address, i) => <MenuItem value={address._id} key={i}>{address.name || (address.address1 + ' ' + address.address2)}</MenuItem>)}
                                </Select>
                                {this.renderHelperText('address_id')}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={getText('contact-phone')}
                                fullWidth={true}
                                size="small"
                                value={formData.phone}
                                onChange={this.onFieldChange.bind(this, 'phone')}
                                {...this.getFieldError('phone')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={getText('contact-mobile')}
                                fullWidth={true}
                                size="small"
                                value={formData.mobile}
                                onChange={this.onFieldChange.bind(this, 'mobile')}
                                {...this.getFieldError('mobile')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={getText('contact-email')}
                                required={true}
                                fullWidth={true}
                                size="small"
                                value={formData.email}
                                onChange={this.onFieldChange.bind(this, 'email')}
                                {...this.getFieldError('email')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={this.cancel.bind(this)}>
                        {getText('action-cancel')}
                    </Button>
                    <Button color="primary" onClick={this.save.bind(this)}>
                        {getText('action-save')}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }

    onFieldChange(attribute, e) {
        const field = e.target;
        let value = field.value;
        const formData = {...this.state.formData};

        if(attribute === 'email') {
            value = value.trim();
        }

        formData[attribute] = value;

        this.setState({
            hasChanged: true,
            formData: formData,
        });
    }

    cancel() {
        if(this.state.hasChanged) {
            confirm({
                title: getText('popup-cancel_title'),
                text: getText('popup-cancel_message'),
                confirmLabel: getText('action-yes'),
                cancelLabel: getText('action-no'),
                onClose: (confirm) => {
                    if(confirm) {
                        this.close();
                    }
                },
            });
        } else {
            this.close();
        }
    }

    save() {
        if(this.state.hasChanged || !this.props.id) {
            const {formData} = this.state;
            const data = {};

            for(const attribute in formData) {
                data[attribute] = formData[attribute];
            }

            // Update if the id is set, create if not
            this.setState({
                formErrors: null,
            });

            if(this.props.id) {
                Api.call('put', '/client-contacts/' + this.props.id, {...data, client: this.props.client._id})
                    .then((response) => {
                        this.handleFormSuccess(response);
                    })
                    .catch((error) => {
                        this.handleFormError(error);
                    });
            } else {
                Api.call('post', '/client-contacts', {...data, client: this.props.client._id})
                    .then((response) => {
                        this.handleFormSuccess(response);
                    })
                    .catch((error) => {
                        this.handleFormError(error);
                    });
            }
        } else {
            this.close();
        }
    }

    handleFormSuccess(response) {
        this.props.updateContact(response.data);
        this.close();
    }

    handleFormError(error) {
        if(error.response.status >= 500) {
            alert('error', error.response.statusText);
            return;
        }

        if(error.response.status === 400) {
            this.setState({
                formErrors: error.response.data.data,
            });
        }
    }

    renderHelperText(field) {
        const error = this.getFieldError(field);

        if(!error.error) {
            return null;
        }

        return <FormHelperText>{error.helperText}</FormHelperText>
    }

    getFieldError(field, getHelperText = true) {
        const { formErrors } = this.state;

        if(formErrors !== null) {
            const formError = formErrors.find(formError => formError.field === field);

            if(formError) {
                const data = {
                    error: true,
                };

                if(getHelperText) {
                    data.helperText = formError.message;
                }

                return data;
            }
        }
        
        const data = {
            error: false,
        };

        if(getHelperText) {
            data.helperText = null;
        }

        return data;
    }

    close() {
        this.setState({
            opened: false,
        });
    }

	componentDidMount() {
        if(this.props.id === null) {
            this.setState({
                isLoading: false,
            });

            return;
        }

		Promise.allSettled([
			Api.call('get', '/client-contacts/' + this.props.id),
		])
		.then((results) => {           
			const state = {
				isLoading: false,
			}

			if(results[0].status === 'fulfilled') {
				state.contact = results[0].value.data;

                const formData = {...this.state.formData};

                for(const attribute in formData) {
                    formData[attribute] = state.contact[attribute];
                }

                state.formData = formData;
			}

			this.setState(state);
		});
	}
}

export default ContactForm;
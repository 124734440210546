import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';

import { updateRelation, deleteRelation } from '../../../utils/ObjectHelper';
import EmptyList from '../../layout/EmptyList';
import Api from '../../../api/Api';
import EquipmentForm from '../../form/EquipmentForm';
import Grid from '@material-ui/core/Grid';
import { filterList } from '../../../utils/ListHelper';
import ListFilter from '../../lists/ListFilter';
import { getText } from '../../../utils/LocaleHelper';
import { withUser } from '../../../utils/ReactWrappers';
import Loader from '../../widgets/Loader';
import { confirm } from '../../../utils/UIHelper';
import qs from 'qs';

const styles = (theme) => ({
    equipmentEntry: {
        width: '100%',
        padding: 16,
        border: '1px solid #e1e1e1',
        marginBottom: 16,
        borderRadius: 4,
    },
});

class ClientEquipment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            types: null,
            equipments: null,
            projects: null,
            filters: null,
            opened: {},
            equipmentFormOpened: false,
            equipmentFormData: {
                id: null,
                quantityOptions: null,
                typeId: null,
                brands: null,
                distributors: null,
            },
            canEdit: props.user.role.type !== 'consultation',
        };
    }

    render() {
        const { isLoading } = this.state;

        if (isLoading) {
            return <Loader />;
        }

        return (
            <Box>
                <ListFilter
                    onFilterUpdate={(filters) => {
                        this.setState({ filters: filters });
                    }}
                />
                {this.renderEquipementCards()}
                {this.renderEquipmentForm()}
            </Box>
        );
    }

    renderEquipmentForm() {
        const { client } = this.props;
        const { equipmentFormOpened, equipmentFormData } = this.state;

        if (!equipmentFormOpened) {
            return null;
        }

        return (
            <EquipmentForm
                clientId={client._id}
                closeForm={this.closeEquipmentForm.bind(this)}
                updateEquipment={this.updateEquipment.bind(this)}
                {...equipmentFormData}
            />
        );
    }

    renderEquipementCards() {
        const { client } = this.props;
        const { types, opened, projects, filters, canEdit } = this.state;

        // Filter clients
        const filteredTypes = filterList(types, filters, ['name']);

        return (
            <Box m={1}>
                {filteredTypes.map((type, i) => {
                    const brands = type.assigned_brands;
                    const distributors = type.equipment_distributors;

                    let equipments = this.state.equipments.filter(
                        (equipment) =>
                            equipment.equipment_type && equipment.equipment_type._id === type._id,
                    );
                    let warning = false;

                    // Check if at least one entry is filled and check the brand
                    let filledPartial = false;
                    let filled = false;
                    let distributor = null;

                    for (let i = 0; i < equipments.length; i++) {
                        let equipment = equipments[i];
                        let equipmentDistributor = equipment.equipment_distributor
                            ? distributors.find(
                                  (distributor) =>
                                      distributor._id === equipment.equipment_distributor._id,
                              )
                            : null;

                        if (!filledPartial && equipment.equipment_brand !== null) {
                            filledPartial = true;
                        }

                        if (
                            !filled &&
                            equipment.equipment_brand &&
                            equipment.equipment_distributor &&
                            equipment.acquisition_year &&
                            equipment.renewal_year &&
                            equipment.estimated_quantity
                        ) {
                            filled = true;
                        }

                        if (equipmentDistributor) {
                            if (equipmentDistributor.name === '1200') {
                                distributor = equipmentDistributor;
                            } else if (!distributor) {
                                distributor = equipmentDistributor;
                            }
                        }
                    }

                    const project = projects.find(
                        (project) => project.type && project.type._id === type._id,
                    );
                    const distributorColor = distributor
                        ? distributor.name === '1200'
                            ? '#4caf50'
                            : '#f44336'
                        : '#ff9800';

                    return (
                        <Accordion
                            key={i}
                            expanded={opened[type._id]}
                            onChange={this.toggleEquipment.bind(this, type._id)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                {filled ? (
                                    <CheckBoxOutlinedIcon />
                                ) : filledPartial ? (
                                    <IndeterminateCheckBoxOutlinedIcon />
                                ) : (
                                    <CheckBoxOutlineBlankOutlinedIcon />
                                )}
                                <Typography style={{ flexGrow: 1, marginLeft: '1em' }}>
                                    {type.name}
                                </Typography>
                                <Chip
                                    label={distributor ? distributor.name : 'N/D'}
                                    style={{ backgroundColor: distributorColor, color: 'white' }}
                                />
                                {warning ? <WarningOutlinedIcon /> : null}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {equipments.length > 0 ? null : (
                                        <EmptyList message={getText('equipment-empty_list')} />
                                    )}
                                    {equipments.map((equipment) =>
                                        this.renderEquipmentEntry(
                                            type,
                                            equipment,
                                            brands,
                                            distributors,
                                        ),
                                    )}
                                    {true ? null : (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1} alignItems="flex-end">
                                                    <Grid item>
                                                        <Typography variant="subtitle2">
                                                            {getText('equipment-project')}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {project
                                                                ? `${project.date} - ${getText(
                                                                      'project-status_' +
                                                                          project.status,
                                                                  )}`
                                                                : getText('general-none')}
                                                        </Typography>
                                                    </Grid>
                                                    {!canEdit ? null : (
                                                        <Grid item>
                                                            <Button size="small" color="primary">
                                                                <EditIcon fontSize="small" />
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionDetails>
                            {!canEdit ? null : (
                                <Fragment>
                                    <Divider />
                                    <AccordionActions>
                                        <Button
                                            size="small"
                                            color="primary"
                                            onClick={this.openEquipmentForm.bind(
                                                this,
                                                type,
                                                brands,
                                                distributors,
                                                null,
                                            )}>
                                            {getText('action-create')}
                                        </Button>
                                    </AccordionActions>
                                </Fragment>
                            )}
                        </Accordion>
                    );
                })}
            </Box>
        );
    }

    renderEquipmentEntry(type, equipment, brands, distributors) {
        const { classes } = this.props;
        const { canEdit } = this.state;

        const brand = equipment.equipment_brand
            ? brands.find((brand) => brand.equipment_brand._id === equipment.equipment_brand._id)
            : null;
        const distributor = equipment.equipment_distributor
            ? distributors.find(
                  (distributor) => distributor._id === equipment.equipment_distributor._id,
              )
            : null;
        const distributorColor = distributor
            ? distributor.name === '1200'
                ? '#4caf50'
                : '#f44336'
            : '#ff9800';

        return (
            <Box key={equipment.id} className={classes.equipmentEntry}>
                <Grid container spacing={1}>
                    <Grid item xs={6} md={2}>
                        <Typography variant="subtitle2">
                            {getText('equipment-distributor')}
                        </Typography>
                        <Chip
                            size="small"
                            label={distributor ? distributor.name : 'N/D'}
                            style={{ backgroundColor: distributorColor, color: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="subtitle2">{getText('equipment-brand')}</Typography>
                        <Typography variant="body2">
                            {brand ? brand.equipment_brand.name : 'N/D'}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography variant="subtitle2">{getText('equipment-quantity')}</Typography>
                        <Typography variant="body2">
                            {equipment.estimated_quantity || 'N/D'}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography variant="subtitle2">
                            {getText('equipment-acquisition_year')}
                        </Typography>
                        <Typography variant="body2">
                            {equipment.acquisition_year || 'N/D'}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography variant="subtitle2">
                            {getText('equipment-renewal_year')}
                        </Typography>
                        <Typography variant="body2">{equipment.renewal_year || 'N/D'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        {!canEdit ? null : (
                            <Grid container justifyContent="flex-end">
                                <IconButton
                                    color="primary"
                                    onClick={this.openEquipmentForm.bind(
                                        this,
                                        type,
                                        brands,
                                        distributors,
                                        equipment.id,
                                    )}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    color="secondary"
                                    onClick={this.deleteMeeting.bind(this, equipment.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Box>
        );
    }

    toggleEquipment(id, e, isOpened) {
        const { opened } = this.state;
        const state = {
            opened: {
                ...opened,
            },
        };

        state.opened[id] = isOpened;

        this.setState(state);
    }

    componentDidMount() {
        const { client } = this.props;

        Promise.allSettled([
            //Api.call('get', '/regions2'),
            Api.call('get', `/client-equipments?_limit=0&client=${client._id}`),
            Api.localizedCall('get', `/equipment-types/byProvince?client=${client.id}`),
            Api.call('get', `/client-projects?_limit=0&client=${client._id}`),
        ]).then((results) => {
            const state = {
                isLoading: false,
                opened: {},
            };

            if (results[0].status === 'fulfilled') {
                state.equipments = results[0].value.data;
            }

            if (results[1].status === 'fulfilled') {
                state.types = results[1].value.data.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });

                for (let i = 0; i < state.types.length; i++) {
                    state.opened[state.types[i]._id] = false;
                }
            }

            if (results[2].status === 'fulfilled') {
                state.projects = results[2].value.data;
            }

            this.setState(state);
        });
    }

    // Forms
    openEquipmentForm(type, brands, distributors, id = null) {
        this.setState({
            equipmentFormOpened: true,
            equipmentFormData: {
                id: id,
                typeId: type._id,
                quantityOptions: type.quantity_options.split('\n'),
                brands: brands,
                distributors: distributors,
            },
        });
    }

    /*openEquipmentForm(type, brands, distributors, e) {
        this.setState({
            equipmentFormOpened: true,
            equipmentFormData: {
                typeId: type._id,
                quantityOptions: type.quantity_options.split("\n"),
                brands: brands,
                distributors: distributors,
            },
        });
    }*/

    closeEquipmentForm(e) {
        this.setState({
            equipmentFormOpened: false,
        });
    }

    updateEquipment(equipment) {
        const state = updateRelation(this.state, 'equipments', equipment);

        this.setState(state);
    }

    deleteMeeting(id) {
        confirm({
            title: getText('equipment-delete_title'),
            text: getText('equipment-delete_message'),
            confirmLabel: getText('action-yes'),
            cancelLabel: getText('action-no'),
            onClose: (confirm) => {
                if (confirm) {
                    Api.call('delete', '/client-equipments/' + id).then((response) => {
                        this.setState(deleteRelation(this.state, 'equipments', id));
                    });
                }
            },
        });
    }
}

export default withRouter(withStyles(styles, { index: 1 })(withUser(ClientEquipment)));

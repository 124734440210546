import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Api from '../../api/Api';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';

import { confirm } from '../../utils/UIHelper';
import { getText } from '../../utils/LocaleHelper';
import Loader from '../widgets/Loader';
import ClientFactory from '../../factory/ClientFactory';

class ProjectForm extends Component {
    static defaultProps = {
        id: null,
        clientId: null,
        typeId: null,
        closeForm: null,
        updateProject: null,
    }

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
            hasChanged: false,
            clients: null,
            types: null,
            project: null,
            opened: true,
            formErrors: null,
            formData: {
                name: '',
                date: '',
                status: '',
                notes: '',
                client: this.props.clientId,
                equipment_type: this.props.typeId,
            }
		}
	}

	render() {
		return (
            <Dialog
                onClose={this.onDialogClose}
                disableEscapeKeyDown={true}
                maxWidth='sm'
                fullWidth={true}
                open={this.state.opened}
                disableEnforceFocus
                TransitionProps={{
                    onExited: this.props.closeForm
                }}
            >
                <DialogTitle>{getText('menu-projects')}</DialogTitle>
                {this.renderContent()}
            </Dialog>
		);
	}

    onDialogClose(e, reason) {
        if(reason === 'backdropClick') {
            return;
        }
    }

    renderContent() {
        const {isLoading, formData, clients, types} = this.state;

        if(isLoading) {
            return <Loader />;
        }

        const years = [];
        for (var i = 2000; i <= (new Date()).getFullYear() + 19; i++) {
            years.push(i);
        }

        const statuses = ['future', 'in_progress', 'past', 'won', 'lost'];

        return (
            <Fragment>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label={getText('project-name')}
                                required={true}
                                fullWidth={true}
                                size="small"
                                value={formData.name}
                                onChange={this.onFieldChange.bind(this, 'name')}
                                {...this.getFieldError('name')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true} required={true} {...this.getFieldError('client', false)}>
                                <InputLabel id="region-label">{getText('client')}</InputLabel>
                                <Select
                                    labelId="region-label"
                                    size="small"
                                    value={formData.client}
                                    onChange={this.onFieldChange.bind(this, 'client')}
                                >
                                    <MenuItem value="">{getText('general-none')}</MenuItem>
                                    {clients.map((client, i) => <MenuItem value={client._id} key={i}>{client.name}</MenuItem>)}
                                </Select>
                                {this.renderHelperText('client')}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true} {...this.getFieldError('equipment_type', false)}>
                                <InputLabel id="region-label">{getText('project-equipment_type')}</InputLabel>
                                <Select
                                    labelId="region-label"
                                    size="small"
                                    value={formData.equipment_type}
                                    onChange={this.onFieldChange.bind(this, 'equipment_type')}
                                >
                                    <MenuItem value="">{getText('general-none')}</MenuItem>
                                    {types.map((type, i) => <MenuItem value={type._id} key={i}>{type.name}</MenuItem>)}
                                </Select>
                                {this.renderHelperText('equipment_type')}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth={true} {...this.getFieldError('date', false)}>
                                <InputLabel id="region-label">{getText('project-year')}</InputLabel>
                                <Select
                                    labelId="region-label"
                                    size="small"
                                    value={formData.date}
                                    onChange={this.onFieldChange.bind(this, 'date')}
                                >
                                    <MenuItem value="">{getText('general-none')}</MenuItem>
                                    {years.map((year, i) => <MenuItem value={year} key={year}>{year}</MenuItem>)}
                                </Select>
                                {this.renderHelperText('date')}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth={true} required={true} {...this.getFieldError('status', false)}>
                                <InputLabel id="region-label">{getText('project-status')}</InputLabel>
                                <Select
                                    labelId="region-label"
                                    size="small"
                                    value={formData.status}
                                    onChange={this.onFieldChange.bind(this, 'status')}
                                >
                                    <MenuItem value="">{getText('general-none')}</MenuItem>
                                    {statuses.map((status, i) => <MenuItem value={status} key={status}>{getText('project-status_'+status)}</MenuItem>)}
                                </Select>
                                {this.renderHelperText('status')}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Notes"
                                multiline
                                rows={4}
                                value={formData.notes}
                                fullWidth={true}
                                onChange={this.onFieldChange.bind(this, 'notes')}
                                {...this.getFieldError('notes')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={this.cancel.bind(this)}>
                        {getText('action-cancel')}
                    </Button>
                    <Button color="primary" onClick={this.save.bind(this)}>
                        {getText('action-save')}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }

    onFieldChange(attribute, e) {
        const {types} = this.state;
        const field = e.target;
        const value = field.value;
        const formData = {...this.state.formData};

        formData[attribute] = value;

        if(attribute === 'equipment_type' && value !== '') {
            formData.name = types.find(type => type._id === value).name;
        }

        this.setState({
            hasChanged: true,
            formData: formData,
        });
    }

    cancel() {
        if(this.state.hasChanged) {
            confirm({
                title: getText('popup-cancel_title'),
                text: getText('popup-cancel_message'),
                confirmLabel: getText('action-yes'),
                cancelLabel: getText('action-no'),
                onClose: (confirm) => {
                    if(confirm) {
                        this.close();
                    }
                },
            });
        } else {
            this.close();
        }
    }

    save() {
        if(this.state.hasChanged || !this.props.id) {
            const {formData} = this.state;
            const data = {};

            for(const attribute in formData) {
                if(['client', 'equipment_type'].includes(attribute)) {
                    data[attribute] = formData[attribute] !== '' ? formData[attribute] : null;
                } else if(['date'].includes(attribute)) {
                    let value = parseInt(formData[attribute]);
                    data[attribute] = isNaN(value) ? null : value;
                } else {
                    data[attribute] = formData[attribute];
                }
            }

            // Update if the id is set, create if not
            this.setState({
                formErrors: null,
            });
            
            if(this.props.id) {
                Api.call('put', '/client-projects/' + this.props.id, data)
                    .then((response) => {
                        this.handleFormSuccess(response);
                    })
                    .catch((error) => {
                        this.handleFormError(error);
                    });
            } else {
                Api.call('post', '/client-projects', {...data})
                    .then((response) => {
                        this.handleFormSuccess(response);
                    })
                    .catch((error) => {
                        this.handleFormError(error);
                    });
            }
        } else {
            this.close();
        }
    }

    handleFormSuccess(response) {
        this.props.updateProject(response.data);
        this.close();
    }

    handleFormError(error) {
        if(error.response.status >= 500) {
            alert('error', error.response.statusText);
            return;
        }

        if(error.response.status === 400) {
            this.setState({
                formErrors: error.response.data.data,
            });
        }
    }

    getFieldError(field, getHelperText = true) {
        const { formErrors } = this.state;

        if(formErrors !== null) {
            const formError = formErrors.find(formError => formError.field === field);

            if(formError) {
                const data = {
                    error: true,
                };

                if(getHelperText) {
                    data.helperText = formError.message;
                }

                return data;
            }
        }
        
        const data = {
            error: false,
        };

        if(getHelperText) {
            data.helperText = null;
        }

        return data;
    }

    renderHelperText(field) {
        const error = this.getFieldError(field);

        if(!error.error) {
            return null;
        }

        return <FormHelperText>{error.helperText}</FormHelperText>
    }

    close() {
        this.setState({
            opened: false,
        });
    }

	componentDidMount() {
        const requests = [
			ClientFactory.getClients({
                select: 'name',
            }),
			Api.call('get', '/equipment-types'),
		]

        if(this.props.id) {
            requests.push(Api.call('get', '/client-projects/' + this.props.id));
        }

		Promise.allSettled(requests)
		.then((results) => {           
			const state = {
				isLoading: false,
			}

            if(results[0].status === 'fulfilled') {
				state.clients = results[0].value;
                console.log(state.clients)
			}

            if(results[1].status === 'fulfilled') {
				state.types = results[1].value.data;
			}

			if(results[2] && results[2].status === 'fulfilled') {
				state.project = results[2].value.data;

                const formData = {...this.state.formData};

                for(const attribute in formData) {
                    if(['client', 'equipment_type'].includes(attribute)) {
                        formData[attribute] = !state.project[attribute] ? '' : state.project[attribute]._id;
                    } else {
                        formData[attribute] = state.project[attribute];
                    }
                }

                state.formData = formData;
			}

			this.setState(state);
		});
	}
}

export default ProjectForm;
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

class Loader extends Component {
    static defaultProps = {
        visible: true,
    }

    render() {
        return (
            <Grid container justifyContent="center">
                <Box m={4} style={{width: '100%', maxWidth: '75px'}}>
                    {
                        !this.props.visible ? null:
                        <svg version="1.1" id="L1" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100">
                            <path fill="#3f51b5" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    }
                </Box>
            </Grid>
        );
    }
}

export default Loader;
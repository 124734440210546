const messages = {
    /**
     * Menu
     */
    'menu-dashboard': {
        fr: 'Dashboard',
        en: 'Dashboard',
    },
    'menu-map': {
        fr: 'Carte',
        en: 'Map',
    },
    'menu-clients': {
        fr: 'Clients',
        en: 'Customers',
    },
    'menu-meetings': {
        fr: 'Visites',
        en: 'Meetings',
    },
    'menu-projects': {
        fr: 'Projets',
        en: 'Projects',
    },
    'menu-profile': {
        fr: 'Mon profil',
        en: 'My profile',
    },
    'menu-equipments': {
        fr: 'Équipements',
        en: 'Equipments',
    },
    'menu-invoices': {
        fr: 'Factures',
        en: 'Invoices',
    },
    'menu-reminders': {
        fr: 'Alertes',
        en: 'Reminders',
    },
    'menu-record': {
        fr: 'Fiche',
        en: 'Record',
    },
    'menu-admin': {
        fr: 'Administration',
        en: 'Admin',
    },
    'menu-logout': {
        fr: 'Déconnexion',
        en: 'Logout',
    },
    'menu-return-account' : {
        fr: 'Retour à mon compte',
        en: 'Return to my account',
    },

    /**
     * General
     */
    'general-none': {
        fr: 'Aucun',
        en: 'None',
    },
    'general-status': {
        fr: 'Status',
        en: 'Status',
    },

    /**
     * Dashboard
     */
    'dashboard-welcome': {
        fr: 'Bienvenue',
        en: 'Welcome',
    },

    /**
     * Alerts
     */
    'popup-cancel_title': {
        fr: 'Annulation des modifications',
        en: 'Cancel changes',
    },
    'popup-cancel_message': {
        fr: 'Voulez-vous annuler vos modifications ?',
        en: 'Do you want to undo your changes?',
    },

    /**
     * Actions
     */
    'action-search': {
        fr: 'Rechercher',
        en: 'Search',
    },
    'action-view': {
        fr: 'Voir',
        en: 'view',
    },
    'action-view_all': {
        fr: 'Voir tout',
        en: 'view all',
    },
    'action-edit': {
        fr: 'Modifier',
        en: 'Edit',
    },
    'action-delete': {
        fr: 'Supprimer',
        en: 'Delete',
    },
    'action-create': {
        fr: 'Ajouter',
        en: 'Create',
    },
    'action-cancel': {
        fr: 'Annuler',
        en: 'Cancel',
    },
    'action-save': {
        fr: 'Sauvegarder',
        en: 'Save',
    },
    'action-yes': {
        fr: 'Oui',
        en: 'Yes',
    },
    'action-no': {
        fr: 'Non',
        en: 'NO',
    },
    'action-change': {
        fr: 'Modifier',
        en: 'Change',
    },
    'action-close': {
        fr: 'Fermer',
        en: 'Close',
    },
    'action-select': {
        fr: 'Choisir',
        en: 'Select',
    },
    'action-view_more': {
        fr: 'Voir plus',
        en: 'View more',
    },
    'action-add': {
        fr: 'Ajouter',
        en: 'Add',
    },

    /**
     * Client
     */
    client: {
        fr: 'Client',
        en: 'Customer',
    },
    'client-region': {
        fr: 'Région administrative',
        en: 'Administrative region',
    },
    'client-region_abbr': {
        fr: 'Rég. Adm.',
        en: 'Adm. reg.',
    },
    'client-next_meeting': {
        fr: 'Prochaine visite',
        en: 'Next meeting',
    },
    'client-last_meeting': {
        fr: 'Dernière visite',
        en: 'Last meeting',
    },
    'client-result_singular': {
        fr: 'client trouvé',
        en: 'customer found',
    },
    'client-result_plural': {
        fr: 'clients trouvés',
        en: 'customers found',
    },
    'client-view_client': {
        fr: 'Voir le client',
        en: 'View customer',
    },
    'client-informations': {
        fr: 'Informations générales',
        en: 'General informations',
    },
    'client-firestation_count': {
        fr: 'Nombre de casernes',
        en: 'Fire station count',
    },
    'client-meeting_count': {
        fr: 'Nombre de visites',
        en: 'Meeting count',
    },
    'client-objectives': {
        fr: 'Notes personnelles sur le client',
        en: 'Personal notes about the customer',
    },
    'client-notes': {
        fr: 'Notes sur les dernières rencontres',
        en: 'Notes about the last meetings',
    },
    'client-notes_view': {
        fr: 'Voir les notes',
        en: 'View notes',
    },
    'client-notes-latest': {
        fr: 'Dernières notes',
        en: 'Latest notes',
    },
    'client-objectives_hints': {
        fr: 'Inscrire ici les notes personnelles sur le client',
        en: 'Write here your personal notes about the customer',
    },
    'client-notes_hints': {
        fr: 'Inscrire ici les notes sur les dernières rencontres',
        en: 'Write here your notes about the last meetings',
    },
    'client-notes-delete-title': {
        fr: 'Supprimer la note',
        en: 'Delete note',
    },
    'client-notes-delete-message': {
        fr: 'Voulez-vous vraiment supprimer cette note ?',
        en: 'Do you really want to delete this note?',
    },
    'client-history': {
        fr: 'Historique',
        en: 'History',
    },
    'client-view_history': {
        fr: "Voir l'historique",
        en: 'View history',
    },
    'client-name': {
        fr: 'Nom',
        en: 'Name',
    },
    'client-sales_rep': {
        fr: 'Représentant',
        en: 'Sales rep',
    },
    'client-address': {
        fr: 'Adresse',
        en: 'Address',
    },
    'client-addresses': {
        fr: 'Adresses',
        en: 'Addresses',
    },
    'client-fireman_count': {
        fr: 'Nombre de pompier',
        en: 'Fireman count',
    },
    'client-full_time': {
        fr: 'Temps plein',
        en: 'Full time',
    },
    'client-part_time': {
        fr: 'Temps partiel',
        en: 'Part time',
    },
    'client-main_address': {
        fr: 'Caserne 1',
        en: 'Fire station 1',
    },
    'client-address_name': {
        fr: 'Nom du lieu',
        en: 'Location name',
    },

    'client-delete_title': {
        fr: 'Suppression du client',
        en: 'Delete customer',
    },
    'client-delete_infos': {
        fr: 'Voulez-vous vraiment supprimer le client? Cette action est irréversible et supprimera toutes les données liées à ce client.',
        en: 'Do you really want to delete the customer? This action is irreversible and will delete all data related to this customer.',
    },
    'client-empty-infos': {
        fr: "Aucun client n'a été sélectionné. Veuillez sélectionner un client dans la liste à gauche.",
        en: 'No customer has been selected. Please select a customer in the list on the left.',
    },
    /**
     * Meeting
     */
    'meeting-status_demo': {
        fr: 'Démo',
        en: 'Demo',
    },
    'meeting-status_follow_up': {
        fr: 'Suivi',
        en: 'Follow up',
    },
    'meeting-status_courtesy': {
        fr: 'Courtoisie',
        en: 'Courtesy',
    },
    'meeting-status_commissioning': {
        fr: 'Mise en service',
        en: 'Commissioning',
    },
    'meeting-status_other': {
        fr: 'Autre',
        en: 'Other',
    },
    'meeting-result_singular': {
        fr: 'visite trouvée',
        en: 'meeting found',
    },
    'meeting-result_plural': {
        fr: 'visites trouvées',
        en: 'meetings found',
    },
    'meeting-futur_result_singular': {
        fr: 'visite à venir',
        en: 'upcoming meeting',
    },
    'meeting-futur_result_plural': {
        fr: 'visites à venir',
        en: 'upcoming meetings',
    },
    'meeting-old': {
        fr: 'Anciennes visites',
        en: 'Completed meetings',
    },
    'meeting-duration': {
        fr: 'Durée',
        en: 'Duration',
    },
    'meeting-time': {
        fr: 'Heure',
        en: 'Time',
    },
    'meeting-location': {
        fr: 'Lieu',
        en: 'Location',
    },
    'meeting-delete_title': {
        fr: 'Suppression de la visite',
        en: 'Deletion of a meeting',
    },
    'meeting-delete_message': {
        fr: 'Êtes-vous sûr de vouloir supprimer cette visite ?',
        en: 'Are you sure you want to delete this meeting?',
    },
    'meeting-close_title': {
        fr: 'Fermeture de la visite',
        en: 'Close a meeting',
    },
    'meeting-close_message': {
        fr: 'Êtes-vous sûr de vouloir fermer cette visite ?',
        en: 'Are you sure you want to close this meeting?',
    },
    'meeting-empty_list': {
        fr: "Vous n'avez présentement aucune visite",
        en: 'You currently have no meeting',
    },
    'meeting-add-attendee': {
        fr: 'Ajouter participant',
        en: 'Add attendee',
    },
    'meeting-notes': {
        fr: 'Notes de la visite',
        en: 'Meeting notes',
    },

    /**
     * Unfilled meeting
     */
    'unfilled-meeting-result_singular': {
        fr: 'visite non fermée',
        en: 'unclosed meeting',
    },
    'unfilled-meeting-result_plural': {
        fr: 'visites non fermées',
        en: 'unclosed meetings',
    },

    /**
     * Reminder
     */
    'reminder-result_singular': {
        fr: 'alerte trouvée',
        en: 'reminder found',
    },
    'reminder-result_plural': {
        fr: 'alertes trouvées',
        en: 'reminders found',
    },
    'reminder-futur_result_singular': {
        fr: 'alerte à venir',
        en: 'upcoming reminder',
    },
    'reminder-futur_result_plural': {
        fr: 'alertes à venir',
        en: 'upcoming reminders',
    },
    'reminder-old': {
        fr: 'Anciennes alertes',
        en: 'Completed reminders',
    },
    'reminder-duration': {
        fr: 'Durée',
        en: 'Duration',
    },
    'reminder-time': {
        fr: 'Heure',
        en: 'Time',
    },
    'reminder-location': {
        fr: 'Lieu',
        en: 'Location',
    },
    'reminder-delete_title': {
        fr: 'Suppression de la alerte',
        en: 'Deletion of a reminder',
    },
    'reminder-delete_message': {
        fr: 'Êtes-vous sûr de vouloir supprimer cette alerte ?',
        en: 'Are you sure you want to delete this reminder?',
    },
    'reminder-empty_list': {
        fr: "Vous n'avez présentement aucune alerte",
        en: 'You currently have no reminder',
    },
    'reminder-important': {
        fr: "Rappels importants",
        en: 'Important reminders',
    },
    'reminder-important_singular': {
        fr: "Rappel important",
        en: 'Important reminder',
    },
    'reminder-important_plural': {
        fr: "Rappels importants",
        en: 'Important reminders',
    },
    /**
     * Project
     */

    'project-status_future': {
        fr: 'À venir',
        en: 'Upcoming',
    },
    'project-status_in_progress': {
        fr: 'En cours',
        en: 'In progress',
    },
    'project-status_past': {
        fr: 'Passé',
        en: 'Past',
    },
    'project-status_won': {
        fr: 'Gagné',
        en: 'Won',
    },
    'project-status_lost': {
        fr: 'Perdu',
        en: 'Lost',
    },
    'project-result_singular': {
        fr: 'projet trouvé',
        en: 'project found',
    },
    'project-result_plural': {
        fr: 'projets trouvés',
        en: 'projects found',
    },
    'project-name': {
        fr: 'Nom',
        en: 'Name',
    },
    'project-equipment_type': {
        fr: "Type d'équipement",
        en: 'Equipment type',
    },
    'project-year': {
        fr: 'Année',
        en: 'Year',
    },
    'project-status': {
        fr: 'Statut',
        en: 'Status',
    },
    'project-delete_title': {
        fr: 'Suppression du project',
        en: 'Deletion of the project',
    },
    'project-delete_message': {
        fr: 'Êtes-vous sûr de vouloir supprimer ce projet ?',
        en: 'Are you sure you want to delete this project?',
    },
    'project-empty_list': {
        fr: "Vous n'avez présentement aucun projet",
        en: 'You currently have no project',
    },
    'project-title-incoming_singular': {
        fr: 'Projet à venir',
        en: 'Incoming project',
    },
    'project-title-incoming-plural': {
        fr: 'Projets à venir',
        en: 'Incoming projects',
    },

    /**
     * Contacts
     */
    'contact-name': {
        fr: 'Nom',
        en: 'Name',
    },
    'contact-position': {
        fr: 'Poste',
        en: 'Position',
    },
    'contact-phone': {
        fr: 'Téléphone',
        en: 'Phone',
    },
    'contact-mobile': {
        fr: 'Cellulaire',
        en: 'Mobile',
    },
    'contact-email': {
        fr: 'Courriel',
        en: 'Email',
    },
    'contact-delete_title': {
        fr: 'Suppression de ',
        en: 'Deletion of',
    },
    'contact-delete_message': {
        fr: 'Êtes-vous sûr de vouloir supprimer ce contact ?',
        en: 'Are you sure you want to delete this contact?',
    },
    'contact-empty_list': {
        fr: "Vous n'avez présentement aucun contact d'inscrit",
        en: 'You currently have no registered contacts',
    },
    'contact-location': {
        fr: 'Emplacement',
        en: 'Location',
    },

    /**
     * Invoices
     */
    'invoice-result_singular': {
        fr: 'facture trouvée',
        en: 'invoice found',
    },
    'invoice-result_plural': {
        fr: 'factures trouvées',
        en: 'invoices found',
    },
    'invoice-type_invoice': {
        fr: 'Facture',
        en: 'Invoice',
    },
    'invoice-type_order': {
        fr: 'Commande',
        en: 'Order',
    },
    'invoice-type_quote': {
        fr: 'Soumission',
        en: 'Quote',
    },
    'invoice-empty_list': {
        fr: "Vous n'avez présentement aucune facture",
        en: 'You currently have no invoices',
    },

    /**
     * Equipment
     */
    'equipment-brand': {
        fr: 'Marque actuelle',
        en: 'Current brand',
    },
    'equipment-distributor': {
        fr: 'Distributeur',
        en: 'Distributor',
    },
    'equipment-distributors': {
        fr: 'Distributeurs',
        en: 'Distributors',
    },
    'equipment-quantity': {
        fr: 'Qté. actuelle est.',
        en: 'Est. quantity',
    },
    'equipment-acquisition_year': {
        fr: 'Année acquis',
        en: 'Acquisition year',
    },
    'equipment-renewal_year': {
        fr: 'Renouvellement',
        en: 'Renewal year',
    },
    'equipment-project': {
        fr: 'Projet',
        en: 'Project',
    },
    'equipment-delete_title': {
        fr: "Suppression de l'équipement",
        en: 'Deletion of a equipment',
    },
    'equipment-delete_message': {
        fr: 'Êtes-vous sûr de vouloir supprimer cet équipement ?',
        en: 'Are you sure you want to delete this equipment?',
    },
    'equipment-empty_list': {
        fr: "Vous n'avez présentement aucun équipement",
        en: 'You currently have no equipment',
    },

    /**
     * My profile
     */
    'profile-name': {
        fr: 'Nom',
        en: 'Name',
    },
    'profile-role': {
        fr: 'Rôle',
        en: 'Role',
    },
    'profile-email': {
        fr: 'Courriel',
        en: 'Email',
    },
    'profile-change_password_title': {
        fr: 'Modification de mot de passe',
        en: 'Change password',
    },
    'profile-new_password': {
        fr: 'Nouveau mot de passe',
        en: 'New password',
    },
    'profile-repeat_password': {
        fr: 'Répéter mot de passe',
        en: 'Repeat password',
    },
    'profile-conditions': {
        fr: 'Min. 6 charactères; Au moins une majuscule; Au moins une minuscule; Au moins un charactère spécial',
        en: 'Minimum six characters; At least one uppercase character; At least one lowercase character; At least one special character',
    },
    'profile-identical': {
        fr: 'Les mots de passe doivent être identiques.',
        en: 'Passwords do not match',
    },
    'profile-confirm': {
        fr: 'Votre mot de passe a été modifié.',
        en: 'Your password has been changed.',
    },

    /**
     * Invoice PDF
     */
    'invoice_pdf-no_trans': {
        fr: 'No transaction',
        en: 'Transaction #',
    },
    'invoice_pdf-date': {
        fr: 'Date',
        en: 'Date',
    },
    'invoice_pdf-date_echeance': {
        fr: 'Expiration',
        en: 'Expiration',
    },
    'invoice_pdf-rep': {
        fr: 'Représentant',
        en: 'Representant',
    },
    'invoice_pdf-reference': {
        fr: 'Votre No de commande',
        en: 'Your order #',
    },
    'invoice_pdf-reference_quote': {
        fr: 'No de reference',
        en: 'Reference #',
    },
    'invoice_pdf-bill_to': {
        fr: 'Facturé à',
        en: 'Bill to',
    },
    'invoice_pdf-ship_to': {
        fr: 'Livré à',
        en: 'Ship to',
    },
    'invoice_pdf-in_items_id': {
        fr: 'No de produit',
        en: 'Product #',
    },
    'invoice_pdf-description': {
        fr: 'Description',
        en: 'Description',
    },
    'invoice_pdf-in_items_um_id': {
        fr: 'U/M',
        en: 'U/M',
    },
    'invoice_pdf-qte': {
        fr: 'Qté',
        en: 'Qty',
    },
    'invoice_pdf-prix': {
        fr: 'Prix',
        en: 'Price',
    },
    'invoice_pdf-prixrev': {
        fr: 'Montant',
        en: 'Amount',
    },
    'invoice_pdf-subtotal': {
        fr: 'Sous-total',
        en: 'Subtotal',
    },
    'invoice_pdf-nocommande': {
        fr: 'No commande',
        en: 'Order #',
    },
    'invoice_pdf-nolivraison': {
        fr: 'No livraison',
        en: 'Delivery #',
    },
    'invoice_pdf-delay': {
        fr: 'Délai',
        en: 'Delay',
    },

    /**
     * Address
     */
    'address-empty_list': {
        fr: "Vous n'avez présentement aucune adresse d'inscrit",
        en: 'You currently have no registered address',
    },
    'address-delete_title': {
        fr: "Suppression de l'adresse",
        en: 'Deletion of the address',
    },
    'address-delete_message': {
        fr: 'Êtes-vous sûr de vouloir supprimer cette adresse ?',
        en: 'Are you sure you want to delete this address?',
    },

    // Reminders
    'reminder-title': {
        fr: 'Rappel',
        en: 'Reminder',
    },
    '1-day_before': {
        fr: '1 jour avant',
        en: '1 day before',
    },
    '2-day_before': {
        fr: '2 jours avant',
        en: '2 days before',
    },
    '5-day_before': {
        fr: '5 jours avant',
        en: '5 days before',
    },

    // Months
    january: {
        fr: 'Janvier',
        en: 'January',
    },
    february: {
        fr: 'Février',
        en: 'February',
    },
    march: {
        fr: 'Mars',
        en: 'March',
    },
    april: {
        fr: 'Avril',
        en: 'April',
    },
    may: {
        fr: 'Mai',
        en: 'May',
    },
    june: {
        fr: 'Juin',
        en: 'June',
    },

    july: {
        fr: 'Juillet',
        en: 'July',
    },
    august: {
        fr: 'Août',
        en: 'August',
    },
    september: {
        fr: 'Septembre',

        en: 'September',
    },
    october: {
        fr: 'Octobre',

        en: 'October',
    },
    november: {
        fr: 'Novembre',

        en: 'November',
    },
    december: {
        fr: 'Décembre',

        en: 'December',
    },

    // user
    'user-role': {
        fr: 'Rôle',
        en: 'Role',
    },
    'user-form-title-create': {
        fr: 'Ajouter un utilisateur',
        en: 'Add a user',
    },
    'user-form-title-edit': {
        fr: 'Modifier un utilisateur',
        en: 'Edit a user',
    },
    "user-form-first-name": {
        fr: 'Prénom',
        en: 'First name',
    },
    "user-form-last-name": {
        fr: 'Nom',
        en: 'Last name',
    },
    "user-form-email": {
        fr: 'Courriel',
        en: 'Email',
    },
    "user-form-role": {
        fr: 'Rôle',
        en: 'Role',
    },
    "user-form-language": {
        fr: 'Langue',
        en: 'Language',
    },
    "user-form-password-temp": {
        fr: 'Mot de passe temporaire',
        en: 'Temporary password',
    },
    "user-form-role-admin": {
        fr: 'Administrateur',
        en: 'Administrator',
    },
    "user-form-role-user": {
        fr: 'Utilisateur',
        en: 'User',
    },
    "user-delete_title": {
        fr: "Suppression de l'utilisateur",
        en: 'Deletion of the user',
    },
    'user-delete_message': {
        fr: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
        en: 'Are you sure you want to delete this user?',
    },
};

export default messages;

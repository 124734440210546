import React, {Component, Fragment} from 'react';
import { withRouter } from 'react-router';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Api from '../../api/Api';
import { getFormattedAddress } from '../../utils/ClientHelper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import { alert, confirm } from '../../utils/UIHelper';
import { getText } from '../../utils/LocaleHelper';
import Loader from '../widgets/Loader';

class AddressForm extends Component {
    static defaultProps = {
        index: null,
        client: null,
        closeForm: null,
        updateClient: null,
    }

	constructor(props) {
		super(props);

        this.addressInput = React.createRef();
        this.autocomplete = null;

        const currentAddress = props.index !== null ? props.client.address[props.index] : {};

		this.state = {
			isLoading: false,
            hasChanged: false,
            opened: true,
            addressString: getFormattedAddress(currentAddress),
            formErrors: null,
            formData: {
                name: '',
                address1: '',
                address2: '',
                city: '',
                province: '',
                postal_code: '',
                country: '',
                latitude: null,
                longitude: null,

                ...currentAddress,
            }
		}
	}

	render() {
		return (
            <Dialog
                disableEscapeKeyDown={true}
                maxWidth='sm'
                fullWidth={true}
                open={this.state.opened}
                onClose={this.props.onDialogClose}
                disableEnforceFocus
                TransitionProps={{
                    onExited: this.props.closeForm
                }}
            >
                <DialogTitle>{getText('client-address')}</DialogTitle>
                {this.renderContent()}
            </Dialog>
		);
	}

    onDialogClose(e, reason) {
        if(reason === 'backdropClick') {
            return;
        }
    }

    renderContent() {
        const {isLoading} = this.state;
        
        if(isLoading) {
            return <Loader />;
        }

        return (
            <Fragment>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label={getText('client-address_name')}
                                fullWidth={true}
                                size="small"
                                value={this.state.formData.name}
                                onChange={this.onFieldChange.bind(this, 'name')}
                                {...this.getFieldError('name')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={getText('client-address')}
                                fullWidth={true}
                                size="small"
                                value={this.state.addressString}
                                onChange={this.onAddressChange.bind(this)}
                                inputRef={this.addressInput}
                                required={true}
                                {...this.getFieldError('addressString')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={this.cancel.bind(this)}>
                        {getText('action-cancel')}
                    </Button>
                    <Button color="primary" onClick={this.save.bind(this)}>
                        {getText('action-save')}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }

    registerAutocomplete() {
        // const center = { lat: 50.064192, lng: -130.605469 };

        // Create a bounding box with sides ~10km away from the center point
        const options = {
            componentRestrictions: { country: "ca" },
            origin: { lat: 53, lng: -70 },
            fields: ["formatted_address", "address_components", "geometry"],
            strictBounds: false,
            //types: ["establishment"],
        };

        this.autocomplete = new window.google.maps.places.Autocomplete(this.addressInput.current, options);
        this.autocomplete.addListener('place_changed', this.onPlaceChanged.bind(this, this.autocomplete));
    }

    onFieldChange(attribute, e) {
        const field = e.target;
        const value = field.value;
        const formData = {...this.state.formData};

        formData[attribute] = value;

        this.setState({
            hasChanged: true,
            formData: formData,
        });
    }

    onPlaceChanged(autocomplete) {
        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert("No details available for input: '" + place.name + "'");
            return;
        }

        const formData = {
            address1: this.getAddressComponentByType(place.address_components, 'street_number', '') + ' ' + this.getAddressComponentByType(place.address_components, 'route', ''),
            address2: '',
            city: this.getAddressComponentByType(place.address_components, 'locality'),
            province: this.getAddressComponentByType(place.address_components, 'administrative_area_level_1'),
            postal_code: this.getAddressComponentByType(place.address_components, 'country'),
            country: this.getAddressComponentByType(place.address_components, 'postal_code'),
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
        };

        this.setState({
            addressString: place.formatted_address,
            hasChanged: true,
            formData: {...this.state.formData, ...formData},
        });
    }

    getAddressComponentByType(address_components, type, defaultVal = null, format = 'short_name') {
        const component = address_components.find((address_component) => {
            return address_component.types.includes(type);
        });

        if(component) {
            return component[format];
        }

        return defaultVal;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.addressInput.current && this.autocomplete === null) {
            this.registerAutocomplete();
        }
    }

    onAddressChange(e) {
        this.setState({
            addressString: e.target.value,
            hasChanged: true,
        })
    }

    cancel() {
        if(this.state.hasChanged) {
            confirm({
                title: getText('popup-cancel_title'),
                text: getText('popup-cancel_message'),
                confirmLabel: getText('action-yes'),
                cancelLabel: getText('action-no'),
                onClose: (confirm) => {
                    if(confirm) {
                        this.close();
                    }
                },
            });
        } else {
            this.close();
        }
    }

    save() {
        if(this.state.hasChanged || this.props.index === null) {
            const {formData} = this.state;
            const data = [...this.props.client.address];

            // Update if the id is set, create if not
            this.setState({
                formErrors: null,
            });

            if(this.props.index !== null) {
                data[this.props.index] = formData;
            } else {
                data.push(formData);
            }

            Api.call('put', '/clients/' + this.props.client.id + '/address', {address: data})
                .then((response) => {
                    this.handleFormSuccess(response, false);
                })
                .catch((error) => {
                    this.handleFormError(error);
                });

        } else {
            this.close();
        }
    }

    handleFormSuccess(response, isNew) {
        this.props.updateClient(response.data);
        this.close();
    }

    handleFormError(error) {
        if(error.response.status >= 500) {
            alert('error', error.response.statusText);
            return;
        }

        if(error.response.status === 400) {
            this.setState({
                formErrors: error.response.data.data,
            });
        }
    }

    getFieldError(field, getHelperText = true) {
        const { formErrors } = this.state;

        if(formErrors !== null) {
            const formError = formErrors.find(formError => formError.field === field);

            if(formError) {
                const data = {
                    error: true,
                };

                if(getHelperText) {
                    data.helperText = formError.message;
                }

                return data;
            }
        }
        
        const data = {
            error: false,
        };

        if(getHelperText) {
            data.helperText = null;
        }

        return data;
    }

    renderHelperText(field) {
        const error = this.getFieldError(field);

        if(!error.error) {
            return null;
        }

        return <FormHelperText>{error.helperText}</FormHelperText>
    }

    close() {
        this.setState({
            opened: false,
        });
    }
}

export default withRouter(AddressForm);
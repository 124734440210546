import React, { Component, Fragment } from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MapIcon from '@material-ui/icons/Map';
import PhoneIcon from '@material-ui/icons/Phone';
import DescriptionIcon from '@material-ui/icons/Description';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr-CA';
import enLocale from 'date-fns/locale/en-US';
import { MuiPickersUtilsProvider, TimePicker, KeyboardDatePicker } from '@material-ui/pickers';

import Api from '../../api/Api';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import EmptyList from '../layout/EmptyList';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import EmailIcon from '@material-ui/icons/Email';
import RoomIcon from '@material-ui/icons/Room';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';

import { format } from 'date-fns';
import { confirm } from '../../utils/UIHelper';
import { getLang, getText } from '../../utils/LocaleHelper';
import { validateEmail } from '../../utils/StringHelper';
import { getFormattedAddress } from '../../utils/ClientHelper';
import Loader from '../widgets/Loader';
import ClientFactory from '../../factory/ClientFactory';

const locales = {
    fr: frLocale,
    en: enLocale,
};

class MeetingForm extends Component {
    static defaultProps = {
        id: null,
        clientId: null,
        closeForm: null,
        updateMeeting: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            hasChanged: false,
            clients: null,
            meeting: null,
            users: null,
            futureProjects: [],
            opened: true,
            formErrors: null,
            contactsPopupOpened: false,
            addressesPopupOpened: false,
            projectsPopupOpened: false,
            historyPopupOpened: false,
            historyPopupType: null,
            notesHistory: [],
            objectivesHistory: [],
            formData: {
                location: '',
                date: null,
                time: null,
                duration: null,
                reminder: null,
                type: '',
                notes: '',
                client: this.props.clientId,
                attendees: [],
            },
        };
    }

    renderHistoryPopup() {
        const { historyPopupOpened, historyPopupType } = this.state;
        const history = this.state[historyPopupType + 'History'];

        return (
            <Dialog
                onClose={this.closeHistoryPopup.bind(this)}
                disableEscapeKeyDown={true}
                maxWidth="md"
                fullWidth={true}
                open={historyPopupOpened}
                disableEnforceFocus>
                <DialogTitle>{getText('client-' + historyPopupType)}</DialogTitle>
                <DialogContent>
                    {!history
                        ? null
                        : history.map((entry, i) => {
                              let hint =
                                  history[0].user.first_name + ' ' + history[0].user.last_name;
                              hint +=
                                  ' at ' +
                                  format(
                                      Date.parse(history[0].modification_date),
                                      'yyyy-MM-dd HH:mm',
                                  );

                              return (
                                  <Fragment key={i}>
                                      <Box m={1}>
                                          <Typography variant="body2">
                                              {entry[historyPopupType]}
                                          </Typography>
                                          <Typography variant="caption" style={{ opacity: 0.54 }}>
                                              {hint}
                                          </Typography>
                                      </Box>
                                      <Divider />
                                  </Fragment>
                              );
                          })}
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        color="primary"
                        onClick={this.closeHistoryPopup.bind(this)}>
                        {getText('action-close')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        return (
            <Fragment>
                <Dialog
                    onClose={this.onDialogClose}
                    disableEscapeKeyDown={true}
                    maxWidth="sm"
                    fullWidth={true}
                    open={this.state.opened}
                    disableEnforceFocus
                    TransitionProps={{
                        onExited: this.props.closeForm,
                    }}>
                    <DialogTitle>{getText('menu-meetings')}</DialogTitle>
                    {this.renderContent()}
                </Dialog>

                <Dialog
                    onClose={this.closeSelectorPopup.bind(this)}
                    maxWidth="md"
                    fullWidth={true}
                    open={this.state.addressesPopupOpened}
                    disableEnforceFocus>
                    <DialogTitle>{getText('client-addresses')}</DialogTitle>
                    {this.renderAddressesPopup()}
                </Dialog>

                <Dialog
                    onClose={this.closeSelectorPopup.bind(this)}
                    maxWidth="md"
                    fullWidth={true}
                    open={this.state.contactsPopupOpened}
                    disableEnforceFocus>
                    <DialogTitle>Contacts</DialogTitle>
                    {this.renderContactsPopup()}
                </Dialog>

                <Dialog
                    onClose={this.closeSelectorPopup.bind(this)}
                    maxWidth="md"
                    fullWidth={true}
                    open={this.state.projectsPopupOpened}
                    disableEnforceFocus>
                    <DialogTitle>
                        {this.state.futureProjects.length <= 1
                            ? getText('project-title-incoming_singular')
                            : getText('project-title-incoming-plural')}
                    </DialogTitle>
                    {this.renderProjectsPoppup()}
                </Dialog>

                {this.renderHistoryPopup()}
            </Fragment>
        );
    }

    onDialogClose(e, reason) {
        if (reason === 'backdropClick') {
            return;
        }

        this.props.closeForm();
    }

    renderContent() {
        const { isLoading, formData, clients, users } = this.state;

        if (isLoading) {
            return <Loader />;
        }

        const years = [];
        for (var i = 2000; i <= new Date().getFullYear() + 10; i++) {
            years.push(i);
        }

        const textFieldStyle = {
            width: '10rem',
            marginTop: '1rem',
        };

        const types = ['demo', 'follow_up', 'courtesy', 'commissioning', 'other'];
        const reminders = [1, 2, 5];

        return (
            <Fragment>
                <DialogContent>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locales[getLang()]}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box display="flex">
                                    <FormControl
                                        fullWidth={true}
                                        required={true}
                                        {...this.getFieldError('client', false)}>
                                        <InputLabel id="region-label">
                                            {getText('client')}
                                        </InputLabel>
                                        <Select
                                            labelId="region-label"
                                            size="small"
                                            value={formData.client !== null ? formData.client : ''}
                                            onChange={this.onFieldChange.bind(this, 'client')}>
                                            <MenuItem value="">{getText('general-none')}</MenuItem>
                                            {clients.map((client, i) => (
                                                <MenuItem value={client._id} key={i}>
                                                    {client.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {this.renderHelperText('client')}
                                    </FormControl>
                                    {!formData.client ? null : (
                                        <Box>
                                            <IconButton onClick={this.openProjectsPopup.bind(this)}>
                                                <Badge
                                                    badgeContent={this.state.futureProjects.length}
                                                    color="secondary">
                                                    <DescriptionIcon
                                                        fontSize="inherit"
                                                        color="primary"
                                                    />
                                                </Badge>
                                            </IconButton>
                                        </Box>
                                    )}
                                    {!formData.client ? null : (
                                        <Box>
                                            <IconButton
                                                onClick={this.openHistoryPopup.bind(this, 'notes')}>
                                                <Badge
                                                    badgeContent={this.state.notesHistory.length}
                                                    color="secondary">
                                                    <SpeakerNotesIcon
                                                        fontSize="inherit"
                                                        color="primary"
                                                    />
                                                </Badge>
                                            </IconButton>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex">
                                    <Box flexGrow={1}>
                                        <TextField
                                            label={getText('meeting-location')}
                                            fullWidth={true}
                                            size="small"
                                            value={formData.location}
                                            onChange={this.onFieldChange.bind(this, 'location')}
                                            {...this.getFieldError('location')}
                                        />
                                    </Box>
                                    {!formData.client ? null : (
                                        <Fragment>
                                            <Box>
                                                <IconButton
                                                    onClick={this.openAddressesPopup.bind(this)}>
                                                    <MapIcon fontSize="inherit" color="primary" />
                                                </IconButton>
                                            </Box>
                                            <Box>
                                                <IconButton
                                                    onClick={this.openContactsPopup.bind(this)}>
                                                    <PhoneIcon fontSize="inherit" color="primary" />
                                                </IconButton>
                                            </Box>
                                        </Fragment>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <FormControl
                                    fullWidth={true}
                                    required={true}
                                    {...this.getFieldError('date', false)}>
                                    <KeyboardDatePicker
                                        id="date-picker-dialog"
                                        format="yyyy-MM-dd"
                                        label="Date"
                                        required={true}
                                        value={formData.date}
                                        onChange={this.onFieldChange.bind(this, 'date')}
                                        {...this.getFieldError('date')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={5}>
                                <FormControl
                                    fullWidth={true}
                                    required={true}
                                    {...this.getFieldError('time', false)}>
                                    <TimePicker
                                        id="time-picker-1"
                                        label={getText('meeting-time')}
                                        format="HH:mm"
                                        minutesStep={5}
                                        ampm={false}
                                        required={true}
                                        value={formData.time}
                                        onChange={this.onFieldChange.bind(this, 'time')}
                                        {...this.getFieldError('time')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={7}>
                                <FormControl
                                    fullWidth={true}
                                    required={true}
                                    {...this.getFieldError('type', false)}>
                                    <InputLabel id="region-label">Type</InputLabel>
                                    <Select
                                        labelId="region-label"
                                        size="small"
                                        value={formData.type}
                                        onChange={this.onFieldChange.bind(this, 'type')}>
                                        <MenuItem value="">{getText('general-none')}</MenuItem>
                                        {types.map((type, i) => (
                                            <MenuItem value={type} key={type}>
                                                {getText('meeting-status_' + type)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {this.renderHelperText('type')}
                                </FormControl>
                            </Grid>
                            <Grid item xs={5}>
                                <FormControl
                                    fullWidth={true}
                                    required={true}
                                    {...this.getFieldError('duration', false)}>
                                    <TimePicker
                                        id="time-picker-2"
                                        label={getText('meeting-duration')}
                                        format="HH:mm"
                                        minutesStep={5}
                                        ampm={false}
                                        required={true}
                                        value={formData.duration}
                                        onChange={this.onFieldChange.bind(this, 'duration')}
                                        {...this.getFieldError('duration')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    handleHomeEndKeys
                                    options={
                                        !formData.client
                                            ? []
                                            : [
                                                  // Add the client contacts to the list
                                                  ...clients
                                                      .find(
                                                          (client) =>
                                                              client._id === formData.client,
                                                      )
                                                      .client_contacts?.filter(
                                                          (contact) =>
                                                              contact.email !== '' &&
                                                              contact.email !== null,
                                                      )
                                                      .map((contact) => {
                                                          return {
                                                              ...contact,
                                                              contactType: 'Client',
                                                          };
                                                      }),
                                                  // Add the 1200 Degrees users to the list
                                                  ...users /*.filter(contact => contact.email !== '' && contact.email !== null)*/
                                                      .map((user) => {
                                                          return {
                                                              ...user,
                                                              contactType: user.role.name,
                                                          };
                                                      }),
                                              ]
                                    }
                                    value={formData.attendees}
                                    onChange={this.onFieldChange.bind(this, 'attendees')}
                                    getOptionLabel={(option) => option.email}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <div
                                                style={{
                                                    fontWeight:
                                                        option.contactType === 'Client' ? 700 : 400,
                                                }}>
                                                <div>{option.email}</div>
                                                <div style={{ fontSize: '0.8em' }}>
                                                    {option.contactType === 'Client' ||
                                                    !option.contactType
                                                        ? option.name
                                                        : option.first_name +
                                                          ' ' +
                                                          option.last_name}{' '}
                                                    {option.contactType && option.contactType}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    filterOptions={(options, params) => {
                                        const filteredOptions = options.filter((option) => {
                                            return (
                                                option.email
                                                    .toLowerCase()
                                                    .indexOf(params.inputValue.toLowerCase()) !== -1
                                            );
                                        });
                                        // Add an option to
                                        if (params.inputValue !== '') {
                                            filteredOptions.push({
                                                email: params.inputValue,
                                                name: getText('meeting-add-attendee'),
                                            });
                                        }
                                        return filteredOptions;
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            variant="standard"
                                            label="Attendees"
                                            placeholder="Emails"
                                            size="small"
                                            fullWidth={true}
                                            {...params}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Description visible to the customer"
                                    multiline
                                    rows={4}
                                    // render the latest notes here from client
                                    //...
                                    value={formData.notes}
                                    fullWidth={true}
                                    onChange={this.onFieldChange.bind(this, 'notes')}
                                    {...this.getFieldError('notes')}
                                />
                            </Grid>
                        </Grid>
                        <FormControl
                            style={textFieldStyle}
                            required={false}
                            {...this.getFieldError('reminder', false)}>
                            <InputLabel id="reminder-label">{getText('reminder-title')}</InputLabel>
                            <Select
                                labelId="reminder-label"
                                size="small"
                                value={formData.reminder}
                                onChange={this.onFieldChange.bind(this, 'reminder')}>
                                <MenuItem value={0}>{getText('general-none')}</MenuItem>
                                {reminders.map((reminder, i) => (
                                    <MenuItem value={reminder} key={reminder}>
                                        {getText(reminder + '-day_before')}
                                    </MenuItem>
                                ))}
                            </Select>
                            {this.renderHelperText('reminder')}
                        </FormControl>
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={this.cancel.bind(this)}>
                        {getText('action-cancel')}
                    </Button>
                    <Button color="primary" onClick={this.save.bind(this)}>
                        {getText('action-save')}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }

    onFieldChange(attribute, e, val) {
        const formData = { ...this.state.formData };

        if (['date', 'time', 'duration'].includes(attribute)) {
            formData[attribute] = e;
        } else if (['attendees'].includes(attribute)) {
            val = val.filter((v) => validateEmail(v.email || v)).map((v) => v.email || v);

            formData[attribute] = val;
        } else {
            const field = e.target;
            const value = field.value;
            formData[attribute] = value;

            if (attribute === 'client') {
                //  if "none" is selected
                formData.notes = '';

                // render latest notes from client
                this.getClientLatestNotes(value);
                this.getClientLatestObjectives(value);
                // render upcoming projects from client
                this.getClientFutureProjects(value);
            }
        }

        this.setState({
            hasChanged: true,
            formData: formData,
        });
    }

    cancel() {
        if (this.state.hasChanged) {
            confirm({
                title: getText('popup-cancel_title'),
                text: getText('popup-cancel_message'),
                confirmLabel: getText('action-yes'),
                cancelLabel: getText('action-no'),
                onClose: (confirm) => {
                    if (confirm) {
                        this.close();
                    }
                },
            });
        } else {
            this.close();
        }
    }

    save() {
        if (this.state.hasChanged || !this.props.id) {
            const { formData } = this.state;
            const data = {};

            for (const attribute in formData) {
                if (['client'].includes(attribute)) {
                    data[attribute] = formData[attribute] !== '' ? formData[attribute] : null;
                } else if (['date'].includes(attribute)) {
                    if (formData[attribute] === '' || formData[attribute] === null) {
                        data[attribute] = null;
                    } else {
                        let date =
                            formData[attribute] instanceof Date
                                ? formData[attribute]
                                : Date.parse(formData[attribute]);
                        data[attribute] = format(date, 'yyyy-MM-dd');
                    }
                } else if (['attendees'].includes(attribute)) {
                    data[attribute] = formData[attribute].map((val) => {
                        return { email: val };
                    });
                } else if (['time', 'duration'].includes(attribute)) {
                    if (formData[attribute] === '' || formData[attribute] === null) {
                        data[attribute] = null;
                    } else {
                        let date =
                            formData[attribute] instanceof Date
                                ? formData[attribute]
                                : Date.parse(formData[attribute]);
                        data[attribute] = format(date, 'HH:mm:00');
                    }
                } else {
                    data[attribute] = formData[attribute];
                }
            }

            // Update if the id is set, create if not
            this.setState({
                formErrors: null,
            });

            if (this.props.id) {
                Api.call('put', '/client-meetings/' + this.props.id, data)
                    .then((response) => {
                        this.handleFormSuccess(response);
                    })
                    .catch((error) => {
                        this.handleFormError(error);
                    });
            } else {
                Api.call('post', '/client-meetings', {
                    ...data,
                    client: this.props.clientId || data.client,
                })
                    .then((response) => {
                        this.handleFormSuccess(response);
                    })
                    .catch((error) => {
                        this.handleFormError(error);
                    });
            }
        } else {
            this.close();
        }
    }

    handleFormSuccess(response) {
        this.props.updateMeeting(response.data);
        this.close();
    }

    handleFormError(error) {
        if (error.response.status >= 500) {
            alert('error', error.response.statusText);
            return;
        }

        if (error.response.status === 400) {
            this.setState({
                formErrors: error.response.data.data,
            });
        }
    }

    getFieldError(field, getHelperText = true) {
        const { formErrors } = this.state;

        if (formErrors !== null) {
            const formError = formErrors.find((formError) => formError.field === field);

            if (formError) {
                const data = {
                    error: true,
                };

                if (getHelperText) {
                    data.helperText = formError.message;
                }

                return data;
            }
        }

        const data = {
            error: false,
        };

        if (getHelperText) {
            data.helperText = null;
        }

        return data;
    }

    getClientLatestNotes(clientId) {
        if (!clientId) {
            this.setState({
                notesHistory: [],
            });

            return;
        }

        this.refreshHistory(clientId, 'notes');
    }

    getClientLatestObjectives(clientId) {
        if (!clientId) {
            this.setState({
                objectivesHistory: [],
            });

            return;
        }

        this.refreshHistory(clientId, 'objectives');
    }

    refreshHistory(clientId, type) {
        if (!clientId) {
            return;
        }
        
        Api.call('get', '/clients/' + clientId + `/${type}-history`)
            .then((response) => {
                const data = {};

                data[type + 'History'] = response.data;

                this.setState(data);
            })
            .catch((error) => {});
    }

    getClientFutureProjects(clientId) {
        if (!clientId) return;

        Api.call('get', `/client-projects?_limit=0&client=${clientId}&status=future`).then(
            (results) => {
                let { data } = results;

                if (!Array.isArray(data)) {
                    data = [];
                }

                this.setState({
                    futureProjects: data.map(({ name, date }) => ({
                        name: name,
                        date: date,
                    })),
                });
            },
        );
    }

    renderHelperText(field) {
        const error = this.getFieldError(field);

        if (!error.error) {
            return null;
        }

        return <FormHelperText>{error.helperText}</FormHelperText>;
    }

    close() {
        this.setState({
            opened: false,
        });
    }

    componentDidMount() {
        const requests = [
            ClientFactory.getClients({
                select: 'name,address',
                populate: 'client_contacts',
            }),
            Api.call('get', '/users'),
        ];

        if (this.props.id) {
            requests.push(Api.call('get', '/client-meetings/' + this.props.id));
        }

        this.getClientLatestNotes(this.props.clientId);
        this.getClientLatestObjectives(this.props.clientId);

        Promise.allSettled(requests).then((results) => {
            const state = {
                isLoading: false,
            };

            if (results[0].status === 'fulfilled') {
                state.clients = results[0].value;
                console.log(state.clients);
            }

            if (results[1].status === 'fulfilled') {
                state.users = results[1].value.data;
            }

            if (results[2] && results[2].status === 'fulfilled') {
                state.meeting = results[2].value.data;

                const formData = { ...this.state.formData };

                for (const attribute in formData) {
                    if (['client'].includes(attribute)) {
                        formData[attribute] = !state.meeting[attribute]
                            ? ''
                            : state.meeting[attribute]._id;
                        //this.getClientFutureProjects(formData.client);
                    } else if (['type'].includes(attribute)) {
                        formData[attribute] = !state.meeting[attribute]
                            ? ''
                            : state.meeting[attribute];
                    } else if (['date'].includes(attribute)) {
                        formData[attribute] = state.meeting[attribute] + ' 00:00:00';
                    } else if (['attendees'].includes(attribute)) {
                        formData[attribute] = state.meeting[attribute].map((v) => v.email);
                    } else if (['time', 'duration'].includes(attribute)) {
                        formData[attribute] = '2021-01-01 ' + state.meeting[attribute];
                    } else {
                        formData[attribute] = state.meeting[attribute];
                    }
                }

                state.formData = formData;
            }

            this.getClientFutureProjects(state?.formData?.client || this.props.clientId);

            this.setState(state);
        });
    }

    /**
     * History popup
     */

    openHistoryPopup(type) {
        this.setState({
            historyPopupOpened: true,
            historyPopupType: type,
        });
    }

    closeHistoryPopup() {
        this.setState({
            historyPopupOpened: false,
        });
    }

    /**
     * Selectors popup
     */

    openAddressesPopup() {
        this.setState({
            addressesPopupOpened: true,
        });
    }

    openContactsPopup() {
        this.setState({
            contactsPopupOpened: true,
        });
    }
    openProjectsPopup() {
        this.setState({
            projectsPopupOpened: true,
        });
    }

    closeSelectorPopup() {
        this.setState({
            contactsPopupOpened: false,
            addressesPopupOpened: false,
            projectsPopupOpened: false,
        });
    }

    selectLocation(value) {
        this.setState({
            contactsPopupOpened: false,
            addressesPopupOpened: false,
            formData: {
                ...this.state.formData,
                location: value,
            },
        });
    }

    renderAddressesPopup() {
        const { formData, clients } = this.state;

        if (!formData.client || !clients) {
            return null;
        }

        const client = clients.find((client) => client._id === formData.client);
        const addresses = client.address;
        return (
            <Fragment>
                <DialogContent style={{ backgroundColor: '#F5F5F5' }}>
                    <Grid container>
                        {addresses.length > 0 ? null : (
                            <EmptyList message={getText('address-empty_list')} />
                        )}
                        {addresses.map((address, i) => (
                            <Grid item key={i} xs={12} sm={12} md={6} lg={4}>
                                <Box m={1}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">
                                                {address.name ||
                                                    address.address1 + ' ' + address.address2}
                                            </Typography>
                                            <Typography variant="body2">
                                                {address.address1} {address.address2}
                                            </Typography>
                                            <Typography variant="body2">
                                                {address.city}, {address.province}
                                            </Typography>
                                            <Typography variant="body2">
                                                {address.postal_code}, {address.country}
                                            </Typography>
                                        </CardContent>
                                        <Divider />
                                        <CardActions>
                                            <Button
                                                size="small"
                                                color="primary"
                                                onClick={this.selectLocation.bind(
                                                    this,
                                                    getFormattedAddress(address),
                                                )}>
                                                {getText('action-select')}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button color="secondary" onClick={this.closeSelectorPopup.bind(this)}>
                        {getText('action-cancel')}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }

    renderContactsPopup() {
        const { formData, clients } = this.state;

        if (!formData.client || !clients) {
            return null;
        }

        const client = clients.find((client) => client._id === formData.client);
        const client_contacts = client.client_contacts;

        const textFieldStyle = {
            marginLeft: '5px',
        };

        return (
            <Fragment>
                <DialogContent style={{ backgroundColor: '#F5F5F5' }}>
                    <Grid container>
                        {client_contacts.length > 0 ? null : (
                            <EmptyList message={getText('contact-empty_list')} />
                        )}
                        {client_contacts.map((contact, i) => {
                            const address = client.address.find(
                                (add) => add.id === contact.address_id,
                            );

                            return (
                                <Grid item key={i} xs={12} sm={12} md={6}>
                                    <Box m={1}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h6">{contact.name}</Typography>
                                                <Typography variant="subtitle1">
                                                    {contact.position}
                                                </Typography>

                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                    spacing={1}
                                                    style={{ marginTop: '10px' }}>
                                                    <Grid item xs={6}>
                                                        <Grid container alignItems="center">
                                                            <PhoneIcon />
                                                            <Typography
                                                                variant="body2"
                                                                style={textFieldStyle}>
                                                                {contact.phone || 'N/D'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Grid container alignItems="center">
                                                            <PhoneAndroidIcon />
                                                            <Typography
                                                                variant="body2"
                                                                style={textFieldStyle}>
                                                                {contact.mobile || 'N/D'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Grid container alignItems="center">
                                                            <EmailIcon />
                                                            <Typography
                                                                variant="body2"
                                                                style={textFieldStyle}>
                                                                {contact.email || 'N/D'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Grid container alignItems="center">
                                                            <RoomIcon />
                                                            <Typography
                                                                variant="body2"
                                                                style={textFieldStyle}>
                                                                {address
                                                                    ? address.name ||
                                                                      address.address1 +
                                                                          ' ' +
                                                                          address.address2
                                                                    : 'N/D'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            <Divider />
                                            <CardActions>
                                                <Button
                                                    size="small"
                                                    color="primary"
                                                    onClick={this.selectLocation.bind(
                                                        this,
                                                        (contact.phone || contact.email) +
                                                            ` (${contact.name})`,
                                                    )}>
                                                    {getText('action-select')}
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button color="secondary" onClick={this.closeSelectorPopup.bind(this)}>
                        {getText('action-cancel')}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }

    renderProjectsPoppup() {
        const { futureProjects } = this.state;

        if (!futureProjects) {
            return null;
        }

        return (
            <Fragment>
                <DialogContent style={{ backgroundColor: '#F5F5F5' }}>
                    <Grid container>
                        {futureProjects.length > 0 ? null : (
                            <EmptyList message={getText('project-empty_list')} />
                        )}
                        {futureProjects.map((project, i) => (
                            <Grid item key={i} xs={12} sm={12} md={6} lg={4}>
                                <Box m={1}>
                                    <Card>
                                        <CardContent>
                                            <Grid
                                                container
                                                spacing={2}
                                                justifyContent="space-around"
                                                alignItems="center">
                                                <Grid item>
                                                    <Typography variant="h6">
                                                        {project.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1">
                                                        {project.date}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button color="secondary" onClick={this.closeSelectorPopup.bind(this)}>
                        {getText('action-cancel')}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }
}

export default MeetingForm;

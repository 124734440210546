import axios from 'axios';
//import Cookies from 'js-cookie';
import { getLang } from '../utils/LocaleHelper';
import { setUser } from '../utils/UserHelper';
class Api {
    constructor() {
        // Config
        this.baseUrl = process.env.REACT_APP_API_BASE_URL;

        // Runtime
        this.jwt = null;
        const isImpersonate = window.localStorage.getItem('session_impersonate');

        this.state = {
            impersonateMode: !!isImpersonate,
        };
    }

    /**
     * AUTH
     */
    login(identifier, password) {
        return axios
            .post(this.baseUrl + '/auth/local', {
                identifier: identifier,
                password: password,
            })
            .then((response) => {
                if (response.data.jwt) {
                    window.localStorage.setItem('session', response.data.jwt);
                    setUser(response.data.user);
                } else {
                    this.logout();
                }

                return true;
            });
    }

    impersonate(id) {
        // refactor to call method
        return this.call('post', '/auth/impersonate', {
            id: id,
        }).then((response) => {
            if (response.data.jwt) {
                window.localStorage.setItem('session_impersonate', response.data.jwt);
                setUser(response.data.user, false);
                this.state.impersonateMode = true;
                // go to dashboard
                window.location.replace('/dashboard');
            }

            return true;
        });
    }

    logout(redirect = true) {
        //Cookies.remove('session');
        window.localStorage.clear();
        if (redirect) {
            window.location.replace('/');
        }
    }

    /**
     * Validate if the user is logged in
     */
    loggedIn() {}

    /**
     * CALL
     */
    call(method, url, data = null, localized = false) {
        const jwt = this.state.impersonateMode
            ? window.localStorage.getItem('session_impersonate')
            : window.localStorage.getItem('session');

        const loggedIn = typeof jwt !== 'undefined' && jwt;

        // Shortcut for non logged users
        if (method === 'get' && url === '/users/me' && !loggedIn) {
            return new Promise((resolve, reject) => {
                reject();
            });
        }

        // Add local
        if (localized) {
            const lang = getLang();

            if (['get', 'delete'].includes(method)) {
                if (url.includes('?')) {
                    url += '&_locale=' + lang;
                } else {
                    url += '?_locale=' + lang;
                }
            } else {
                if (data) {
                    data['locale'] = lang;
                } else {
                    data = {
                        locale: lang,
                    };
                }
            }
        }

        // Setup the axios options
        const options = {
            method: method,
            url: this.baseUrl + url,
            data: data,
            headers: {
                accept: 'application/json',
                // send ip address
                'ip-address': window.ipAddress,
                // used to identify the request as coming from the client and not from admin
                'from-client': true,
            },
        };

        if (loggedIn) {
            options.headers['Authorization'] = `Bearer ${jwt}`;
        }

        // Call the API
        return axios(options);
    }

    /**
     * Localized call
     */
    localizedCall(method, url, data = null) {
        const lang = getLang();

        // If the lang is already en, do not translate
        if (lang === 'en') {
            return this.call(method, url, data);
        }

        return new Promise((resolve, reject) => {
            Promise.allSettled([
                this.call(method, url, data),
                this.call(method, url, data, true),
            ]).then((results) => {
                if (results[0].status !== 'fulfilled') {
                    return reject(results[0]);
                }

                if (results[1].status !== 'fulfilled') {
                    return reject(results[1]);
                }

                // Merge the data
                let data = results[0].value.data;
                let localizedData = results[1].value.data;

                data = this.mergeLocale(data, localizedData);

                resolve({
                    data: data,
                });
            });
        });
    }

    mergeLocale(data, localizedData) {
        for (let i = 0; i < data.length; i++) {
            // Find the corresponding localized data
            let localized = localizedData.find((locale) => {
                if (locale.localizations) {
                    for (let u = 0; u < locale.localizations.length; u++) {
                        if (
                            locale.localizations[u].locale === 'en' &&
                            locale.localizations[u].id === data[i]._id
                        ) {
                            return true;
                        }
                    }
                }

                return false;
            });

            let formattedData = { ...data[i] };

            if (localized) {
                formattedData = {
                    ...localized,
                    _id: data[i]._id,
                    id: data[i].id,
                    localizations: data[i].localizations,
                    updatedAt: data[i].updatedAt,
                    __v: data[i].__v,
                };

                // Loop through each property to see if there's some nested arrays
                for (const property in data[i]) {
                    if (Array.isArray(data[i][property])) {
                        formattedData[property] = this.mergeLocale(
                            data[i][property],
                            localized[property] || [],
                        );
                    }
                }
            }

            data[i] = formattedData;
        }

        return data;
    }
}

export default new Api();

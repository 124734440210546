import React, { Component } from 'react';
import { withRouter } from 'react-router';
import AdminLayout from '../../layout/AdminLayout';
import { withUser } from '../../../utils/ReactWrappers';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Api from '../../../api/Api';
import Loader from '../../widgets/Loader';
import IconButton from '@material-ui/core/IconButton';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import EditIcon from '@material-ui/icons/Edit';
import ToolTip from '@material-ui/core/Tooltip';
import { confirm } from '../../../utils/UIHelper';
import { getText } from '../../../utils/LocaleHelper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import UserForm from '../../form/UserForm';

class AdminUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            users: null,
            userFormOpen: false,
            currUser: null,
        };
    }

    getBreadcrumbs() {
        return [
            {
                label: 'Users',
                link: '/admin/users',
            },
        ];
    }

    renderCustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    componentDidMount() {
        Promise.allSettled([Api.call('get', '/users?confirmed=true')]).then((results) => {
            if (results[0].status === 'fulfilled') {
                this.setState({
                    users: results[0].value.data,
                    isLoading: false,
                });
            }
        });
    }

    renderActionsCell(params) {
        const { id } = params.row;

        // only if not admin
        if (params.row.role === 'Admin') return null;

        return (
            <div>
                <IconButton
                    color="primary"
                    onClick={() => {
                        confirm({
                            title: `Impersonnating Confirmation`,
                            text: `Are you sure you want to connect as ${params.row.first_name} ${params.row.last_name}?`,
                            confirmLabel: getText('action-yes'),
                            cancelLabel: getText('action-no'),
                            onClose: (confirm) => {
                                if (confirm) {
                                    this.impersonateUser(id);
                                }
                            },
                        });
                    }}>
                    <ToolTip title={`Connect as ${params.row.first_name}`}>
                        <CompareArrowsIcon />
                    </ToolTip>
                </IconButton>
                <IconButton
                    color="primary"
                    onClick={() => {
                        this.openUserForm(params.row);
                    }}>
                    <ToolTip title={`Edit ${params.row.first_name}`}>
                        <EditIcon />
                    </ToolTip>
                </IconButton>
            </div>
        );
    }

    render() {
        const { isLoading, users, currUser, userFormOpen } = this.state;

        const formattedUsers = users
            ?.filter((user) => {
                // get only confirmed and not blocked users
                return user.confirmed && !user.blocked;
            })
            .map((user) => {
                const { id, first_name, last_name, email, role, region, language } = user;
   
                return {
                    id,
                    first_name,
                    last_name,
                    email,
                    role: role.name,
                    region,
                    language,
                };
            });

        const columns = [
            { field: 'id', headerName: 'ID', width: 60 },
            { field: 'first_name', headerName: 'First name', width: 150 },
            { field: 'last_name', headerName: 'Last name', width: 150 },
            { field: 'email', headerName: 'Email', width: 250 },
            { field: 'role', headerName: 'Role', width: 150 },
            { field: 'region', headerName: 'Region', width: 130 },
            { field: 'language', headerName: 'Language', width: 130 , valueFormatter: (params) => {
                return params.value === 'en' ? 'English' : 'Français';
            }},
            {
                field: 'actions',
                headerName: 'Actions',
                width: 150,
                renderCell: (params) => this.renderActionsCell(params),
            },
        ];

        return (
            <AdminLayout title="Users" breadcrumbs={this.getBreadcrumbs()}>
                <Box mb={2} display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            this.openUserForm();
                        }}>
                        {getText('action-add')}
                    </Button>
                </Box>
                {!isLoading ? (
                    <div style={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={formattedUsers}
                            columns={columns}
                            components={{
                                Toolbar: this.renderCustomToolbar,
                            }}
                        />
                    </div>
                ) : (
                    <Loader />
                )}
                {userFormOpen && (
                    <UserForm
                        currUser={currUser}
                        onClose={() => {
                            this.setState({
                                userFormOpen: false,
                                currUser: null,
                            });

                            // reload
                            this.componentDidMount();
                        }}
                    />
                )}
            </AdminLayout>
        );
    }

    openUserForm(data) {
        this.setState({
            userFormOpen: true,
            currUser: data ? data : null,
        });
    }

    impersonateUser(id) {
        Api.impersonate(id);
    }
}

export default withRouter(withUser(AdminUsers));

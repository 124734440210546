import React, {Component, Fragment} from 'react';
import { withRouter } from 'react-router';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Api from '../../api/Api';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { alert, confirm } from '../../utils/UIHelper';
import { getText } from '../../utils/LocaleHelper';
import Loader from '../widgets/Loader';
import ClientFactory from '../../factory/ClientFactory';

class ClientForm extends Component {
    static defaultProps = {
        id: null,
        closeForm: null,
        updateClient: null,
    }

	constructor(props) {
		super(props);

        this.addressInput = React.createRef();
        this.autocomplete = null;

		this.state = {
			isLoading: true,
            hasChanged: false,
			client: null,
            regions: null,
            reps: null,
            opened: true,
            address: '',
            formErrors: null,
            formData: {
                name: '',
                fidelio_ids: [],
                region: '',
                sales_representative: '',
                fire_station_count: '',
                population: '',
                firefighters_full_time_count: '',
                firefighters_part_time_count: '',
                address: [],
            },

            // Fidelio ID Form
            fidelioIdDialogOpened: false,
            fidelioIdSearch: '',
            fidelioClients: null,
		}
	}

    renderFidelioIdDialog() {
        let filteredClients = null;

        if(this.state.fidelioClients && this.state.fidelioIdSearch.length >= 3) {
            filteredClients = this.state.fidelioClients.filter(client => {
                return client.name.toLowerCase().includes(this.state.fidelioIdSearch.toLowerCase());
            });
        }

        return (
            <Dialog
                disableEscapeKeyDown={true}
                maxWidth='sm'
                fullWidth={true}
                open={this.state.fidelioIdDialogOpened}
                onClose={this.props.onDialogClose}
                disableEnforceFocus
            >
                <DialogTitle>{"Client"}</DialogTitle>
                <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label={getText('client-name')}
                                    required={true}
                                    fullWidth={true}
                                    size="small"
                                    value={this.state.fidelioIdSearch}
                                    onChange={(e) => {this.setState({fidelioIdSearch: e.target.value})}}
                                />
                            </Grid>
                            {
                                filteredClients && <List>
                                    {
                                        filteredClients.map(client => {
                                            return (
                                                <ListItem button onClick={this.onSelectFidelioId.bind(this, client.id)}>
                                                    <Box display="flex" alignItems="center">
                                                        <Box mr={1} style={{
                                                            width: '120px',
                                                            flexShrink: 0,
                                                        }}>
                                                            <ListItemText primary={client.id} />
                                                        </Box>
                                                        <ListItemText secondary={client.name} secondaryTypographyProps={{
                                                            align: 'left',
                                                        }}/>
                                                    </Box>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            }
                            
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={() => {this.setState({fidelioIdDialogOpened: false})}}>
                            {getText('action-cancel')}
                        </Button>
                    </DialogActions>
            </Dialog>
        );
    }

	render() {
		return (
            <Fragment>
                <Dialog
                    disableEscapeKeyDown={true}
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.opened}
                    onClose={this.props.onDialogClose}
                    disableEnforceFocus
                    TransitionProps={{
                        onExited: this.props.closeForm
                    }}
                >
                    <DialogTitle>{"Client"}</DialogTitle>
                    {this.renderContent()}
                </Dialog>
                {this.renderFidelioIdDialog()}
            </Fragment>
		);
	}

    onSelectFidelioId(fidelioId) {
        this.setState({
            fidelioIdDialogOpened: false,
            hasChanged: true,
            formData: {
                ...this.state.formData,
                fidelio_ids: [...this.state.formData.fidelio_ids, fidelioId],
            }
        });
    }

    onOpenFidelioIdDialog() {
        this.setState({
            fidelioIdDialogOpened: true,
            fidelioIdSearch: '',
        });

        // Load Fidelio Clients
        if(!this.state.fidelioClients) {
            ClientFactory.getFidelioClients()
                .then((fidelioClients) => {
                    // Get unique fidelio ids
                    let fidelioIds = [];

                    const uniqueFidelioClients = fidelioClients.data.filter(fidelioClient => {
                        if(!fidelioIds.includes(fidelioClient.id)) {
                            fidelioIds.push(fidelioClient.id);
                            return true;
                        }
                    });

                    this.setState({
                        fidelioClients: uniqueFidelioClients.map(fidelioClient => {
                            return {
                                id: fidelioClient.id,
                                name: [
                                    fidelioClient.description,
                                    fidelioClient.adresse,
                                    fidelioClient.code_postal,
                                    fidelioClient.ville,
                                    fidelioClient.xx_provinces_id,
                                ].join(', '),
                            }
                        }),
                    });
                });
        }
    }

    onDialogClose(e, reason) {
        if(reason === 'backdropClick') {
            return;
        }
    }

    renderContent() {
        const {isLoading, formData, regions, reps, client} = this.state;
        
        if(isLoading) {
            return <Loader />;
        }

        return (
            <Fragment>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label={getText('client-name')}
                                required={true}
                                fullWidth={true}
                                size="small"
                                value={formData.name}
                                onChange={this.onFieldChange.bind(this, 'name')}
                                {...this.getFieldError('name')}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl fullWidth={true} required={true} {...this.getFieldError('region', false)}>
                                <InputLabel id="region-label">{getText('client-region')}</InputLabel>
                                <Select
                                    labelId="region-label"
                                    size="small"
                                    value={formData.region}
                                    onChange={this.onFieldChange.bind(this, 'region')}
                                >
                                    <MenuItem value="">{getText('general-none')}</MenuItem>
                                    {regions.map((region, i) => <MenuItem value={region._id} key={i}>{region.name}</MenuItem>)}
                                </Select>
                                {this.renderHelperText('region')}
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl fullWidth={true} required={true} {...this.getFieldError('sales_representative', false)}>
                                {
                                    this.props.user.role.type === 'sales_representative' ?
                                    <TextField
                                        disabled={true}
                                        size="small"
                                        value={client.sales_representative.first_name + ' ' + client.sales_representative.last_name}
                                        fullWidth={true}
                                        label="Représentant"
                                    /> : 
                                    <Fragment>
                                        <InputLabel id="rep-label">{getText('client-sales_rep')}</InputLabel>
                                        <Select
                                            labelId="rep-label"
                                            size="small"
                                            value={formData.sales_representative}
                                            onChange={this.onFieldChange.bind(this, 'sales_representative')}
                                        >
                                            <MenuItem value="">{getText('general-none')}</MenuItem>
                                            {reps.map((rep, i) => <MenuItem value={rep.id} key={i}>{rep.first_name + ' ' + rep.last_name}</MenuItem>)}
                                        </Select>
                                    </Fragment>
                                }
                                {this.renderHelperText('sales_representative')}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex">
                                <FormControl fullWidth={true}>
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        disableClearable={true}
                                        options={[]}
                                        value={formData.fidelio_ids}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip label={option} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                variant="standard"
                                                label="Fidelio IDs"
                                                size="small"
                                                fullWidth={true}
                                                {...params}
                                            />
                                        )}
                                        onChange={this.onFieldChange.bind(this, 'fidelio_ids')}
                                    />
                                </FormControl>
                                <Box>
                                    <IconButton onClick={this.onOpenFidelioIdDialog.bind(this)} > 
                                        <SearchIcon fontSize="inherit" color="primary" />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                label={getText('client-firestation_count')}
                                fullWidth={true}
                                size="small"
                                value={formData.fire_station_count}
                                onChange={this.onFieldChange.bind(this, 'fire_station_count')}
                                {...this.getFieldError('fire_station_count')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                label="Population"
                                fullWidth={true}
                                size="small"
                                value={formData.population}
                                onChange={this.onFieldChange.bind(this, 'population')}
                                {...this.getFieldError('population')}
                            />
                        </Grid>
                        {
                            this.props.id ? null :
                            <Grid item xs={12}>
                                <TextField
                                    label={getText('client-address')}
                                    fullWidth={true}
                                    size="small"
                                    value={this.state.address}
                                    onChange={this.onAddressChange.bind(this)}
                                    inputRef={this.addressInput}
                                    required={true}
                                    {...this.getFieldError('address')}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Typography variant="body2" component="h2">{getText('client-fireman_count')}</Typography>
                        </Grid>
                        <Grid item xs={6}> 
                            <TextField
                                type="number"
                                label={getText('client-full_time')}
                                fullWidth={true}
                                size="small"
                                value={formData.firefighters_full_time_count}
                                onChange={this.onFieldChange.bind(this, 'firefighters_full_time_count')}
                                {...this.getFieldError('firefighters_full_time_count')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                label={getText('client-part_time')}
                                fullWidth={true}
                                size="small"
                                value={formData.firefighters_part_time_count}
                                onChange={this.onFieldChange.bind(this, 'firefighters_part_time_count')}
                                {...this.getFieldError('firefighters_part_time_count')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={this.cancel.bind(this)}>
                        {getText('action-cancel')}
                    </Button>
                    <Button color="primary" onClick={this.save.bind(this)}>
                        {getText('action-save')}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }

    registerAutocomplete() {
        // const center = { lat: 50.064192, lng: -130.605469 };

        // Create a bounding box with sides ~10km away from the center point
        /*const defaultBounds = {
            north: center.lat + 0.1,
            south: center.lat - 0.1,
            east: center.lng + 0.1,
            west: center.lng - 0.1,
        };*/

        const options = {
            componentRestrictions: { country: "ca" },
            origin: { lat: 53, lng: -70 },
            fields: ["formatted_address", "address_components", "geometry"],
            strictBounds: false,
            //types: ["establishment"],
        };

        this.autocomplete = new window.google.maps.places.Autocomplete(this.addressInput.current, options);
        this.autocomplete.addListener('place_changed', this.onPlaceChanged.bind(this, this.autocomplete));
    }

    onPlaceChanged(autocomplete) {
        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert("No details available for input: '" + place.name + "'");
            return;
        }

        const formData = {...this.state.formData};

        formData['address'] = [
            {
                name: getText('client-main_address'),
                address1: this.getAddressComponentByType(place.address_components, 'street_number', '') + ' ' + this.getAddressComponentByType(place.address_components, 'route', ''),
                address2: '',
                city: this.getAddressComponentByType(place.address_components, 'locality'),
                province: this.getAddressComponentByType(place.address_components, 'administrative_area_level_1'),
                postal_code: this.getAddressComponentByType(place.address_components, 'country'),
                country: this.getAddressComponentByType(place.address_components, 'postal_code'),
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
            }
        ];

        this.setState({
            address: place.formatted_address,
            hasChanged: true,
            formData: formData,
        });
    }

    getAddressComponentByType(address_components, type, defaultVal = null, format = 'short_name') {
        const component = address_components.find((address_component) => {
            return address_component.types.includes(type);
        });

        if(component) {
            return component[format];
        }

        return defaultVal;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.addressInput.current && this.autocomplete === null) {
            this.registerAutocomplete();
        }
    }

    onAddressChange(e) {
        this.setState({
            address: e.target.value,
        })
    }

    onFieldChange(attribute, e, val) {
        let value;

        if(attribute === 'fidelio_ids') {
            value = val;
        } else {
            const field = e.target;
            value = field.value;
        }

        const formData = {...this.state.formData};

        formData[attribute] = value;

        this.setState({
            hasChanged: true,
            formData: formData,
        });
    }

    cancel() {
        if(this.state.hasChanged) {
            confirm({
                title: getText('popup-cancel_title'),
                text: getText('popup-cancel_message'),
                confirmLabel: getText('action-yes'),
                cancelLabel: getText('action-no'),
                onClose: (confirm) => {
                    if(confirm) {
                        this.close();
                    }
                },
            });
        } else {
            this.close();
        }
    }

    save() {
        if(this.state.hasChanged || !this.props.id) {
            const {formData} = this.state;
            const data = {};

            for(const attribute in formData) {
                if(['region', 'sales_representative'].includes(attribute)) {
                    data[attribute] = formData[attribute] !== '' ? formData[attribute] : null;
                } else if(['fire_station_count', 'firefighters_full_time_count', 'firefighters_part_time_count', 'population'].includes(attribute)) {
                    let value = parseInt(formData[attribute]);
                    data[attribute] = isNaN(value) ? null : value;
                } else if(['fidelio_ids'].includes(attribute)) {
                    data[attribute] = formData[attribute].map(id => {
                        return {
                            fidelio_id: id,
                        }
                    });
                } else {
                    data[attribute] = formData[attribute];
                }
            }

            // Update if the id is set, create if not
            this.setState({
                formErrors: null,
            });

            if(this.props.id) {
                Api.call('put', '/clients/' + this.props.id, data)
                    .then((response) => {
                        this.handleFormSuccess(response, false);
                    })
                    .catch((error) => {
                        this.handleFormError(error);
                    });
            } else {
                Api.call('post', '/clients', data)
                    .then((response) => {
                        this.handleFormSuccess(response, true);
                    })
                    .catch((error) => {
                        this.handleFormError(error);
                    });
            }

            ClientFactory.forceRefresh();
        } else {
            this.close();
        }
    }

    handleFormSuccess(response, isNew) {
        this.props.updateClient(response.data);
        this.close();

        if(isNew) {
            const {history} = this.props;

		    history.push('/client/' + response.data.id);
        }
    }

    handleFormError(error) {
        if(error.response.status >= 500) {
            alert('error', error.response.statusText);
            return;
        }

        if(error.response.status === 400) {
            this.setState({
                formErrors: error.response.data.data,
            });
        }
    }

    getFieldError(field, getHelperText = true) {
        const { formErrors } = this.state;

        if(formErrors !== null) {
            const formError = formErrors.find(formError => formError.field === field);

            if(formError) {
                const data = {
                    error: true,
                };

                if(getHelperText) {
                    data.helperText = formError.message;
                }

                return data;
            }
        }
        
        const data = {
            error: false,
        };

        if(getHelperText) {
            data.helperText = null;
        }

        return data;
    }

    renderHelperText(field) {
        const error = this.getFieldError(field);

        if(!error.error) {
            return null;
        }

        return <FormHelperText>{error.helperText}</FormHelperText>
    }

    close() {
        this.setState({
            opened: false,
        });
    }

	componentDidMount() {
        const requests = [
			Api.call('get', '/regions'),
		];

        if(this.props.id) {
            requests.push(Api.call('get', '/clients/' + this.props.id));
        } else {
            requests.push(new Promise((res, rej) => {rej()}));
        }

        if(this.props.user.role.type !== 'sales_representative') {
            requests.push(Api.call('get', '/sales-representative', null, false));
        } else {
            requests.push(new Promise((res, rej) => {rej()}));
        }

		Promise.allSettled(requests)
		.then((results) => {           
			const state = {
				isLoading: false,
			}

            const formData = {...this.state.formData};

			if(results[0].status === 'fulfilled') {
				state.regions = results[0].value.data;
			}

            if(results[1].status === 'fulfilled') {
				state.client = results[1].value.data;

                for(const attribute in formData) {
                    if(['region', 'sales_representative'].includes(attribute)) {
                        formData[attribute] = !state.client[attribute] ? '' : state.client[attribute]._id;
                    } else if(['fire_station_count', 'firefighters_full_time_count', 'firefighters_part_time_count', 'population'].includes(attribute)) {
                        formData[attribute] = state.client[attribute] === null ? '' : state.client[attribute];
                    } else if(['fidelio_ids'].includes(attribute)) {
                        formData[attribute] = state.client[attribute].map(id => id.fidelio_id);
                    } else {
                        formData[attribute] = state.client[attribute];
                    }
                }
			} else {
                if(this.props.user.role.type === 'sales_representative') {
                    formData['sales_representative'] = this.props.user._id;
                    state.client = {
                        sales_representative: this.props.user,
                    }
                }
            }

			if(results[2].status === 'fulfilled') {
				state.reps = results[2].value.data;
			}

            state.formData = formData;

			this.setState(state);
		});
	}
}

export default withRouter(ClientForm);
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import moment from 'moment';

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';

import { updateRelation, deleteRelation } from '../../utils/ObjectHelper';

import Api from '../../api/Api';
import MeetingForm from '../form/MeetingForm';
import SearchResults from '../layout/SearchResults';
import MeetingCard from '../cards/MeetingCard';
import { spiltByMonthListItems } from '../../utils/DatedListHelper';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { upperFirst } from '../../utils/StringHelper';
import ListFilter from './ListFilter';
import { filterList } from '../../utils/ListHelper';
import { getLang, getText } from '../../utils/LocaleHelper';
import { confirm } from '../../utils/UIHelper';
import EmptyList from '../layout/EmptyList';
import { withUser } from '../../utils/ReactWrappers';
import Loader from '../widgets/Loader';
import HttpHelper from '../../utils/HttpHelper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class MeetingList extends Component {
    static defaultProps = {
        oneColumn: false,
        renderPastMeetings: true,
        labelSingle: null,
        labelPlural: null,
        buttonLabel: null,
        clientId: null,
        onButtonClick: null,
        linkToClient: false,
        showSearch: true,
        showHeader: true,
        daysLimit: 0, // 0 means no limit
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            meetings: null,
            types: null,
            meetingFilters: null,
            clientFilters: null,
            meetingFormOpened: false,
            meetingFormData: {
                id: null,
                clientId: null,
            },
            meetingNotesOpened: false,
            meetingNotesData: {
                id: null,
                clientId: null,
            },
            canEdit: props.user.role.type !== 'consultation',
            expandedMonths: [],
        };
    }

    render() {
        const { showSearch, clientId } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            return <Loader />;
        }

        const types = ['demo', 'follow_up', 'courtesy', 'commissioning', 'other'];

        return (
            <Box>
                {!showSearch ? null : (
                    <Grid container spacing={0} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <ListFilter
                                uniqueSearchType="meeting"
                                onFilterUpdate={this.onFilterUpdate.bind(this)}
                                filtersProps={this.state.meetingFilters}
                                fields={[
                                    {
                                        filter: 'type',
                                        defaultValue: '',
                                        element: (
                                            <Select
                                                labelId="type"
                                                size="small"
                                                label="test"
                                                displayEmpty={true}>
                                                <MenuItem value="">Type...</MenuItem>
                                                {types.map((type, i) => (
                                                    <MenuItem value={type} key={i}>
                                                        {getText('meeting-status_' + type)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        ),
                                    },
                                ]}
                            />
                        </Grid>
                        {clientId ? null : (
                            <Grid item>
                                <ListFilter
                                    uniqueSearchType="client"
                                    filtersProps={this.state.clientFilters}
                                    onFilterUpdate={this.onFilterUpdate.bind(this)}
                                    iconElement={<StoreMallDirectoryIcon />}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}

                {this.renderMeetings()}
                {this.renderMeetingForm()}
                {this.renderMeetingNotes()}
            </Box>
        );
    }

    onFilterUpdate(filters, type, emptyValue) {
        const { meetingFilters, clientFilters } = this.state;

        this.setState({
            meetingFilters: type === 'meeting' ? filters : emptyValue ? meetingFilters : '',
            clientFilters: type === 'client' ? filters : emptyValue ? clientFilters : '',
        });
    }

    renderMeetingForm() {
        const { meetingFormOpened, meetingFormData } = this.state;

        if (!meetingFormOpened) {
            return null;
        }

        return (
            <MeetingForm
                closeForm={this.closeMeetingForm.bind(this)}
                updateMeeting={this.updateMeeting.bind(this)}
                {...meetingFormData}
            />
        );
    }

    renderMeetings() {
        let { meetings, clientFilters, meetingFilters, canEdit, expandedMonths } = this.state;
        let { oneColumn, renderPastMeetings, showSearch, showHeader } = this.props;

        let filteredMeetings = meetings;

        // Filter meetings
        if (showSearch && meetingFilters) {
            filteredMeetings = filterList(meetings, meetingFilters, [
                'client.name',
                'client.address.city',
                'notes',
                'location',
                'date',
                'type',
            ]);

            filteredMeetings = filteredMeetings.filter((meeting) => {
                if (!meetingFilters.type) {
                    return true;
                }

                return meeting.type === meetingFilters.type;
            });
        }

        // Filter clients
        if (showSearch && clientFilters) {
            filteredMeetings = filterList(meetings, clientFilters, ['client.name']);
        }

        if (!renderPastMeetings) {
            // remove past meetings
            filteredMeetings = filteredMeetings.filter((meeting) => {
                const itemDate = moment(meeting.date);
                return itemDate.isSameOrAfter(moment(), 'day');
            });
        }

        // Sort meetings
        const { future, past } = spiltByMonthListItems(filteredMeetings);

        let pastMeetings = past;
        let futureMeetings = future;

        let incomingMeetings = [];

        if (!renderPastMeetings) {
            // get all incoming meetings count
            incomingMeetings = filteredMeetings.filter((meeting) => {
                const itemDate = moment(meeting.date);
                return itemDate.isSameOrAfter(moment(), 'day');
            });
        }

        const columnsWidth = oneColumn
            ? { xs: 12 }
            : {
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 4,
              };

        if (!renderPastMeetings) {
            pastMeetings = [];
        }
        let loopDate = null;
        return (
            <Box>
                {!showHeader ? null : (
                    <Box m={1}>
                        <SearchResults
                            value={
                                renderPastMeetings
                                    ? filteredMeetings.length
                                    : incomingMeetings.length
                            }
                            labelSingle={
                                this.props.labelSingle || getText('meeting-result_singular')
                            }
                            labelPlural={this.props.labelPlural || getText('meeting-result_plural')}
                            buttonLabel={
                                canEdit ? this.props.buttonLabel || getText('action-create') : null
                            }
                            onButtonClick={
                                this.props.onButtonClick || this.openMeetingForm.bind(this, null)
                            }
                        />
                    </Box>
                )}
                <Box container m={1}>
                    {Object.keys(futureMeetings).map((meeting, i) => {
                        const items = futureMeetings[meeting];

                        return (
                            <Accordion defaultExpanded key={i}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography style={{ fontWeight: '500' }}>{meeting}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        {items.length === 0 && (
                                            <Grid item xs={12}>
                                                <Box m={1}>
                                                    <Typography variant="body2">
                                                        {getText('meeting-empty_list')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                        {items.map((item, i) => {
                                            if (loopDate !== item.date) {
                                                loopDate = item.date;

                                                return (
                                                    <Fragment key={meeting.id}>
                                                        <Grid item sm={12}>
                                                            <Box m={1} mt={2}>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    component="h2">
                                                                    {this.formatDate(loopDate)}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Divider />
                                                        </Grid>
                                                        <Grid item {...columnsWidth}>
                                                            <MeetingCard
                                                                canEdit={canEdit}
                                                                showDate={false}
                                                                meeting={item}
                                                                openMeetingForm={this.openMeetingForm.bind(
                                                                    this,
                                                                    item._id,
                                                                )}
                                                                openMeetingNotes={this.openMeetingNotes.bind(
                                                                    this,
                                                                    item._id,
                                                                )}
                                                                linkToClient={
                                                                    this.props.linkToClient
                                                                }
                                                                deleteMeeting={this.deleteMeeting.bind(
                                                                    this,
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Fragment>
                                                );
                                            }

                                            return (
                                                <Grid item key={i} {...columnsWidth}>
                                                    <MeetingCard
                                                        canEdit={canEdit}
                                                        showDate={true}
                                                        meeting={item}
                                                        openMeetingForm={this.openMeetingForm.bind(
                                                            this,
                                                            item._id,
                                                        )}
                                                        openMeetingNotes={this.openMeetingNotes.bind(
                                                            this,
                                                            item._id,
                                                        )}
                                                        linkToClient={this.props.linkToClient}
                                                        deleteMeeting={this.deleteMeeting.bind(
                                                            this,
                                                        )}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                    {renderPastMeetings && (
                        <Grid
                            item
                            xs={12}
                            style={{
                                padding: '10px 0 20px 0',
                            }}>
                            <Divider />
                            <Box m={1}>
                                <Typography variant="h5">{getText('meeting-old')}</Typography>
                            </Box>
                            <Divider />
                        </Grid>
                    )}
                    {Object.keys(pastMeetings).map((meeting, i) => {
                        const items = pastMeetings[meeting];
                        const isExpanded = expandedMonths.includes(meeting);

                        return (
                            <Accordion
                                defaultExpanded={false}
                                expanded={isExpanded}
                                key={i}
                                onClick={() => {
                                    this.expandMonth(meeting);
                                }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography style={{ fontWeight: '500' }}>{meeting}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {isExpanded && (
                                        <Grid container>
                                            {items.map((item, i) => {
                                                return (
                                                    <Grid item key={i} {...columnsWidth}>
                                                        <MeetingCard
                                                            canEdit={canEdit}
                                                            showDate={true}
                                                            meeting={item}
                                                            openMeetingForm={this.openMeetingForm.bind(
                                                                this,
                                                                item._id,
                                                            )}
                                                            openMeetingNotes={this.openMeetingNotes.bind(
                                                                this,
                                                                item._id,
                                                            )}
                                                            linkToClient={this.props.linkToClient}
                                                            deleteMeeting={this.deleteMeeting.bind(
                                                                this,
                                                            )}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Box>
            </Box>
        );
    }

    expandMonth(label) {
        const { expandedMonths } = this.state;

        if (expandedMonths.includes(label)) {
            this.setState({
                expandedMonths: expandedMonths.filter((month) => month !== label),
            });
        } else {
            this.setState({
                expandedMonths: [...expandedMonths, label],
            });
        }
    }

    formatDate(loopDate) {
        const fnsLocales = {
            fr: fr,
            en: null,
        };

        try {
            let date = Date.parse(loopDate + ' EST');

            return (
                loopDate.replaceAll('-', '/') +
                ' - ' +
                upperFirst(format(date, 'EEEE', { locale: fnsLocales[getLang()] }))
            );
        } catch (err) {
            return loopDate.replaceAll('-', '/');
        }
    }

    componentDidMount() {
        const { daysLimit, clientId, renderPastMeetings } = this.props;

        const filters = {
            _limit: '0',
        };

        if (clientId) {
            filters['client'] = clientId;
        }

        if (daysLimit > 0) {
            const dateLimit = moment().add(daysLimit, 'day').format('YYYY-MM-DD');
            const today = moment().format('YYYY-MM-DD');

            filters['date_lte'] = dateLimit;
            filters['date_gte'] = today;
        }

        if (!renderPastMeetings) {
            filters['date_gte'] = moment().format('YYYY-MM-DD');
        }

        Promise.allSettled([
            Api.call(
                'get',
                `/client-meetings?${HttpHelper.toQueryString(filters)}&omit=client.name`,
            ),
            Api.call('get', '/equipment-types?_limit=0'),
        ]).then((results) => {
            const state = {
                isLoading: false,
                opened: {},
            };

            if (results[0].status === 'fulfilled') {
                state.meetings = results[0].value.data;
            }

            if (results[1].status === 'fulfilled') {
                state.types = results[1].value.data;
            }

            this.setState(state);
        });
    }

    // Forms
    openMeetingForm(id, e) {
        this.setState({
            meetingFormOpened: true,
            meetingFormData: {
                id: id,
                clientId: this.props.clientId,
            },
        });
    }

    closeMeetingForm(e) {
        this.setState({
            meetingFormOpened: false,
        });
    }

    openMeetingNotes(id) {
        this.setState({
            meetingNotesOpened: true,
            meetingNotesData: {
                id: id,
            },
        });
    }

    closeMeetingNotes() {
        this.setState({
            meetingNotesOpened: false,
        });
    }

    renderMeetingNotes() {
        const { meetingNotesOpened, meetingNotesData } = this.state;

        const { id } = meetingNotesData;

        if (!id) return null;

        const currMeeting = this.state.meetings.find((meeting) => meeting._id === id);
        const { client } = currMeeting;

        const notes = client.notes_history.filter((note) => {
            if (note.client_meeting) {
                return note.client_meeting.id === id;
            } else {
                return false;
            }
        });

        return (
            <Dialog
                onClose={this.closeMeetingNotes.bind(this)}
                disableEscapeKeyDown={true}
                maxWidth="md"
                fullWidth={true}
                open={meetingNotesOpened}
                disableEnforceFocus>
                <DialogTitle>{getText('meeting-notes')}</DialogTitle>
                <DialogContent>
                    {!notes
                        ? null
                        : notes.map((entry, i) => {
                              let hint = notes[0].user.first_name + ' ' + notes[0].user.last_name;
                              hint +=
                                  ' at ' +
                                  format(
                                      Date.parse(notes[0].modification_date),
                                      'yyyy-MM-dd HH:mm',
                                  );

                              return (
                                  <Fragment key={i}>
                                      <Box m={1}>
                                          <Typography variant="body2">{entry.notes}</Typography>
                                          <Typography variant="caption" style={{ opacity: 0.54 }}>
                                              {hint}
                                          </Typography>
                                      </Box>
                                      <Divider />
                                  </Fragment>
                              );
                          })}
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        color="primary"
                        onClick={this.closeMeetingNotes.bind(this)}>
                        {getText('action-close')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    // Contact
    updateMeeting(meeting) {
        this.setState(updateRelation(this.state, 'meetings', meeting));
    }

    deleteMeeting(id) {
        confirm({
            title: getText('meeting-delete_title'),
            text: getText('meeting-delete_message'),
            confirmLabel: getText('action-yes'),
            cancelLabel: getText('action-no'),
            onClose: (confirm) => {
                if (confirm) {
                    Api.call('delete', '/client-meetings/' + id).then((response) => {
                        this.setState(deleteRelation(this.state, 'meetings', id));
                    });
                }
            },
        });
    }
}

export default withRouter(withUser(MeetingList));

import moment from 'moment';
import 'moment/locale/fr';
import { getLang } from './LocaleHelper';

export function sortListItems(listItems, order = 'ASC') {
    listItems.sort((a, b) => {
        const aTime = a.time || '00:00:00';
        const bTime = b.time || '00:00:00';

        let aDate = Date.parse(`${a.date} ${aTime}`);
        let bDate = Date.parse(`${b.date} ${bTime}`);

        if (aDate < bDate) {
            return order === 'ASC' ? -1 : 1;
        }

        if (aDate > bDate) {
            return order === 'ASC' ? 1 : -1;
        }
        // a must be equal to b
        return 0;
    });
}

export function splitListItems(listItems) {
    const pastListItems = [];
    const futurListItems = [];
    const currentDate = new Date();

    listItems = listItems || [];

    // Split the listItems
    for (let i = 0; i < listItems.length; i++) {
        const listItem = listItems[i];
        let listItemDate = Date.parse(`${listItem.date} ${listItem.time}`);

        if (listItemDate < currentDate) {
            pastListItems.push(listItem);
        } else {
            futurListItems.push(listItem);
        }
    }

    // Sort listItems
    sortListItems(pastListItems, 'DESC');
    sortListItems(futurListItems, 'ASC');

    return {
        pastListItems: pastListItems,
        futurListItems: futurListItems,
    };
}

export const spiltByMonthListItems = (listItems = []) => {
    const futureItemsByMonth = {};
    const pastItemsByMonth = {};
    const lang = getLang();

    function formatKey(date) {
        let month = moment(date).locale(lang).format('MMMM YYYY');
        month = month.charAt(0).toUpperCase() + month.slice(1);
        return month;
    }

    // PAST

    // get past months list items
    const pastMonthsListItems = listItems.filter((listItem) => {
        const itemDate = moment(listItem.date);

        return itemDate.isBefore(moment(), 'month');
    });

    // group by month
    pastMonthsListItems.forEach((listItem) => {
        const itemDate = moment(listItem.date);
        const month = formatKey(itemDate);

        if (!pastItemsByMonth[month]) {
            pastItemsByMonth[month] = [];
        }

        pastItemsByMonth[month].push(listItem);
        sortListItems(pastItemsByMonth[month], 'ASC');
    });

    // split future months list items
    const futureMonthsListItems = listItems.filter((listItem) => {
        const itemDate = moment(listItem.date);
        // is after or current month
        return itemDate.isSameOrAfter(moment(), 'month');
    });

    // FUTURE

    // Add by default a year of the current and futur months
    for (let i = 0; i < 12; i++) {
        const month = formatKey(moment().add(i, 'month'));
        futureItemsByMonth[month] = [];
    }

    // group by month
    futureMonthsListItems.forEach((listItem) => {
        const itemDate = moment(listItem.date);
        const month = formatKey(itemDate);

        if (!futureItemsByMonth[month]) {
            futureItemsByMonth[month] = [];
        }
        futureItemsByMonth[month].push(listItem);
        sortListItems(futureItemsByMonth[month], 'ASC');
    });

    // sort the keys of the object key = ex: 'January 2021'
    const sortedPastItemsByMonth = Object.keys(pastItemsByMonth).sort((a, b) =>
        moment(b, 'MMMM YYYY', lang).diff(moment(a, 'MMMM YYYY', lang)),
    );

    const sortedFutureItemsByMonth = Object.keys(futureItemsByMonth).sort((a, b) => {
        return moment(a, 'MMMM YYYY', lang).diff(moment(b, 'MMMM YYYY', lang));
    });
    
    return {
        past: sortedPastItemsByMonth.reduce((obj, key) => {
            obj[key] = pastItemsByMonth[key];
            return obj;
        }, {}),
        future: sortedFutureItemsByMonth.reduce((obj, key) => {
            obj[key] = futureItemsByMonth[key];
            return obj;
        }, {}),
    };
};

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import React, {Component} from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchIcon from '@material-ui/icons/Search';
import { withRouter } from 'react-router';
import { getText } from '../../utils/LocaleHelper';

class ListFilter extends Component {
	static defaultProps = {
		fields: [],
		defaultValues: null,
		filterName: null,
		saveSearch: true,
		onFilterUpdate: (filters) => {},
		iconElement: <SearchIcon />,
    }

	constructor(props) {
		super(props);

		this.filterTimer = null;

		this.state = {
			filters: this.getDefaultValues() || this.getEmptyValues(),
		}
	}

	getFilterName() {
		const { location, filterName, uniqueSearchType } = this.props;
		const nameParts = ['search-' + (filterName || location.pathname)];

		if(uniqueSearchType) {
			nameParts.push(uniqueSearchType);
		}

  		return nameParts.join('-');
	}
	
	getDefaultValues() {
		const { defaultValues, saveSearch } = this.props;

		if(saveSearch) {
			const filterName = this.getFilterName();
			const localStorageSearch = localStorage.getItem(filterName);

			if(localStorageSearch) {
				return {
					...this.getEmptyValues(),
					...JSON.parse(localStorageSearch)
				};
			}
		}

		return defaultValues;
	}

	getEmptyValues() {
		const { fields } = this.props;
		const emptyValues = {
			search: '',
		};

		for(let i = 0; i < fields.length; i++) {
			const field = fields[i];
			emptyValues[field.filter] = field.defaultValue;
		}
		return emptyValues;
	}

	render() {
		const { fields, iconElement } = this.props;
		const { filters } = this.state;

 		return (
			<Box m={1} mb={2}>
				<Grid container alignItems="center">
					<Grid item>
						<Grid container spacing={1} alignItems="flex-end">
							<Grid item>				 
							{ iconElement }
							</Grid>
							<Grid item>
								<TextField
									placeholder={getText('action-search') + '...'}								
									value={filters.search}
									onChange={this.onFieldChange.bind(this, 'search')}
								/>
							</Grid>
						</Grid>
					</Grid>
					{
						fields.map((field, i) => {
							const { filter, element } = field;

							return (
								<Grid item key={i} style={{marginLeft: 10}}>
									{React.cloneElement(
										element,
										{
											value:filters[filter],
											onChange: this.onFieldChange.bind(this, filter),
										}
									)}
								</Grid>
							);
						})
					}
					<Grid item>
						<IconButton onClick={this.clearFilters.bind(this)}>
							<HighlightOffIcon />
						</IconButton>
					</Grid>
				</Grid>
            </Box>
		);
	}

	onFieldChange(attribute, e) {
		const field = e.target;
  		const value = field.value;
		
		const fieldFilters = {};

		fieldFilters[attribute] = value;

		this.updateFilters(fieldFilters);
	}
	
	clearFilters() {
		//Prevent deleting multiple search inputs in the same page
		if(this.props.filtersProps === '') return;

		this.updateFilters(this.getEmptyValues());
	}
	
	updateFilters(filters) {
		const { saveSearch, uniqueSearchType } = this.props;

		const newFilters = {
			...this.getEmptyValues(),
			...this.state.filters,
			...filters,
		}

		this.setState({
			filters: newFilters,
		});

		if(saveSearch) {
			const filterName = this.getFilterName();
			localStorage.setItem(filterName, JSON.stringify(newFilters));
		}

		// Add a delay before calling the "onFilterUpdate" in order to avoid useless requests
		this.clearFilterTimer();

		this.filterTimer = setTimeout(() => {
			const emptyValue = JSON.stringify(this.state.filters) === JSON.stringify(this.getEmptyValues());

			this.props.onFilterUpdate(this.state.filters, uniqueSearchType, emptyValue);
		}, 500);
	}

	clearFilterTimer() {
		if(this.filterTimer) {
			clearTimeout(this.filterTimer);
			this.filterTimer = null;
		}
	}

	componentWillUnmount() {
		this.clearFilterTimer();
	}

	componentDidMount() { 
		this.updateFilters({});
	}

	componentDidUpdate(prevProps) {
		const {uniqueSearchType, filtersProps, saveSearch} = this.props;
		//Meeting only
	 	if (filtersProps !== prevProps.filtersProps) {
			if (filtersProps === '') {
				this.setState({
					filters: {search: '', type:''}
				})

				if(saveSearch) {
					const filterName = this.getFilterName();
					localStorage.setItem(filterName, JSON.stringify(this.getEmptyValues()));
				}
			}
		}
	}
}
	
export default withRouter(ListFilter);

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';

import { withStyles } from '@material-ui/styles';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DashboardIcon from '@material-ui/icons/Dashboard';
import MapIcon from '@material-ui/icons/Map';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HelpIcon from '@material-ui/icons/Help';
import LockIcon from '@material-ui/icons/Lock';
import Button from '@material-ui/core/Button';
import Api from '../../api/Api';
import logo from '../../images/1200_Logo_Noir.svg';
import './MainLayout.scss';
import { withMediaQuery } from '../../utils/ReactWrappers';
import DesktopTitleBar from './DesktopTitleBar';

import { getText } from '../../utils/LocaleHelper';
import { getUser } from '../../utils/UserHelper';

const styles = (theme) => ({
    menuContent: {
        width: 280,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    menuContentDesktop: {
        //backgroundColor: '#e6e6e6',
        flexShrink: 0,
        flexGrow: 0,
    },
});

class MainLayout extends Component {
    static defaultProps = {
        title: '',
        bottomNavBar: null,
        onClose: null,
        showTitleBar: true,
    };

    constructor(props) {
        super(props);

        this.onImpersonateMode = localStorage.getItem('session_impersonate');
        this.state = {
            menuOpened: false,
            adminMenuOpened: false,
        };
    }

    render() {
        const classNames = ['main-layout'];
        const { isDesktop, showTitleBar } = this.props;

        if (isDesktop) {
            classNames.push('desktop');
        }

        const desktopMenuStyle = {
            zIndex: 99,
        };

        return (
            <div className={classNames.join(' ')}>
                <CssBaseline />
                {isDesktop ? null : (
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={this.toggleMenu.bind(this, true)}>
                                <MenuIcon />
                            </IconButton>
                            <Typography noWrap variant="h6" style={{ flexGrow: 1 }}>
                                {this.props.title}
                            </Typography>
                            {this.props.onClose ? (
                                <CloseIcon color="inherit" onClick={this.props.onClose}>
                                    Login
                                </CloseIcon>
                            ) : null}
                        </Toolbar>
                    </AppBar>
                )}
                {isDesktop ? (
                    <Paper elevation={3} style={desktopMenuStyle}>
                        {this.renderMenu()}
                    </Paper>
                ) : (
                    <Drawer
                        anchor="left"
                        open={this.state.menuOpened}
                        onClose={this.toggleMenu.bind(this, false)}>
                        {this.renderMenu()}
                    </Drawer>
                )}
                <div className="container">
                    {isDesktop && showTitleBar ? (
                        <DesktopTitleBar title={this.props.title} />
                    ) : null}
                    {this.props.children}
                </div>
                {this.props.bottomNavBar}
            </div>
        );
    }

    toggleMenu(open) {
        this.setState({
            menuOpened: open,
        });
    }

    renderMenu() {
        const { classes } = this.props;
        const { isDesktop } = this.props;

        const logoStyle = {
            width: '70px',
        };

        const user = getUser();

        const menuItems = [
            {
                icon: <DashboardIcon />,
                label: getText('menu-dashboard'),
                path: '/dashboard',
                visible: true,
            },
            {
                icon: <MapIcon />,
                label: getText('menu-map'),
                path: '/map',
                visible: true,
            },
            {
                icon: <StoreMallDirectoryIcon />,
                label: getText('menu-clients'),
                path: '/clients',
                visible: true,
            },
            {
                icon: <WatchLaterIcon />,
                label: getText('menu-meetings'),
                path: '/meetings',
                visible: true,
            },
            {
                icon: <DescriptionIcon />,
                label: getText('menu-projects'),
                path: '/projects',
                visible: true,
            },
            {
                icon: <NotificationsIcon />,
                label: getText('menu-reminders'),
                path: '/reminders',
                visible: true,
            },
            {
                icon: <AssignmentIndIcon />,
                label: getText('menu-profile'),
                path: '/my-profile',
                visible: true,
            },
            {
                icon: <LockIcon />,
                label: 'Admin',
                path: '/admin',
                visible: user.role.type === 'admin',
            },
            {
                icon: <HelpIcon />,
                label: 'Help',
                path: `https://form.asana.com/?k=dRgMwjTx-MlsKz8tLxBdnQ&d=983836322904792`,
                visible: true,
                external: true,
            },
        ];

        const classNames = [classes.menuContent];

        if (isDesktop) {
            classNames.push(classes.menuContentDesktop);
        }

        return (
            <Box className={classNames.join(' ')}>
                <Box>
                    <Box m={2}>
                        <img src={logo} alt="Logo" style={logoStyle} />
                    </Box>
                    <List>
                        {menuItems.map((menuItem, index) =>
                            typeof menuItem.visible === 'undefined' || menuItem.visible ? (
                                <ListItem
                                    key={menuItem.label}
                                    button
                                    onClick={this.onMenuItemClick.bind(this, menuItem)}
                                    style={{ padding: '9px 18px' }}>
                                    <ListItemIcon>{menuItem.icon}</ListItemIcon>
                                    <ListItemText primary={menuItem.label} />
                                </ListItem>
                            ) : null,
                        )}
                    </List>
                </Box>
                <Box>
                    <List>
                        {this.onImpersonateMode && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    localStorage.removeItem('session_impersonate');
                                    window.location.replace('/dashboard');
                                }}
                                style={{ margin: '10px 10px' }}
                                >
                                {getText('menu-return-account')}
                            </Button>
                        )}

                        <ListItem
                            button
                            key="Déconnexion"
                            onClick={this.onLogoutClick.bind(this)}
                            style={{ padding: '9px 18px' }}>
                            <ListItemText primary={getText('menu-logout')} />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        );
    }

    onMenuItemClick(menuItem, e) {
        const { history } = this.props;

        if (menuItem.external) {
            window.open(menuItem.path, '_blank');
        } else {
            history.push(menuItem.path);
        }
    }

    toggleAdminMenu(e) {
        this.setState({
            adminMenuOpened: !this.state.adminMenuOpened,
        });
    }

    onLogoutClick(e) {
        Api.logout();
    }
}

export default withRouter(
    withStyles(styles, { index: 1 })(
        withMediaQuery([
            [
                'isDesktop',
                (theme) => theme.breakpoints.up('lg'),
                {
                    defaultMatches: true,
                },
            ],
        ])(MainLayout),
    ),
);

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Grid from '@material-ui/core/Grid'
import React, {Component, Fragment} from 'react';
import { withRouter } from 'react-router';
import { PDFViewer } from '@react-pdf/renderer';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import Api from '../../api/Api';
import SearchResults from '../layout/SearchResults';
import ListFilter from './ListFilter';
import { filterList } from '../../utils/ListHelper';
import { getText } from '../../utils/LocaleHelper';
import { formatMoney } from '../../utils/StringHelper';
import EmptyList from '../layout/EmptyList';
import Invoice from '../pdf/Invoice';
import Loader from '../widgets/Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class InvoiceList extends Component {
	constructor(props) {
		super(props);

		this.state = {
            isLoading: true,
            invoices: null,
            reps: null,
            filters: null,
            invoicePopupOpened: false,
            invoicePopup: null,
            invoicePopupType: null,
		}
	}

	render() {
        const {isLoading} = this.state;

		if(isLoading) {
            return <Loader />;
        }

        const types = ['quote', 'order', 'invoice'];

        return (
			<Box>
                <ListFilter
                    saveSearch={false}
                    onFilterUpdate={(filters) => {this.setState({ filters: filters })}}
                    fields={[
                        {
                            filter: 'type',
                            defaultValue: '',
                            element: (
                                <Select
                                    labelId="type"
                                    size="small"
                                    label="test"
                                    displayEmpty={true}
                                >
                                    <MenuItem value="">Type...</MenuItem>
                                    {types.map((type, i) => <MenuItem value={type} key={i}>{getText('invoice-type_'+type)}</MenuItem>)}
                                </Select>
                            )
                        }
                    ]}
                />
                {this.renderInvoices()}
                {this.renderInvoicePopup()}
            </Box>
		);
	}

    renderInvoices() {
        const { invoices, filters } = this.state;

        let filteredInvoices = invoices;

        // Filter projects
        if(filters) {
            filteredInvoices = filterList(invoices, filters, [
                'client',
                'date',
                'invoice_number',
            ]);
    
            filteredInvoices = filteredInvoices.filter(invoice => {
                if(!filters.type) {
                    return true;
                }
    
                return invoice.type === filters.type;
            });
        }

        let loopDate = null;

        return (
            <Box>
                <Box m={1}>
                    <SearchResults
                        value={filteredInvoices.length}
                        labelSingle={getText('invoice-result_singular')}
                        labelPlural={getText('invoice-result_plural')}
                    />
                </Box>
                <Grid container>
                    {
                        filteredInvoices.length > 0 ? null :
                        <EmptyList message={getText('invoice-empty_list')} />
                    }
                    {filteredInvoices.map((invoice, i) => {
                        let invoiceDate = invoice.date.substring(0, 7);
                        let addSection = false;

                        if(loopDate !== invoiceDate) {
                            loopDate = invoiceDate;
                            addSection = true;
                        }

                        return (
                            <Fragment>
                                {
                                    !addSection ? null :
                                    <Fragment>
                                        <Grid item sm={12}>
                                            <Box m={1} mt={2}>
                                                <Typography variant="subtitle1" component="h2">
                                                    {loopDate}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <Divider />
                                        </Grid>
                                    </Fragment>
                                }
                                <Grid item key={invoice.invoice_number} xs={12} sm={12} md={6} lg={4}>
                                    <Box m={1}>
                                        <Card>
                                            <CardContent>
                                                <Grid container justifyContent="space-between" alignItems="center">
                                                    <Grid item>
                                                        <Typography variant="h6" component="h2">
                                                            {invoice.invoice_number}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>
                                                            {formatMoney(invoice.amount)} $
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container justifyContent="space-between" alignItems="center">
                                                    <Grid item>
                                                        <Typography color="textSecondary">
                                                            {invoice.client}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography color="secondary">
                                                            {getText('invoice-type_'+invoice.type)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            <Divider />
                                            <CardActions>
                                                <Button size="small" color="primary" onClick={this.onViewInvoiceClick.bind(this, invoice)}>
                                                    {getText('action-view')}
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Box>
                                </Grid>
                            </Fragment>
                        );
                    })}
                </Grid>
            </Box>
        );
    }

    renderInvoicePopup() {
        const { invoicePopupOpened, invoicePopup } = this.state;

        return (
            
            <Dialog
                fullScreen={true}
                open={invoicePopupOpened}
                onClose={this.onInvoiceClose.bind(this)}
                disableEnforceFocus
                TransitionComponent={Transition}
            >
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="close" onClick={this.onInvoiceClose.bind(this)}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">
                            {invoicePopup ? invoicePopup.transaction.no_trans : null}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {this.renderInvoice()}
            </Dialog>
        );
    }

    renderInvoice() {
        const { invoicePopupOpened, invoicePopup, invoicePopupType, reps } = this.state;

        if(!invoicePopupOpened) {
            return null;
        }

        return (
            <PDFViewer style={{width: '100%', height: '100%'}}>
				<Invoice {...invoicePopup} type={invoicePopupType} reps={reps} />
			</PDFViewer>
        );
    }

    onInvoiceClose() {
        this.setState({
            invoicePopupOpened: false,
        });
    }

    onViewInvoiceClick(invoice, e) {
        Api.call('get', `/clients/${this.props.clientId}/invoice/${invoice.type}/${invoice.invoice_number}`)
            .then(response => {
                this.setState({
                    invoicePopupOpened: true,
                    invoicePopup: response.data,
                    invoicePopupType: invoice.type,
                });
            });
    }

    componentDidMount() {        
		Promise.allSettled([
			//Api.call('get', '/regions2'),
			Api.call('get', `/clients/${this.props.clientId}/invoices?_limit=0`),
			Api.call('get', `/fidelio-reps`),
		])
		.then((results) => {
			const state = {
				isLoading: false,
			}

			if(results[0].status === 'fulfilled') {
				state.invoices = results[0].value.data;
			}

			if(results[1].status === 'fulfilled') {
				state.reps = results[1].value.data;
			}

			this.setState(state);
		});
	}
}

export default withRouter(InvoiceList);

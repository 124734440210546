import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import { getText } from '../../utils/LocaleHelper';

class ReminderCard extends Component {
	static defaultProps = {
        reminder: null,
		showDate: false,
		linkToClient: false,
		canEdit: false,
    }

	constructor(props) {
		super(props);

		this.state = {
			
		}
	}

	render() {
		const { reminder, canEdit, linkToClient } = this.props;

		return (
			<Box m={1}>
				<Card>
					<CardContent>
						<Box>
							<Grid container justifyContent="space-between" alignItems="center">
								<Grid item>
									<Typography variant="h6" component="h3">
										{reminder.description}
									</Typography>
								</Grid>
								<Grid item>
									<Typography color="secondary">
										{this.props.showDate ? reminder.date : null}
									</Typography>
								</Grid>
							</Grid>
							<Grid container justifyContent="space-between" alignItems="center">
								<Grid item>
									<Typography color="textSecondary">
										{reminder.client.name}
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					{
						!linkToClient && !canEdit ? null :
						<Fragment>
							<Divider />
							<CardActions>
								{
									!canEdit ? null :
									<Button size="small" color="primary" onClick={this.props.openReminderForm}>
										{getText('action-edit')}
									</Button>
								}
								{
									!linkToClient ? null :
									<Button size="small" color="primary" onClick={this.onClientClick.bind(this)}>
										{getText('client-view_client')}
									</Button>
								}
								{
									!canEdit ? null :
									<Button size="small" color="secondary" onClick={this.deleteReminder.bind(this, reminder)}>
										{getText('action-delete')}
									</Button>
								}
							</CardActions>
						</Fragment>
					}
				</Card>
			</Box>
		);
	}

	onClientClick() {
		const { history, reminder } = this.props;

		history.push('/client/' + reminder.client.id);
	}

	deleteReminder(reminder) {
        this.props.deleteReminder(reminder._id);
	}
}

export default withRouter(ReminderCard);
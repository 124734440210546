import React, { Component, Fragment } from 'react';
import * as _ from 'lodash';

class ClientScoreToken extends Component {
    static defaultProps = {
        client: null,
        size: 'small', // 'small', 'medium', 'large'
    }

	constructor(props) {
		super(props);

		this.state = {

		}
	}

	render() {
        const { client, size } = this.props;
        const icon = _.get(client, 'score.color', 'red');

        const imgStyle = {
            display: 'inline-block',
            height: '1em',
            marginLeft: '0.4em',
        };

        return (
			<Fragment>
                <img style={imgStyle} src={`/images/coin_${icon}_40.png`} />
			</Fragment>
		);
	}
}

export default ClientScoreToken;

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import React, {Component} from 'react';

class DesktopTitleBar extends Component {
    static defaultProps = {
        title: null,
        actions: null,
    }

	constructor(props) {
		super(props);

		this.state = {
			
		}
	}

	render() {
        const { actions } = this.props;

		return (
            <AppBar position="static">
                <Toolbar>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography noWrap variant="h6" style={{flexGrow: 1}}>
                                {this.props.title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {actions}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
		);
	}
}

export default DesktopTitleBar;

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import React, {Component} from 'react';

class EmptyList extends Component {
    static defaultProps = {
        message: null,
    }

	constructor(props) {
		super(props);

		this.state = {
			
		}
	}

	render() {
        const { message } = this.props;

		return (
            <Box m={1} style={{width: '100%', textAlign: 'center'}}>
                <Card variant="outlined" style={{backgroundColor: 'transparent'}}>
                    <CardContent>
                        <Typography variant="body2" component="p">{message}</Typography>
                    </CardContent>
                </Card>
            </Box>
		);
	}
}

export default EmptyList;

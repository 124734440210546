import React, {Component, Fragment} from 'react';
import { withRouter } from 'react-router';
import Api from '../../api/Api';
import { setUser } from '../../utils/UserHelper';
import Loader from '../widgets/Loader';

class Page extends Component {
	constructor(props) {
		super(props);

		this.state = {
            isLoading: true,
            user: null,
		}
	}

	render() {
        const { isLoading } = this.state;

        if(isLoading) {
            return <Loader />;
        }

		return (
			<Fragment>
                {this.props.children}
			</Fragment>
		);
	}

    componentDidMount() {
        const {history} = this.props;
        
        history.listen((location, action) => {
            /*this.setState({
                isLoading: true,
            });*/

            this.loadUser();
        });

        this.loadUser();
    }

    loadUser() {
        Api.call('get', '/users/me')
            .then(response => {
                setUser(response.data);
                
                this.setState({
                    user: response.data,
                    isLoading: false,
                });
            })
            .catch(error => {
                Api.logout(!this.isPublic());

                this.setState({
                    isLoading: false,
                });
            });
    }

    isPublic() {
        const {location} = this.props;

        return location.pathname === '/';
    }
}

export default withRouter(Page);

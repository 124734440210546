import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, {Component} from 'react';

class SearchResults extends Component {
    static defaultProps = {
        onButtonClick: null,
        buttonLabel: null,
        labelSingle: 'résultat',
        labelPlural: 'résultats',
        value: 0,
    }

	constructor(props) {
		super(props);

		this.state = {
			menuOpened: false,
		}
	}

	render() {
        const {value, labelSingle, labelPlural} = this.props;

		return (
			<Grid container spacing={1} justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                        {value} {value > 1 ? labelPlural : labelSingle}
                    </Typography> 
                </Grid>
                <Grid item>
                    {this.renderButton()}
                </Grid>
            </Grid>
		);
	}

    renderButton() {
        const {onButtonClick, buttonLabel} = this.props;

        if(buttonLabel === null) {
            return null;
        }

        return (
            <Button size="small" color="primary" onClick={onButtonClick}>
                {buttonLabel}
            </Button>
        );
    }
}

export default SearchResults;

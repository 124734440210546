export function sortProjects(projects, order = 'ASC') {
    projects.sort((a, b) => {
        if (a.date < b.date) {
            return order === 'ASC' ? -1 : 1;
        }

        if (a.date > b.date) {
            return order === 'ASC' ? 1 : -1;
        }

        // If equal, sort by name
        if (a.name < b.name) {
            return order === 'ASC' ? -1 : 1;
        }

        if (a.name > b.name) {
            return order === 'ASC' ? 1 : -1;
        }

        // a must be equal to b
        return 0;
    });
}

export function splitProjects(projects) {
    const filteredProjects = {
        in_progress: [],
        future: [],
        past: [],
        won: [],
        lost: []
    };

    // Split the projects
    for(let i = 0; i < projects.length; i++) {
        const project = projects[i];
        filteredProjects[project.status].push(project);
    }

    // Sort projects
    sortProjects(filteredProjects.past, 'DESC');
    sortProjects(filteredProjects.in_progress, 'ASC');
    sortProjects(filteredProjects.future, 'ASC');
    sortProjects(filteredProjects.won, 'ASC');
    sortProjects(filteredProjects.lost, 'ASC');

    return filteredProjects
}
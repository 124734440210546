import React, { Component } from 'react';
import { withRouter } from 'react-router';
import AdminLayout from '../../../layout/AdminLayout';
import { withUser } from '../../../../utils/ReactWrappers';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Api from '../../../../api/Api';
import Loader from '../../../widgets/Loader';
import './RepsPerformance.css';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { formatDate } from '../../../../utils/DateHelper';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
    },
});

class RepsPerformance extends Component {
    constructor(props) {
        super(props);

        this.lastMonthDate = formatDate(new Date(new Date().setMonth(new Date().getMonth() - 1)));
        this.lastWeekDate = formatDate(new Date(new Date().setDate(new Date().getDate() - 7)));

        this.state = {
            isLoading: true,
            reps: [],
            history: [],
            // default to last month
            compareDate: this.lastMonthDate,
            comparisonType: 'lastMonth',
        };
    }

    handleCompareDateChange = (date) => {
        this.setState({
            compareDate: formatDate(date),
        });
    };

    handleComparisonTypeChange = (event) => {
        const { value } = event.target;
        this.setState({
            comparisonType: value,
            ...(value === 'custom' && { compareDate: formatDate(new Date()) }),
        });
    };

    getBreadcrumbs() {
        return [
            {
                label: 'Reports',
                url: '/reports',
            },
            {
                label: 'Reps Performance',
                url: '/reports/reps-performance',
            },
        ];
    }

    renderCustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    componentDidMount() {
        Promise.allSettled([
            Api.call('get', '/reps/score'),
            Api.call('get', `/performance-histories?name=sales_representative`),
        ]).then((results) => {
            if (results[0].status === 'fulfilled') {
                this.setState({
                    reps: results[0].value.data,
                    isLoading: false,
                });
            }

            if (results[1].status === 'fulfilled') {
                this.setState({
                    history: results[1].value.data,
                });
            }
        });
    }
    componentDidUpdate(prevProps, prevState) {
        // set compare date when its last week or last month changes
        if (prevState.comparisonType !== this.state.comparisonType) {
            if (this.state.comparisonType === 'lastWeek') {
                this.setState({
                    compareDate: this.lastWeekDate,
                });
            } else if (this.state.comparisonType === 'lastMonth') {
                this.setState({
                    compareDate: this.lastMonthDate,
                });
            }
        }
    }

    renderPageControls() {
        const { comparisonType, compareDate } = this.state;

        return (
            <div >
                <Grid container spacing={3} style={{ padding: 20 }}>
                    <Grid container item xs={12} sm={3}>
                        <Typography style={{
                            marginRight: '10px'
                        }} variant="subtitle1">Select Comparison Date:</Typography>
                        <Select value={comparisonType} onChange={this.handleComparisonTypeChange}>
                            <MenuItem value="lastMonth">Last Month</MenuItem>
                            <MenuItem value="lastWeek">Last Week</MenuItem>
                            <MenuItem value="custom">Choose date</MenuItem>
                        </Select>
                    </Grid>
                    {comparisonType === 'custom' && (
                        <Grid container item xs={12} sm={3}>
                            <Typography style={{
                            marginRight: '10px'
                        }} variant="subtitle1">Date:</Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    disableFuture
                                    value={new Date(compareDate + 'T00:00:00')}
                                    onChange={this.handleCompareDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }

    renderProgress(value, status, percentage) {
        // Don't show progress if there is no progress

        // get only 2 decimal places
        const valueNum = parseFloat(value.toFixed(2));

        if (status === 'equal') return null;
        return (
            <div className={`progress_container`}>
                <span>{`${valueNum} ${percentage ? '%' : ''}`}</span>
                {status === 'positive' ? (
                    <ArrowDropUpIcon
                        style={{
                            color: '#39de39',
                        }}
                    />
                ) : (
                    <ArrowDropDownIcon color="error" />
                )}
            </div>
        );
    }

    renderCellWithHistory(params, percentage) {
        const { value, field, id } = params;
        const { history } = this.state;

        const currHistory = history.find((item) => item.date === this.state.compareDate);

        let historyValue = value;
        let valueDiff = 0;
        let valueDiffStatus = 'equal';

        if (currHistory) {
            const { fields } = currHistory;
            // get the history field that matches the current field
            const historyField = fields.find((f) => f.id === id);

            if (historyField) {
                // Make sure the field exists in the history field
                if (historyField[field] !== undefined && historyField[field] !== null) {
                    historyValue = historyField[field];
                }
            }

            valueDiff = value - historyValue;
            // is positive , negative or equal
            valueDiffStatus = valueDiff > 0 ? 'positive' : valueDiff < 0 ? 'negative' : 'equal';
        }

        return (
            <div className="cell_container">
                <p>{`${value} ${percentage ? '%' : ''}`}</p>
                {currHistory && this.renderProgress(valueDiff, valueDiffStatus, percentage)}
            </div>
        );
    }

    renderContent() {
        const { isLoading, reps } = this.state;

        if (isLoading) {
            return <Loader />;
        }

        const formattedReps = reps.map((rep) => {
            let projectWinLost = rep?.percentage_project_win_lost;

            if (projectWinLost === null || projectWinLost === undefined) {
                projectWinLost = null;
            } else {
                projectWinLost = projectWinLost;
            }

            return {
                id: rep.id,
                user: rep.user,
                total: rep.total,
                total_technical_equipment: rep.total_technical_equipment,
                percentage_filled_equipment: rep.percentage_filled_equipment,
                percentage_visit_hit: rep.percentage_visit_hit,
                percentage_project_win_lost: projectWinLost,
            };
        });

        const columns = [
            { field: 'id', headerName: 'ID', width: 70 },
            { field: 'user', headerName: 'Name', width: 200 },
            {
                field: 'total',
                headerName: 'Average Score',
                width: 150,
                renderCell: (params) => this.renderCellWithHistory(params),
            },
            {
                field: 'total_technical_equipment',
                headerName: 'Technical Equipment',
                width: 150,
                renderCell: (params) => this.renderCellWithHistory(params),
            },
            {
                field: 'percentage_filled_equipment',
                headerName: 'Filled Equipment',
                width: 150,
                renderCell: (params) => this.renderCellWithHistory(params, true),
            },
            {
                field: 'percentage_visit_hit',
                headerName: 'Visit Hit',
                width: 150,
                renderCell: (params) => this.renderCellWithHistory(params, true),
            },
            {
                field: 'percentage_project_win_lost',
                headerName: 'Project Win/lost',
                width: 150,
                renderCell: (params) => this.renderCellWithHistory(params, true),
            },
        ];

        return (
            <div style={{ height: '600px', width: '100%', paddingBottom: '100px' }}>
                {this.renderPageControls()}
                <DataGrid
                    rows={formattedReps}
                    columns={columns}
                    components={{
                        Toolbar: this.renderCustomToolbar,
                    }}
                />
            </div>
        );
    }

    render() {
        return (
            <AdminLayout breadcrumbs={this.getBreadcrumbs()} title="Reps Performance">
                {this.renderContent()}
            </AdminLayout>
        );
    }
}

export default withRouter(withUser(withStyles(styles)(RepsPerformance)));

import {Component} from 'react';
import { withRouter } from "react-router";

import Api from '../../api/Api';

import { withUser } from '../../utils/ReactWrappers';

class Logout extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return null;
	}

	componentDidMount() {
		Api.logout();
	}
}

export default withRouter(withUser(Logout));

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid'
import React, {Component} from 'react';
import { withRouter } from 'react-router';

import Api from '../../api/Api';
import { getText } from '../../utils/LocaleHelper';
import { withUser } from '../../utils/ReactWrappers';
import * as UIHelper from '../../utils/UIHelper';
import MainLayout from '../layout/MainLayout';

class MyProfile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			password: '',
			repeatPassword: '',
			error: null,
		}
	}

	render() {
		const { user } = this.props;
		const { password, repeatPassword, error } = this.state;

		return (
			<MainLayout title={getText('menu-profile')}>
				<Grid container justifyContent="center">
					<Grid item style={{width: '100%', maxWidth: 800}}>
						<Box m={1}>
							<Card>
								<CardContent>
									<Box mb={2}>
										<Typography variant="h6" component="h2" mb={3}>
											Informations
										</Typography>
									</Box>
									<Grid container>
										<Grid item xs={3}>
											<Box fontWeight="fontWeightBold" mb={1}>
												{getText('profile-name')}
											</Box>
										</Grid>
										<Grid item xs={9}>
											{user.first_name} {user.last_name}
										</Grid>
										<Grid item xs={3}>
											<Box fontWeight="fontWeightBold" mb={1}>
												Language
											</Box>
										</Grid>
										<Grid item xs={9}>
											{user.language === 'fr' ? 'Français' : 'English'}
										</Grid>
										<Grid item xs={3}>
											<Box fontWeight="fontWeightBold" mb={1}>
												{getText('profile-role')}
											</Box>
										</Grid>
										<Grid item xs={9}>
											{user.role.name}
										</Grid>
										<Grid item xs={3}>
											<Box fontWeight="fontWeightBold" mb={1}>
												{getText('profile-email')}
											</Box>
										</Grid>
										<Grid item xs={9}>
											{user.email}
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Box>
						<Box m={1}>
							<Card>
								<CardContent>
									<Box mb={2}>
										<Typography variant="h6" component="h2" mb={3}>
											{getText('profile-change_password_title')}
										</Typography>
									</Box>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextField
												error={error !== null}
												variant="filled"
												type="password"
												label={getText('profile-new_password')}
												fullWidth={true}
												size="small"
												value={password}
												onChange={this.onFieldChange.bind(this, 'password')}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												error={error !== null}
												helperText={error}
												variant="filled"
												type="password"
												label={getText('profile-repeat_password')}
												fullWidth={true}
												size="small"
												value={repeatPassword}
												onChange={this.onFieldChange.bind(this, 'repeatPassword')}
											/>
										</Grid>
									</Grid>
								</CardContent>
								<Divider />
								<CardActions>
									<Button size="small" color="primary" onClick={this.onFormSubmit.bind(this)}>
										{getText('action-change')}
									</Button>
								</CardActions>
							</Card>
						</Box>
					</Grid>
				</Grid>
			</MainLayout>
		);
	}

	onFormSubmit() {
		const { password, repeatPassword } = this.state;

		const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&.])[A-Za-z\d$@$!%*?&.]{6,}/;

		if(!passwordReg.test(password)) {
			this.setState({
				error: getText('profile-conditions'),
			});
		} else if(password !== repeatPassword) {
			this.setState({
				error: getText('profile-identical'),
			});
		} else {			
			Api.call('put', '/me/change-password', { password : password })
				.then((response) => {
					UIHelper.alert('success', getText('profile-confirm'));

					this.setState({
						error: null,
					});
				})
				.catch((error) => {
					UIHelper.alert('error', error.message);

					this.setState({
						error: null,
					});
				});
		}
	}

	onFieldChange(attribute, e) {
		const field = e.target;
        const value = field.value;

		this.setState({
			[attribute]: value,
		});
	}
}

export default withRouter(withUser(MyProfile));

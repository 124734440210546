import React, {Component, Fragment} from 'react';

import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Api from '../../api/Api';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import { confirm } from '../../utils/UIHelper';
import { getText } from '../../utils/LocaleHelper';
import Loader from '../widgets/Loader';

class EquipmentForm extends Component {
    static defaultProps = {
        id: null,
        typeId: null,
        quantityOptions: null,
        clientId: null,
        closeForm: null,
        brands: null,
        distributors: null,
        updateEquipment: null,
    }

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
            hasChanged: false,
			equipment: null,
            opened: true,
            formErrors: null,
            formData: {
                client: props.clientId,
                equipment_type: props.typeId,
                equipment_brand: '',
                equipment_distributor: '',
                acquisition_year: '',
                renewal_year: '',
                estimated_quantity: '',
                notes: '',
            }
		}
	}

	render() {
		return (
            <Dialog
                onClose={this.onDialogClose}
                disableEscapeKeyDown={true}
                maxWidth='sm'
                fullWidth={true}
                open={this.state.opened}
                disableEnforceFocus
                TransitionProps={{
                    onExited: this.props.closeForm
                }}
            >
                <DialogTitle>{getText('menu-equipments')}</DialogTitle>
                {this.renderContent()}
            </Dialog>
		);
	}

    onDialogClose(e, reason) {
        if(reason === 'backdropClick') {
            return;
        }
    }

    renderContent() {
        const {brands, distributors, quantityOptions} = this.props;
        const {isLoading, formData} = this.state;

        if(isLoading) {
            return <Loader />;
        }

        const years = [];
        for (var i = 2000; i <= (new Date()).getFullYear() + 10; i++) {
            years.push(i);
        }

        return (
            <Fragment>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true} {...this.getFieldError('equipment_brand', false)}>
                                <InputLabel id="region-label">{getText('equipment-brand')}</InputLabel>
                                <Select
                                    labelId="region-label"
                                    size="small"
                                    value={formData.equipment_brand}
                                    onChange={this.onFieldChange.bind(this, 'equipment_brand')}
                                >
                                    <MenuItem value="">{getText('general-none')}</MenuItem>
                                    {brands.map((brand, i) => <MenuItem value={brand.equipment_brand._id} key={i}>{brand.equipment_brand.name}</MenuItem>)}
                                </Select>
                                {this.renderHelperText('equipment_brand')}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true} {...this.getFieldError('equipment_distributor', false)}>
                                <InputLabel id="region-label">{getText('equipment-distributor')}</InputLabel>
                                <Select
                                    labelId="region-label"
                                    size="small"
                                    value={formData.equipment_distributor}
                                    onChange={this.onFieldChange.bind(this, 'equipment_distributor')}
                                >
                                    <MenuItem value="">{getText('general-none')}</MenuItem>
                                    {distributors.map((distributor, i) => <MenuItem value={distributor._id} key={i}>{distributor.name}</MenuItem>)}
                                </Select>
                                {this.renderHelperText('equipment_distributor')}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true} {...this.getFieldError('estimated_quantity', false)}>
                                <InputLabel id="region-label">{getText('equipment-quantity')}</InputLabel>
                                <Select
                                    labelId="region-label"
                                    size="small"
                                    value={formData.estimated_quantity}
                                    onChange={this.onFieldChange.bind(this, 'estimated_quantity')}
                                >
                                    <MenuItem value="">{getText('general-none')}</MenuItem>
                                    {quantityOptions.map((quantityOption, i) => <MenuItem value={quantityOption} key={i}>{quantityOption}</MenuItem>)}
                                </Select>
                                {this.renderHelperText('estimated_quantity')}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth={true} {...this.getFieldError('acquisition_year', false)}>
                                <InputLabel id="region-label">{getText('equipment-acquisition_year')}</InputLabel>
                                <Select
                                    labelId="region-label"
                                    size="small"
                                    value={formData.acquisition_year}
                                    onChange={this.onFieldChange.bind(this, 'acquisition_year')}
                                >
                                    <MenuItem value="">{getText('general-none')}</MenuItem>
                                    {years.map((year, i) => <MenuItem value={year} key={year}>{year}</MenuItem>)}
                                </Select>
                                {this.renderHelperText('acquisition_year')}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth={true} {...this.getFieldError('renewal_year', false)}>
                                <InputLabel id="region-label">{getText('equipment-renewal_year')}</InputLabel>
                                <Select
                                    labelId="region-label"
                                    size="small"
                                    value={formData.renewal_year}
                                    onChange={this.onFieldChange.bind(this, 'renewal_year')}
                                >
                                    <MenuItem value="">{getText('general-none')}</MenuItem>
                                    {years.map((year, i) => <MenuItem value={year} key={year}>{year}</MenuItem>)}
                                </Select>
                                {this.renderHelperText('renewal_year')}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Notes"
                                multiline
                                rows={4}
                                value={formData.notes}
                                fullWidth={true}
                                onChange={this.onFieldChange.bind(this, 'notes')}
                                {...this.getFieldError('notes')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={this.cancel.bind(this)}>
                        {getText('action-cancel')}
                    </Button>
                    <Button color="primary" onClick={this.save.bind(this)}>
                        {getText('action-save')}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }

    onFieldChange(attribute, e) {
        const field = e.target;
        const value = field.value;
        const formData = {...this.state.formData};

        formData[attribute] = value;

        this.setState({
            hasChanged: true,
            formData: formData,
        });
    }

    cancel() {
        if(this.state.hasChanged) {
            confirm({
                title: getText('popup-cancel_title'),
                text: getText('popup-cancel_message'),
                confirmLabel: getText('action-yes'),
                cancelLabel: getText('action-no'),
                onClose: (confirm) => {
                    if(confirm) {
                        this.close();
                    }
                },
            });
        } else {
            this.close();
        }
    }

    save() {
        if(this.state.hasChanged || !this.props.id) {
            const {formData} = this.state;
            const data = {};

            for(const attribute in formData) {
                if(['client', 'equipment_type', 'equipment_brand', 'equipment_distributor'].includes(attribute)) {
                    data[attribute] = formData[attribute] !== '' ? formData[attribute] : null;
                } else if(['acquisition_year', 'renewal_year'].includes(attribute)) {
                    let value = parseInt(formData[attribute]);
                    data[attribute] = isNaN(value) ? null : value;
                } else {
                    data[attribute] = formData[attribute];
                }
            }

            // Update if the id is set, create if not
            this.setState({
                formErrors: null,
            });

            if(this.state.equipment && this.state.equipment._id) {
                Api.call('put', '/client-equipments/' + this.state.equipment._id, data)
                    .then((response) => {
                        this.handleFormSuccess(response);
                    })
                    .catch((error) => {
                        this.handleFormError(error);
                    });
            } else {
                Api.call('post', '/client-equipments', data)
                    .then((response) => {
                        this.handleFormSuccess(response);
                    })
                    .catch((error) => {
                        this.handleFormError(error);
                    });
            }
        } else {
            this.close();
        }
    }

    handleFormSuccess(response) {
        this.props.updateEquipment(response.data);
        this.close();
    }

    handleFormError(error) {
        if(error.response.status >= 500) {
            alert('error', error.response.statusText);
            return;
        }

        if(error.response.status === 400) {
            this.setState({
                formErrors: error.response.data.data,
            });
        }
    }

    getFieldError(field, getHelperText = true) {
        const { formErrors } = this.state;

        if(formErrors !== null) {
            const formError = formErrors.find(formError => formError.field === field);

            if(formError) {
                const data = {
                    error: true,
                };

                if(getHelperText) {
                    data.helperText = formError.message;
                }

                return data;
            }
        }
        
        const data = {
            error: false,
        };

        if(getHelperText) {
            data.helperText = null;
        }

        return data;
    }

    renderHelperText(field) {
        const error = this.getFieldError(field);

        if(!error.error) {
            return null;
        }

        return <FormHelperText>{error.helperText}</FormHelperText>
    }

    close() {
        this.setState({
            opened: false,
        });
    }

	componentDidMount() {
        if(this.props.id === null) {
            this.setState({
                isLoading: false,
            });

            return;
        }

		Promise.allSettled([
			Api.call('get', `/client-equipments/${this.props.id}`),
		])
		.then((results) => {           
			const state = {
				isLoading: false,
			}

			if(results[0].status === 'fulfilled') {
				state.equipment = results[0].value.data;

                const formData = {...this.state.formData};

                for(const attribute in formData) {
                    if(['client', 'equipment_type', 'equipment_brand', 'equipment_distributor'].includes(attribute)) {
                        formData[attribute] = !state.equipment[attribute] ? '' : state.equipment[attribute]._id;
                    } else {
                        formData[attribute] = state.equipment[attribute];
                    }
                }

                state.formData = formData;
			}

			this.setState(state);
		});
	}
}

export default EquipmentForm;
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.scss';

const themeLight = createTheme({
    palette: {
        background: {
            default: '#F5F5F5',
        },
    },
});

Sentry.init({
    dsn: 'https://cd918295280146089e2ecc2d15986c5b@o129275.ingest.sentry.io/5903295',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
});

ReactDOM.render(
    <MuiThemeProvider theme={themeLight}>
        <App />
    </MuiThemeProvider>,
    document.getElementById('root'),
);

/*<React.StrictMode></React.StrictMode>*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

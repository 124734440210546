import React, { Component } from 'react';
import * as _ from 'lodash';

import Api from '../../api/Api';
import { hasAddress } from '../../utils/ClientHelper';

import Map from 'ol/Map';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import Feature from 'ol/Feature';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { fromLonLat } from 'ol/proj';
import { Point } from 'ol/geom';
import { Icon, Style } from 'ol/style';
import XYZ from 'ol/source/XYZ';
import ClientInfoCard from '../cards/ClientInfoCard';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

import { withRouter } from 'react-router-dom';
import { getText } from '../../utils/LocaleHelper';
import { boundingExtent } from 'ol/extent';
import ClientFactory from '../../factory/ClientFactory';

class ClientMap extends Component {
	constructor(props) {
		super(props);

		this.clients = null;

		// Map variables
		this.popup = React.createRef();
		this.mapElem = React.createRef();
		this.map = null;
		this.popupOverlay = null;
		this.clientLayers = {};

		this.state = {
			popupClient: null,
		}
	}

	//45, -70
	render() {
		const boxStyle = {
			width: '100%',
			height: '100%',
		}

		const mapStyle = {
			width: '100%',
			height: '100%',
		}

		return (
            <Box style={boxStyle}>
                <div id="map" ref={this.mapElem} style={mapStyle}></div>
                <div id="popup" ref={this.popup}>
                    {this.renderPopup()}
                </div>
            </Box>
        );
	}

	renderPopup() {
		const {popupClient} = this.state;

		if(popupClient === null) {
			return null;
		}
		return (
			<div className="popup-content">
				<Card>
					<CardContent>
						<ClientInfoCard key={popupClient.id} client={popupClient} />
					</CardContent>
					<Divider />
					<CardActions>
						<Button size="small" color="primary" onClick={() => {
							const {history} = this.props;

							history.push('/client/' + popupClient.id);
						}}>
							{getText('action-view')}
						</Button>
					</CardActions>
				</Card>
			</div>
		)
	}

	componentDidMount() {
		this.createMap();
		this.loadClients();
	}

	createMap() {
		// Create map
		this.map = new Map({
			target: this.mapElem.current,
			layers: [
				new TileLayer({
					source: new XYZ({
						url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
					})
				})
			],
			view: new View({
				center: fromLonLat([-71, 46]),
				zoom: 10
			}),
		});

		// Create popup overlay
		this.popupOverlay = new Overlay({
			element: this.popup.current,
			autoPan: true,
			autoPanAnimation: {
				duration: 250
			}
		});
		this.map.addOverlay(this.popupOverlay);

		// Remove controls
		this.map.getControls().forEach((control) => {
			this.map.removeControl(control);
		}, this);

		// Create click event
		this.map.on('singleclick', (evt) => {
			const feature = this.map.forEachFeatureAtPixel(evt.pixel, (feature) => {
				return feature;
			});

			if (feature) {
				const client = feature.get('client');
				const coordinates = feature.getGeometry().getCoordinates();

				this.popupOverlay.setPosition(coordinates);
				this.setState({
					popupClient: client,
				});

				// Move to center marker
				this.map.getView().animate({
					center: coordinates,
					duration: 500,
				});
			} else {
				this.popupOverlay.setPosition(undefined);
				this.setState({
					popupClient: null,
				});
			}
		});
	}

	loadClients() {
		ClientFactory.getClients({
			omit: 'fidelio_ids,notes_history,objectives_history',
		})
		.then((response) => {
			this.clients = response;
			const coords = [];

			for(let i = 0; i < this.clients.length; i++) {
				const client = this.clients[i];

				// Add to map only if the client has an address
				if(hasAddress(client)) {
					// Use the first address as the principal
					let address = client.address.find(add => add.principal);
					address = address || client.address[0];

					// Check if the address has some coordinates
					if(address.latitude && address.longitude) {
						const coord = this.addClientToMap(client);

						if(coord) {
							coords.push(coord);
						}
					} else {
						console.log('Fetching missing coord for ' + client.name);
					}


				} else {
					console.log('Missing address for ' + client.name);
				}
			}

			// Set the map boundaries to the points
			if(coords.length > 1) {
				const extent = boundingExtent(coords);
				this.map.getView().fit(extent, {
					size: this.map.getSize(),
					padding: [300, 300, 300, 300],
				});
			}
		});
	}

	addClientToMap(client) {
		// Get the score for the icon, and the layer
		const icon = _.get(client, 'score.color', 'red');
		// const colors = ['green', 'yellow', 'red'];
		// const icon = colors[Math.floor(Math.random() * colors.length)];

		// Create the layer if it doesn't exist
		if(!(icon in this.clientLayers)) {
			const layer = new VectorLayer({
				source: new VectorSource({
					features: [
						
					]
				}),
				style: new Style({
					image: new Icon({
						anchor: [0.5, 0.5],
						anchorXUnits: 'fraction',
						anchorYUnits: 'fraction',
						src: `/images/coin_${icon}_40.png`,
					})
				})
			});

			this.clientLayers[icon] = layer;

			this.map.addLayer(layer);
		}

		const source = this.clientLayers[icon].getSource();

		// Make sure the client has an address
		if(hasAddress(client)) {
			// Use the first address as the principal
			let address = client.address.find(add => add.principal);
			address = address || client.address[0];

			// Check if the address has some coordinates
			if(address.latitude && address.longitude) {
				const coord = fromLonLat([address.longitude, address.latitude]);

				// Add to map
				source.addFeature(new Feature({
					geometry: new Point(coord),
					client: client,
				}));

				return coord;
			}
		}

		return null;
	}
}

export default withRouter(ClientMap);

import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { getText } from '../../utils/LocaleHelper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Api from '../../api/Api';
import { alert, confirm } from '../../utils/UIHelper';

const UserForm = ({ onClose, currUser }) => {
    const [roles, setRoles] = useState([]);
    const [formData, setFormData] = useState({
        first_name: currUser ? currUser.first_name : '',
        last_name: currUser ? currUser.last_name : '',
        email: currUser ? currUser.email : '',
        language: currUser ? currUser.language : '',
        username: currUser ? currUser.email : '',
        ...(!currUser && { password: '' }),
        role: '',
    });

    useEffect(() => {
        Api.call('get', '/users-permissions/roles').then((res) => {
            const unauthorizedRoles = ['Public', 'Authenticated'];

            const formattedRoles = res.data.roles
                .map((role) => ({
                    id: role.id,
                    name: role.name,
                }))
                .filter((role) => !unauthorizedRoles.includes(role.name));

            setRoles(formattedRoles);
            setFormData({
                ...formData,
                role: currUser
                    ? formattedRoles.find((role) => role.name === currUser.role)?.id
                    : formattedRoles[0].id,
            });
        });
    }, []);

    function updateUser() {
        Api.call('put', `/users/${currUser.id}`, formData).then(() => {
            onClose();
        });
    }

    function createUser() {
        Api.call('post', '/auth/local/register', {
            ...formData,
            confirmed: true,
        }).then((res) => {
            if (res.status === 200) {
                // Update its role since it's not possible to set it in the previous request
                Api.call('put', `/users/${res.data.user.id}`, {
                    role: formData.role,
                }).then(() => {
                    onClose();
                });
            }
        });
    }

    function deleteUser() {
        // set user to not confirmed and blocked
        Api.call('put', `/users/${currUser.id}`, {
            confirmed: false,
            blocked: true,
        }).then(() => {
            onClose();
        });
    }

    return (
        <Dialog
            disableEscapeKeyDown={true}
            maxWidth="sm"
            fullWidth={true}
            disableEnforceFocus
            open={true}
            onClose={() => {}}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {currUser ? getText('user-form-title-edit') : getText('user-form-title-create')}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <TextField
                                label={getText('user-form-first-name')}
                                value={formData.first_name}
                                onChange={(e) =>
                                    setFormData({ ...formData, first_name: e.target.value })
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <TextField
                                label={getText('user-form-last-name')}
                                value={formData.last_name}
                                onChange={(e) =>
                                    setFormData({ ...formData, last_name: e.target.value })
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                label={getText('user-form-email')}
                                value={formData.email}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        email: e.target.value,
                                        username: e.target.value,
                                    })
                                }
                            />
                        </FormControl>
                    </Grid>
                    {!currUser && (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label={getText('user-form-password-temp')}
                                    value={formData.password}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            password: e.target.value,
                                        })
                                    }
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="region-label">
                                {getText('user-form-language')}
                            </InputLabel>
                            <Select
                                label={getText('user-form-language')}
                                value={formData.language}
                                onChange={(e) => {
                                    setFormData({ ...formData, language: e.target.value });
                                }}>
                                <MenuItem value="en">English</MenuItem>
                                <MenuItem value="fr">Français</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="region-label">{getText('user-role')}</InputLabel>
                            <Select
                                label={getText('user-form-role')}
                                value={formData.role}
                                onChange={(e) => {
                                    setFormData({ ...formData, role: e.target.value });
                                }}>
                                {roles.map((role) => (
                                    <MenuItem key={role.id} value={role.id}>
                                        {role.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {currUser && (
                    <Button
                        style={{
                            marginRight: 'auto',
                        }}
                        onClick={() => {
                            confirm({
                                title: getText('user-delete_title'),
                                text: getText('user-delete_message'),
                                confirmLabel: getText('action-yes'),
                                cancelLabel: getText('action-no'),
                                onClose: (confirm) => {
                                    if (confirm) {
                                        deleteUser();
                                    }
                                },
                            });
                        }}
                        color="secondary">
                        {getText('action-delete')}
                    </Button>
                )}
                <Button onClick={onClose} color="primary">
                    {getText('action-cancel')}
                </Button>
                <Button
                    onClick={() => {
                        if (currUser) {
                            updateUser();
                        } else {
                            createUser();
                        }
                    }}
                    color="primary">
                    {currUser ? getText('action-edit') : getText('action-create')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserForm;

import React, {Component} from 'react';
import { Route, Switch, withRouter } from 'react-router';
import './Admin.scss';
import { withUser } from '../../utils/ReactWrappers';
import Loader from '../widgets/Loader';
import AdminIndex from './admin/AdminIndex';
import MissingFidelioIds from './admin/reports/MissingFidelioIds';
import AdminReportsIndex from './admin/reports/AdminReportsIndex';
import ClientsPerformance from './admin/reports/ClientsPerformance';
import RepsPerformance from './admin/reports/RepsPerformance';
import AdminUsers from './admin/AdminUsers';
import ActivityHistory from './admin/ActivityHistory';

class Admin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hasAccess: props.user.role.type === 'admin',
			isLoading: false,
		}
	}

	render() {
		const { hasAccess } = this.state;

		if(!hasAccess) {
			return <div>You do not have access to this page.</div>;
		}

		return this.renderPage();
	}

	renderPage() {
		const { isLoading } = this.state;
		const match = this.props.match;

		if(isLoading) {
            return <Loader />;
        }

		return (
			<Switch>
				<Route exact path={`${match.path}`}>
					<AdminIndex />
				</Route>
				<Route exact path={`${match.path}/reports`}>
					<AdminReportsIndex />
				</Route>
				<Route exact path={`${match.path}/reports/missing-fidelio-ids`}>
					<MissingFidelioIds />
				</Route>
				<Route exact path={`${match.path}/reports/clients-performance`}>
					<ClientsPerformance />
				</Route>
				<Route exact path={`${match.path}/reports/reps-performance`}>
					<RepsPerformance />
				</Route>
				<Route exact path={`${match.path}/users`}>
					<AdminUsers />
				</Route>
				<Route exact path={`${match.path}/activities`}>
					<ActivityHistory />
				</Route>
			</Switch>
		);
	}
}

export default withRouter(withUser(Admin));

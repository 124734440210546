import React, {Component} from 'react';
import { withRouter } from 'react-router';
import Api from '../../../../api/Api';
import AdminLayout from '../../../layout/AdminLayout';
import Loader from '../../../widgets/Loader';
import { updateRelation } from '../../../../utils/ObjectHelper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ClientForm from '../../../form/ClientForm';
import { withUser } from '../../../../utils/ReactWrappers';
import ClientFactory from '../../../../factory/ClientFactory';

class MissingFidelioIds extends Component {

    constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			clients: null,
			regions: null,
            clientFormOpened: false,
			clientFormId: false,
		}
	}

    getBreadcrumbs() {
        return [
            {
                'label': 'Reports',
                'url': '/reports',
            },
            {
                'label': 'Missing Fidelio IDs',
                'url': '/reports/missing-fidelio-ids',
            },
        ]
    }

    renderContent() {
        const { isLoading, clients } = this.state;

        if(isLoading) {
            return <Loader />;
        }

        return (
            <List>
                {clients.map((client) => !client.fidelio_ids || client.fidelio_ids.length === 0  ? (
                    <ListItem key={client.id} button style={{padding: '9px 18px'}} onClick={this.openClientForm.bind(this, client.id)}>
                        <ListItemText primary={client.name} />
                    </ListItem>
                ) : null)}
            </List>
        )
    }
    
    render() {
        return (
            <AdminLayout breadcrumbs={this.getBreadcrumbs()} title="Missing Fidelio IDs">
                {this.renderContent()}
                {this.renderClientForm()}
            </AdminLayout>
        );
    }

    openClientForm(id, e) {
        this.setState({
            clientFormOpened: true,
            clientFormId: id,
        });
    }

	renderClientForm() {
        const {clientFormOpened, clientFormId} = this.state;

        if(!clientFormOpened) {
            return null;
        }

        return (
            <ClientForm
                id={clientFormId}
                closeForm={this.closeClientForm.bind(this)}
                updateClient={this.updateClient.bind(this)}
                user={this.props.user}
            />
        );
    }

	closeClientForm(e) {
        this.setState({
            clientFormOpened: false,
        });
    }

    // Client
    updateClient(client) {
		this.setState({
			clients: updateRelation(this.state, 'clients', client).clients,
		});
    }

    componentDidMount() {
		Promise.allSettled([
			Api.call('get', '/regions'),
			ClientFactory.getClients({
                select: 'name'
            }),
		])
		.then((results) => {
			const state = {
				isLoading: false,
			}

			if(results[0].status === 'fulfilled') {
				state.regions = results[0].value.data;
			}

			if(results[1].status === 'fulfilled') {
				state.clients = results[1].value;
			}

			this.setState(state);

            if(this.props.onLoad) {
                this.props.onLoad(state.clients);
            }
		});
	}
}

export default withRouter(withUser(MissingFidelioIds));
import Box from '@material-ui/core/Box';
import React, {Component} from 'react';
import { withRouter } from 'react-router';
import { withUser } from '../../../utils/ReactWrappers';

import InvoiceList from '../../lists/InvoiceList';
import Loader from '../../widgets/Loader';

class ClientInvoices extends Component {
	constructor(props) {
		super(props);

		this.state = {
			
		}
	}

	render() {
        const {isLoading} = this.state;

		if(isLoading) {
            return <Loader />;
        }

        return (
			<Box>
                {this.renderInvoices()}
            </Box>
		);
	}

    renderInvoices() {
        const {client} = this.props;

        return <InvoiceList clientId={client._id} />;
    }
}

export default withRouter(withUser(ClientInvoices));

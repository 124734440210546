import { setLang } from './LocaleHelper';

let _user = null;

export function getUser() {
    return _user;
}

export function setUser(user, changeLang = true) {
    _user = user;
    if (changeLang) {
        setLang(user.language);
    }
}

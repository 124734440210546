import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';

import { updateRelation, deleteRelation } from '../../utils/ObjectHelper';

import Api from '../../api/Api';
import ProjectForm from '../form/ProjectForm';
import SearchResults from '../layout/SearchResults';
import ListFilter from './ListFilter';
import { filterList } from '../../utils/ListHelper';
import { getText } from '../../utils/LocaleHelper';
import { confirm } from '../../utils/UIHelper';
import EmptyList from '../layout/EmptyList';
import { splitProjects } from '../../utils/ProjectHelper';
import { withUser } from '../../utils/ReactWrappers';
import Loader from '../widgets/Loader';

class ProjectList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            projects: null,
            types: null,
            filters: null,
            projectFormOpened: false,
            linkToClient: false,
            projectFormData: {
                id: null,
                clientId: null,
            },
            canEdit: props.user.role.type !== 'consultation',
        };
    }

    render() {
        const { isLoading } = this.state;

        if (isLoading) {
            return <Loader />;
        }

        return (
            <Box>
                {this.props.withoutControls ? null : (
                    <ListFilter
                        onFilterUpdate={(filters) => {
                            this.setState({ filters: filters });
                        }}
                    />
                )}
                {this.renderProjects()}
                {this.renderProjectForm()}
            </Box>
        );
    }

    renderProjectForm() {
        const { projectFormOpened, projectFormData } = this.state;

        if (!projectFormOpened) {
            return null;
        }

        return (
            <ProjectForm
                closeForm={this.closeProjectForm.bind(this)}
                updateProject={this.updateProject.bind(this)}
                {...projectFormData}
            />
        );
    }

    renderProjects() {
        const { projects, filters, canEdit } = this.state;

        // Filter projects
        const filteredProjects = filterList(projects, filters, [
            'client.name',
            'client.address.city',
            'equipment.name',
            'notes',
            'name',
            'date',
            'status',
        ]);

        const sortedProjects = splitProjects(filteredProjects);

        return (
            <Box>
                {this.props.withoutControls ? null : (
                    <Box m={1}>
                        <SearchResults
                            value={filteredProjects.length}
                            labelSingle={getText('project-result_singular')}
                            labelPlural={getText('project-result_plural')}
                            buttonLabel={canEdit ? getText('action-create') : null}
                            onButtonClick={this.openProjectForm.bind(this, null)}
                        />
                    </Box>
                )}
                <Grid container>
                    {filteredProjects.length > 0 ? null : (
                        <EmptyList message={getText('project-empty_list')} />
                    )}
                    <Fragment>
                        {this.props.hideOnEmptyCategory &&
                        sortedProjects.in_progress.length === 0 ? null : (
                            <>
                                <Grid item sm={12}>
                                    <Box m={1} mt={2}>
                                        <Typography variant="subtitle1" component="h2">
                                            {getText('project-status_in_progress') +
                                                ' (' +
                                                sortedProjects.in_progress.length +
                                                ')'}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </>
                        )}
                        {this.renderProjectList(sortedProjects.in_progress)}
                    </Fragment>
                    <Fragment>
                        {this.props.hideOnEmptyCategory &&
                        sortedProjects.future.length === 0 ? null : (
                            <>
                                <Grid item sm={12}>
                                    <Box m={1} mt={2}>
                                        <Typography variant="subtitle1" component="h2">
                                            {getText('project-status_future') +
                                                ' (' +
                                                sortedProjects.future.length +
                                                ')'}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </>
                        )}
                        {this.renderProjectList(sortedProjects.future)}
                    </Fragment>
                    <Fragment>
                        {this.props.hideOnEmptyCategory &&
                        sortedProjects.past.length === 0 ? null : (
                            <>
                                <Grid item sm={12}>
                                    <Box m={1} mt={2}>
                                        <Typography variant="subtitle1" component="h2">
                                            {getText('project-status_past') +
                                                ' (' +
                                                sortedProjects.past.length +
                                                ')'}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </>
                        )}
                        {this.renderProjectList(sortedProjects.past)}
                    </Fragment>
                    <Fragment>
                        {this.props.hideOnEmptyCategory &&
                        sortedProjects.won.length === 0 ? null : (
                            <>
                                <Grid item sm={12}>
                                    <Box m={1} mt={2}>
                                        <Typography variant="subtitle1" component="h2">
                                            {getText('project-status_won') +
                                                ' (' +
                                                sortedProjects.won.length +
                                                ')'}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </>
                        )}
                        {this.renderProjectList(sortedProjects.won)}
                    </Fragment>
                    <Fragment>
                        {this.props.hideOnEmptyCategory &&
                        sortedProjects.lost.length === 0 ? null : (
                            <>
                                <Grid item sm={12}>
                                    <Box m={1} mt={2}>
                                        <Typography variant="subtitle1" component="h2">
                                            {getText('project-status_lost') +
                                                ' (' +
                                                sortedProjects.lost.length +
                                                ')'}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </>
                        )}
                        {this.renderProjectList(sortedProjects.lost)}
                    </Fragment>
                </Grid>
            </Box>
        );
    }

    renderProjectList(projects) {
        const { canEdit } = this.state;
        const { fullWidth } = this.props;

        return (
            <Fragment>
                {projects.map((project, i) => {
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={!fullWidth ? 6 : 12}
                            lg={!fullWidth ? 4 : 12}
                            key={project._id}>
                            <Box m={1} key={i}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6" component="h2">
                                            {project.name} - {project.date}
                                        </Typography>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="center">
                                            <Grid item>
                                                <Typography color="textSecondary">
                                                    {project.client.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography color="secondary">
                                                    {getText('project-status_' + project.status)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {!project.notes || project.notes === '' ? null : (
                                            <Box>
                                                <Typography variant="caption">
                                                    {project.notes}
                                                </Typography>
                                            </Box>
                                        )}
                                    </CardContent>
                                    {!this.props.linkToClient && !canEdit ? null : (
                                        <Fragment>
                                            <Divider />
                                            <CardActions>
                                                {!canEdit ? null : (
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        onClick={this.openProjectForm.bind(
                                                            this,
                                                            project._id,
                                                        )}>
                                                        {getText('action-edit')}
                                                    </Button>
                                                )}
                                                {!this.props.linkToClient ? null : (
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        onClick={this.onClientClick.bind(
                                                            this,
                                                            project.client,
                                                        )}>
                                                        {getText('client-view_client')}
                                                    </Button>
                                                )}
                                                {!canEdit ? null : (
                                                    <Button
                                                        size="small"
                                                        color="secondary"
                                                        onClick={this.deleteProject.bind(
                                                            this,
                                                            project._id,
                                                        )}>
                                                        {getText('action-delete')}
                                                    </Button>
                                                )}
                                            </CardActions>
                                        </Fragment>
                                    )}
                                </Card>
                            </Box>
                        </Grid>
                    );
                })}
            </Fragment>
        );
    }

    componentDidMount() {
        const filters = this.props.clientId
            ? `?_limit=-1&client=${this.props.clientId}`
            : `${this.props.params ? '?' + this.props.params : '?_limit=-1'}`;

        Promise.allSettled([
            Api.call('get', `/client-projects${filters}`),
            Api.call('get', '/equipment-types?_limit=0'),
        ]).then((results) => {
            const state = {
                isLoading: false,
                opened: {},
            };

            if (results[0].status === 'fulfilled') {
                state.projects = results[0].value.data;
            }

            if (results[1].status === 'fulfilled') {
                state.types = results[1].value.data;
            }

            this.setState(state);
        });
    }

    // Forms
    openProjectForm(id, e) {
        this.setState({
            projectFormOpened: true,
            projectFormData: {
                id: id,
                clientId: this.props.clientId,
            },
        });
    }

    closeProjectForm(e) {
        this.setState({
            projectFormOpened: false,
        });
    }

    // Contact
    updateProject(project) {
        this.setState(updateRelation(this.state, 'projects', project));
    }

    deleteProject(id) {
        confirm({
            title: getText('project-delete_title'),
            text: getText('project-delete_message'),
            confirmLabel: getText('action-yes'),
            cancelLabel: getText('action-no'),
            onClose: (confirm) => {
                if (confirm) {
                    Api.call('delete', '/client-projects/' + id).then((response) => {
                        this.setState(deleteRelation(this.state, 'projects', id));
                    });
                }
            },
        });
    }

    // Client
    onClientClick(client) {
        const { history } = this.props;

        history.push('/client/' + client.id);
    }
}

export default withRouter(withUser(ProjectList));

export function parseErrors(message) {
    let errors = [];

    if(typeof message === 'string' || message instanceof String) {
        errors.push(message);
    } else if(typeof message === 'object' && message !== null) {
        if('message' in message) {
            errors.push(message.message);
        } else {
            for(let key in message) {
                errors = errors.concat(parseErrors(message[key]));
            }
        }
    } else if(Array.isArray(message)) {
        for(let i = 0; i < message.length; i++) {
            errors = errors.concat(parseErrors(message[i]));
        }
    }

    return errors;
}
import React, { Component } from 'react';
import { withRouter } from 'react-router';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import Api from '../../api/Api';
import ClientInfoCard from '../cards/ClientInfoCard';
import SearchResults from '../layout/SearchResults';
import ListFilter from '../lists/ListFilter';
import { filterList } from '../../utils/ListHelper';
import { getText } from '../../utils/LocaleHelper';
import ClientForm from '../form/ClientForm';
import { updateRelation } from '../../utils/ObjectHelper';
import { withUser } from '../../utils/ReactWrappers';
import Loader from '../widgets/Loader';
import ClientFactory from '../../factory/ClientFactory';

class ClientList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            clients: [],
            regions: null,
            filters: null,
            onListLoad: null,
            clientFormOpened: false,
            clientFormId: false,
            canEdit: props.user.role.type !== 'consultation',
            currClientList: [],
            currClientListIndex: 0,
            currClientTotal: 0,
        };
    }

    render() {
        const { isLoading, canEdit, currClientList, filters, currClientTotal } = this.state;

        if (isLoading) {
            return <Loader />;
        }

        // Filter clients
        const filteredClients = filterList(currClientList, filters, [
            'name',
            'address.city',
            'region.name',
            'sales_representative.first_name',
            'sales_representative.last_name',
        ]);

        return (
            <Box>
                <ListFilter filterName="clients" onFilterUpdate={this.onFilterUpdate.bind(this)} />
                <Box m={1}>
                    <SearchResults
                        value={currClientTotal}
                        labelSingle={getText('client-result_singular')}
                        labelPlural={getText('client-result_plural')}
                        buttonLabel={canEdit ? getText('action-create') : null}
                        onButtonClick={this.openClientForm.bind(this, null)}
                    />
                </Box>
                {filteredClients.map((client, i) => (
                    <Box m={1} key={client.id}>
                        <Card>
                            <CardContent>
                                <ClientInfoCard client={client} />
                            </CardContent>
                            <Divider />
                            <CardActions>
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={this.onClientViewClick.bind(this, client)}>
                                    {getText('action-view')}
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                ))}
                <Box m={1} display="flex" justifyContent="center">
                    {currClientList.length < currClientTotal && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                this.renderNextClientBatch();
                            }}>
                            {getText('action-view_more')}
                        </Button>
                    )}
                </Box>
                {this.renderClientForm()}
            </Box>
        );
    }

    componentDidMount() {
        Promise.allSettled([
            Api.call('get', '/regions'),
            ClientFactory.getClients({
                omit: 'address,score,fidelio_ids,notes_history,objectives_history',
            }),
        ]).then((results) => {
            const state = {
                isLoading: false,
            };

            if (results[0].status === 'fulfilled') {
                state.regions = results[0].value.data;
            }

            if (results[1].status === 'fulfilled') {
                state.clients = results[1].value;
                this.renderNextClientBatch(state.clients);
            }

            this.setState(state);

            if (this.props.onLoad) {
                this.props.onLoad(state.clients);
            }
        });

        this.props.history.listen((location) => {
            if (location.pathname === '/clients') {
                this.setState({
                    isLoading: true,
                });

                ClientFactory.getClients({
                    omit: 'address,score,fidelio_ids,notes_history,objectives_history',
                }).then((results) => {
                    const state = {
                        isLoading: false,
                    };
                    state.clients = results;
                    this.setState(state);
                });
            }
        });
    }

    onClientViewClick(client, e) {
        this.props.onClientViewClick(client);
    }

    openClientForm(id, e) {
        this.setState({
            clientFormOpened: true,
            clientFormId: id,
        });
    }

    componentWillUnmount() {
        this.props.history.listen(() => {});
    }

    renderNextClientBatch(clientsProps, indexProps, reset) {
        const { clients, currClientListIndex, currClientList, filters } = this.state;
        const clientsRef = clientsProps ? clientsProps : clients;
        const indexRef = indexProps >= 0 ? indexProps : currClientListIndex;
        const batch = 100;
        const start = indexRef * batch;
        const end = start + batch;

        let filteredClients = clientsRef;

        // happen when view more click
        if (!clientsProps) {
            filteredClients = filterList(clients, filters, [
                'name',
                'address.city',
                'region.name',
                'sales_representative.first_name',
                'sales_representative.last_name',
            ]);
        }

        let newCurrClientList = reset
            ? filteredClients.slice(start, end)
            : currClientList.concat(filteredClients.slice(start, end));

        // make sure no duplicates
        newCurrClientList = newCurrClientList.filter(
            (client, index, self) => self.findIndex((c) => c.id === client.id) === index,
        );

        this.setState({
            currClientList: newCurrClientList,
            currClientListIndex: indexRef + 1,
            currClientTotal: filteredClients.length,
        });
    }

    onFilterUpdate(filters) {
        this.setState({
            filters: filters,
        });
        this.filterClientList(filters);
    }

    filterClientList(filters) {
        const { clients } = this.state;

        const filteredClients = filterList(clients, filters, [
            'name',
            'address.city',
            'region.name',
            'sales_representative.first_name',
            'sales_representative.last_name',
        ]);

        this.renderNextClientBatch(filteredClients, 0, true);
    }

    renderClientForm() {
        const { clientFormOpened, clientFormId } = this.state;

        if (!clientFormOpened) {
            return null;
        }

        return (
            <ClientForm
                id={clientFormId}
                closeForm={this.closeClientForm.bind(this)}
                updateClient={this.updateClient.bind(this)}
                user={this.props.user}
            />
        );
    }

    closeClientForm(e) {
        this.setState({
            clientFormOpened: false,
        });
    }

    // Client
    updateClient(client) {
        this.setState({
            clients: updateRelation(this.state, 'clients', client).clients,
        });
    }
}

export default withRouter(withUser(ClientList));

import Api from '../api/Api';

class ClientFactory {
    static clients = null;
    static fidelioClients = null;
    static lastFetch = 0;
    static expireDelay = 5 * 60; // 5 minutes

    static getClients(params = {}) {
        const paramsUrl = new URLSearchParams(params).toString();

        if (ClientFactory.isExpired()) {
            return Api.call('get', '/clients?_limit=0&_sort=name&' + paramsUrl).then((resp) => {
                if (!params?.omit && !params?.select) {
                    ClientFactory.clients = resp.data;
                    ClientFactory.lastFetch = new Date().getTime();

                    return ClientFactory.clients;
                } else {
                    return resp.data;
                }
            });
        }

        return new Promise((resolve, reject) => {
            resolve(ClientFactory.clients);
        });
    }

    static isExpired() {
        const currentTime = new Date().getTime();

        if (ClientFactory.lastFetch < currentTime - ClientFactory.expireDelay * 1000) {
            return true;
        }

        return false;
    }

    static forceRefresh() {
        ClientFactory.lastFetch = 0;
    }

    static getFidelioClients() {
        if (ClientFactory.fidelioClients === null) {
            return Api.call('get', '/clients/fidelio-clients').then((resp) => {
                ClientFactory.fidelioClients = resp.data;

                return ClientFactory.fidelioClients;
            });
        }

        return new Promise((resolve, reject) => {
            resolve(ClientFactory.fidelioClients);
        });
    }
}

export default ClientFactory;

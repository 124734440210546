export function getEndTime(meeting) {
    const startTime = formatTime(meeting.time).split(':');
    const durationTime = formatTime(meeting.duration).split(':');

    let hours = parseInt(startTime[0]) + parseInt(durationTime[0]);
    let minutes = parseInt(startTime[1]) + parseInt(durationTime[1]);

    if(minutes >= 60) {
        minutes -= 60;
        hours++;
    }

    hours %= 24;
    
    return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
}

export function formatTime(time) {
    const parts = time.split(':');
    return `${parts[0]}:${parts[1]}`;
}

export function isOnPhone(meeting) {
    /**
     * Validates : 
     * 
     * 1234567890
     * (123) 456-7890
     * +(123) 456-7890
     * +(123)-456-7890
     * +1 123.456.7890
     * 123-456-7890
     * 123 456 7890
     * 123.456.7890
     * 1234567890
     * +31636363634
     * 075-63546725
     */

    return /^[+]{0,1}[0-9]{0,1}[(-\s.]{0,2}[0-9]{3}[)-\s.]{0,2}[0-9]{3}[-\s.]{0,1}[0-9]{4,}/g.test(meeting.location);
}
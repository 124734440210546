import React, {Component} from 'react';
import { withRouter } from 'react-router';
import { getText } from '../../utils/LocaleHelper';
import { withUser } from '../../utils/ReactWrappers';

import MainLayout from '../layout/MainLayout';
import ReminderList from '../lists/ReminderList';

class Reminders extends Component {
	constructor(props) {
		super(props);

		this.state = {
			
		}
	}

	render() {
		return (
			<MainLayout title={getText('menu-reminders')}>
				<ReminderList linkToClient={true} />
			</MainLayout>
		);
	}
}

export default withRouter(withUser(Reminders));

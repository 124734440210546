import React, {Component, Fragment} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr-CA";
import enLocale from "date-fns/locale/en-US";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import Api from '../../api/Api';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';

import { format } from 'date-fns';
import { confirm } from '../../utils/UIHelper';
import { getLang, getText } from '../../utils/LocaleHelper';
import { validateEmail } from '../../utils/StringHelper';
import Loader from '../widgets/Loader';
import ClientFactory from '../../factory/ClientFactory';

const locales = {
    fr: frLocale,
    en: enLocale,
}

class ReminderForm extends Component {
    static defaultProps = {
        id: null,
        clientId: null,
        closeForm: null,
        updateReminder: null,
        meeting: null,
    }

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
            hasChanged: false,
            clients: null,
            reminder: null,
            users: null,
            opened: true,
            formErrors: null,
            formData: {
                description: '',
                date: null,
                client: this.props.clientId,
            }
		}
	}

	render() {
		return (
            <Fragment>
                <Dialog
                    onClose={this.onDialogClose}
                    disableEscapeKeyDown={true}
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.opened}
                    disableEnforceFocus
                    TransitionProps={{
                        onExited: this.props.closeForm
                    }}
                >
                    <DialogTitle>{getText('menu-reminders')}</DialogTitle>
                    {this.renderContent()}
                </Dialog>
            </Fragment>
		);
	}

    onDialogClose(e, reason) {
        if(reason === 'backdropClick') {
            return;
        }

        this.props.closeForm();
    }

    renderContent() {
        const {isLoading, formData, clients } = this.state;

        if(isLoading) {
            return <Loader />;
        }

        const textFieldStyle = {
            width: "10rem",
            marginTop: "1rem"
        }

        return (
            <Fragment>
                <DialogContent>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locales[getLang()]}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box display="flex">
                                    <FormControl fullWidth={true} required={true} {...this.getFieldError('client', false)}>
                                        <InputLabel id="region-label">{getText('client')}</InputLabel>
                                        <Select
                                            labelId="region-label"
                                            size="small"
                                            value={formData.client !== null ? formData.client : ''}
                                            onChange={this.onFieldChange.bind(this,'client')}
                                        >
                                            <MenuItem value="">{getText('general-none')}</MenuItem>
                                            {clients.map((client, i) => <MenuItem value={client._id} key={i}>{client.name}</MenuItem>)}
                                        </Select>
                                        {this.renderHelperText('client')}
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <FormControl fullWidth={true} required={true} {...this.getFieldError('date', false)}>
                                    <KeyboardDatePicker
                                        id="date-picker-dialog"
                                        format="yyyy-MM-dd"
                                        label="Date"
                                        required={true}
                                        value={formData.date}
                                        onChange={this.onFieldChange.bind(this, 'date')}
                                        {...this.getFieldError('date')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Description"
                                    multiline
                                    rows={4}
                                    value={formData.description}
                                    fullWidth={true}
                                    onChange={this.onFieldChange.bind(this, 'description')}
                                    {...this.getFieldError('description')}
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={this.cancel.bind(this)}>
                        {getText('action-cancel')}
                    </Button>
                    <Button color="primary" onClick={this.save.bind(this)}>
                        {getText('action-save')}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }

    onFieldChange(attribute, e, val) {
        const formData = {...this.state.formData};

        if(['date', 'time', 'duration'].includes(attribute)) {
            formData[attribute] = e;
        } else if(['attendees'].includes(attribute)) {
            val = val.filter(v => validateEmail(v.email || v)).map(v => v.email || v);
            formData[attribute] = val;
        } else {
            const field = e.target;
            const value = field.value;
            formData[attribute] = value;

            if (attribute === 'client') {
                //  if "none" is selected 
                formData.reminders = '';
            }
        }

        this.setState({
            hasChanged: true,
            formData: formData,
        });

    }

    cancel() {
        if(this.state.hasChanged) {
            confirm({
                title: getText('popup-cancel_title'),
                text: getText('popup-cancel_message'),
                confirmLabel: getText('action-yes'),
                cancelLabel: getText('action-no'),
                onClose: (confirm) => {
                    if(confirm) {
                        this.close();
                    }
                },
            });
        } else {
            this.close();
        }
    }

    save() {
        if(this.state.hasChanged || !this.props.id) {
            const {formData} = this.state;
            const data = {};

            for(const attribute in formData) {
                if(['client'].includes(attribute)) {
                    data[attribute] = formData[attribute] !== '' ? formData[attribute] : null;
                } else if(['date'].includes(attribute)) {
                    if(formData[attribute] === '' || formData[attribute] === null) {
                        data[attribute] = null;
                    } else {
                        let date = formData[attribute] instanceof Date ? formData[attribute] : Date.parse(formData[attribute]);
                        data[attribute] = format(date, 'yyyy-MM-dd');
                    }
                } else {
                    data[attribute] = formData[attribute];
                }
            }

            if(this.props.meeting) {
                data.client_meeting_origin = this.props.meeting._id;
            }

            // Update if the id is set, create if not
            this.setState({
                formErrors: null,
            });

            if(this.props.id) {
                Api.call('put', '/client-reminders/' + this.props.id, data)
                    .then((response) => {
                        this.handleFormSuccess(response);
                    })
                    .catch((error) => {
                        this.handleFormError(error);
                    });
            } else {
                Api.call('post', '/client-reminders', {...data, client: this.props.clientId || data.client})
                    .then((response) => {
                        this.handleFormSuccess(response);
                    })
                    .catch((error) => {
                        this.handleFormError(error);
                    });
            }
        } else {
            this.close();
        }
    }

    handleFormSuccess(response) {
        this.props.updateReminder(response.data);
        this.close();
    }

    handleFormError(error) {
        if(error.response?.status >= 500) {
            alert('error', error.response.statusText);
            return;
        }

        if(error.response?.status === 400) {
            this.setState({
                formErrors: error.response.data.data,
            });
        }
    }

    getFieldError(field, getHelperText = true) {
        const { formErrors } = this.state;

        if(formErrors !== null) {
            const formError = formErrors.find(formError => formError.field === field);

            if(formError) {
                const data = {
                    error: true,
                };

                if(getHelperText) {
                    data.helperText = formError.message;
                }

                return data;
            }
        }
        
        const data = {
            error: false,
        };

        if(getHelperText) {
            data.helperText = null;
        }

        return data;
    }

    renderHelperText(field) {
        const error = this.getFieldError(field);

        if(!error.error) {
            return null;
        }

        return <FormHelperText>{error.helperText}</FormHelperText>
    }

    close() {
        this.setState({
            opened: false,
        });
    }

	componentDidMount() {

        const requests = [
			ClientFactory.getClients({
                select: 'name'
            }),
		]

        if(this.props.id) {         
            requests.push(Api.call('get', '/client-reminders/' + this.props.id));
        }

		Promise.allSettled(requests)
		.then((results) => {       
             
			const state = {
				isLoading: false,
			}

            if(results[0].status === 'fulfilled') {
				state.clients = results[0].value;
			}

			if(results[1] && results[1].status === 'fulfilled') {
                
				state.reminder = results[1].value.data;

                const formData = {...this.state.formData};
                
                for(const attribute in formData) {
                    if(['client'].includes(attribute)) { 
                        formData[attribute] = !state.reminder[attribute] ? '' : state.reminder[attribute]._id; 
                        //this.getClientFutureProjects(formData.client);     
                    } else if(['date'].includes(attribute)) {
                        formData[attribute] = state.reminder[attribute] + " 00:00:00";
                    } else {
                        formData[attribute] = state.reminder[attribute];
                    }
                }
                
                state.formData = formData;
			}

			this.setState(state);
		});
	}
}

export default ReminderForm;
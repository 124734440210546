import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import MapIcon from '@material-ui/icons/Map';
import Grid from '@material-ui/core/Grid';

import { formatTime, getEndTime, isOnPhone } from '../../utils/MeetingHelper';
import { getText } from '../../utils/LocaleHelper';
import Api from '../../api/Api';

class MeetingCard extends Component {
    static defaultProps = {
        meeting: null,
        showDate: false,
        linkToClient: false,
        canEdit: false,
        client: null,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { meeting, canEdit, linkToClient } = this.props;
        const { client } = this.state;
        const onPhone = isOnPhone(meeting);

        const notes = meeting?.client?.notes_history || client?.notes_history || [];
        const currClient = meeting?.client || client;
        const hasMeetingNotes = notes.find((note) => {
            if (note?.client_meeting) {
                return note.client_meeting.id === meeting.id;
            } else {
                return false;
            }
        });

        return (
            <Box m={1}>
                <Card>
                    <CardContent>
                        <Box>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="h6" component="h2">
                                        {formatTime(meeting.time)}-{getEndTime(meeting)} (
                                        {formatTime(meeting.duration).replace(':', 'h')})
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography color="secondary">
                                        {getText('meeting-status_' + meeting.type)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography color="textSecondary">
                                        {currClient.name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        {this.props.showDate ? meeting.date : null}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={1}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <LocationOnIcon />
                                        </Grid>
                                        <Grid item>
                                            <Typography>{meeting.location || 'n/d'}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        variant="contained"
                                        color="primary"
                                        onClick={this.onLocationClick.bind(this)}>
                                        {onPhone ? <PhoneIcon /> : <MapIcon />}
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                        {!meeting.notes || meeting.notes === '' ? null : (
                            <Box>
                                <Typography variant="caption">{meeting.notes}</Typography>
                            </Box>
                        )}
                    </CardContent>
                    {!linkToClient && !canEdit ? null : (
                        <Fragment>
                            <Divider />
                            <CardActions>
                                {!canEdit ? null : (
                                    <Button
                                        size="small"
                                        color="primary"
                                        onClick={this.props.openMeetingForm}>
                                        {getText('action-edit')}
                                    </Button>
                                )}
                                {this.props.openMeetingNotes && hasMeetingNotes && (
                                    <Button
                                        size="small"
                                        color="primary"
                                        onClick={this.props.openMeetingNotes}>
                                        {getText('client-notes_view')}
                                    </Button>
                                )}
                                {!linkToClient ? null : (
                                    <Button
                                        size="small"
                                        color="primary"
                                        onClick={this.onClientClick.bind(this)}>
                                        {getText('client-view_client')}
                                    </Button>
                                )}
                                {!canEdit ? null : (
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={this.deleteMeeting.bind(this, meeting)}>
                                        {getText('action-delete')}
                                    </Button>
                                )}
                            </CardActions>
                        </Fragment>
                    )}
                </Card>
            </Box>
        );
    }

    onLocationClick() {
        const { meeting } = this.props;
        const onPhone = isOnPhone(meeting);

        // https://www.google.com/maps/search/?api=1&query=centurylink+field
        if (onPhone) {
            window.open('tel:' + meeting.location);
        } else {
            window.open('https://www.google.com/maps/search/?api=1&query=' + meeting.location);
        }
    }

    onClientClick() {
        const { history, meeting } = this.props;
        const currClientId = meeting.client[0]?._id || this.state.client?.id;
        history.push('/client/' + currClientId);
    }

    deleteMeeting(meeting) {
        this.props.deleteMeeting(meeting._id);
    }

    componentDidMount() {
        const {
            meeting: { client },
        } = this.props;

        // Fetch the client if the provided client is only an id
        if (typeof client === 'string' && client) {
            Api.call('get', '/clients/' + client).then((resp) => {

                this.setState({
                    client: resp.data,
                });
            });
        }
    }
}

export default withRouter(MeetingCard);

import React, {Component} from 'react';
import { withRouter } from 'react-router';

import './Map.scss';
import MainLayout from '../layout/MainLayout';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { withMediaQuery, withUser } from '../../utils/ReactWrappers';
import MeetingList from '../lists/MeetingList';
import ClientMap from '../map/ClientMap';
import { getText } from '../../utils/LocaleHelper';

class Map extends Component {
	//45, -70
	render() {
		return (
			<MainLayout title="Carte" showTitleBar={false}>
				{this.renderContent()}
			</MainLayout>
		);
	}

	renderContent() {
		const { isDesktop } = this.props;

		if(!isDesktop) {
			return <ClientMap />;
		}

		const style = {
			height: '100%',
			overflowY: 'auto',
		}

		const rightColumnStyle = {
			...style,
			zIndex: 99,
			width: '380px',
		}

		return (
			<Box style={style}>
				<Grid container style={style}>
					<Grid item className="map-container" style={style}>
						<ClientMap />
					</Grid>
					<Grid item className="sidebar-container" style={rightColumnStyle}>
						<MeetingList
							oneColumn={true}
							labelSingle={getText('meeting-futur_result_singular')}
							labelPlural={getText('meeting-futur_result_plural')}
							buttonLabel={getText('action-view_all')}
							onButtonClick={this.onViewMeetingsClick.bind(this)}
							linkToClient={true}
							renderPastMeetings={false}
						/>
					</Grid>
				</Grid>
			</Box>
		)
	}

	onViewMeetingsClick() {
		const {history} = this.props;

		history.push('/meetings');
	}
}

export default withRouter(withUser(withMediaQuery([
    ['isDesktop', theme => theme.breakpoints.up('lg'), {
        defaultMatches: true
    }]
])(Map)));

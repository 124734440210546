import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import MapIcon from '@material-ui/icons/Map';
import Grid from '@material-ui/core/Grid'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { formatTime, getEndTime, isOnPhone } from '../../utils/MeetingHelper';
import { getText } from '../../utils/LocaleHelper';
import ReminderForm from '../form/ReminderForm';
import Api from '../../api/Api';

class UnfilledMeetingCard extends Component {
	static defaultProps = {
        meeting: null,
		showDate: false,
		linkToClient: false,
		canEdit: false,
    }

	constructor(props) {
		super(props);

		const { meeting } = props;

		const reminderFormData = {
			id: null,
			clientId: meeting.client.id,
		}

		if(meeting.reminder) {
			reminderFormData.id = meeting.reminder.id;
		}

		this.state = {
			reminderListOpened: false,
			reminderFormOpened: false,
			reminderFormData: reminderFormData,
			note: '',
			noteFormOpened: false,
		}
	}

	renderNoteForm() {
		const {noteFormOpened, note} = this.state;

		return (
			<Dialog
				disableEscapeKeyDown={true}
				maxWidth='sm'
				fullWidth={true}
				open={noteFormOpened}
				disableEnforceFocus
			>
				<DialogTitle>Note</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
                            <TextField
                                required={true}
                                fullWidth={true}
                                size="small"
                                value={note}
                                onChange={(e) => {this.setState({note: e.target.value})}}
                            />
                        </Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
                    <Button color="secondary" onClick={this.closeNoteForm.bind(this)}>
                        {getText('action-cancel')}
                    </Button>
                    <Button color="primary" onClick={this.saveNote.bind(this)}>
                        {getText('action-save')}
                    </Button>
                </DialogActions>
			</Dialog>
		);
	}

	renderForms() {
		return (
			<Fragment>
				{this.renderReminderForm()}
				{this.renderNoteForm()}
			</Fragment>
		)
	}

	render() {
		const { meeting, canEdit, linkToClient } = this.props;
		const onPhone = isOnPhone(meeting);

		return (
			<Fragment>
				<Box m={1}>
					<Card>
						<CardContent>
							<Box>
								<Grid container justifyContent="space-between" alignItems="center">
									<Grid item>
										<Typography variant="h6" component="h2">
											{formatTime(meeting.time)}-{getEndTime(meeting)} ({formatTime(meeting.duration).replace(':', 'h')})
										</Typography>
									</Grid>
									<Grid item>
										<Typography color="secondary">
											{getText('meeting-status_'+meeting.type)}
										</Typography>
									</Grid>
								</Grid>
								<Grid container justifyContent="space-between" alignItems="center">
									<Grid item>
										<Typography color="textSecondary">
											{meeting.client.name}
										</Typography>
									</Grid>
									<Grid item>
										<Typography>
											{this.props.showDate ? meeting.date : null}
										</Typography>
									</Grid>
								</Grid>
							</Box>
							<Box mt={1}>
								<Grid container justifyContent="space-between" alignItems="center">
									<Grid item>
										<Grid container alignItems="center">
											<Grid item>
												<LocationOnIcon />
											</Grid>
											<Grid item>
												<Typography>
													{meeting.location || 'n/d'}
												</Typography>
											</Grid>
										</Grid>									
									</Grid>
									<Grid item>
										<IconButton variant="contained" color="primary" onClick={this.onLocationClick.bind(this)}>
											{onPhone ? <PhoneIcon /> : <MapIcon />}
										</IconButton>
									</Grid>
								</Grid>
							</Box>
							{
								!meeting.notes || meeting.notes === '' ? null :
								<Box>
									<Typography variant="caption">
										{meeting.notes}
									</Typography>
								</Box>
							}
						</CardContent>
						{
							!linkToClient && !canEdit ? null :
							<Fragment>
								<Divider />
								<CardActions>
									{
										!canEdit ? null :
										<Button size="small" color="primary" onClick={this.openReminderForm.bind(this)}>
											+&nbsp;alerte
										</Button>
									}
									{
										!canEdit ? null :
										<Button size="small" color="primary" onClick={this.openNoteForm.bind(this)}>
											+&nbsp;note
										</Button>
									}
									{
										!linkToClient ? null :
										<Button size="small" color="primary" onClick={this.onClientClick.bind(this)}>
											{getText('client-view_client')}
										</Button>
									}
									{
										!canEdit ? null :
										<Button size="small" color="secondary" onClick={this.closeMeeting.bind(this, meeting)}>
											{getText('action-close')}
										</Button>
									}
								</CardActions>
							</Fragment>
						}
					</Card>
				</Box>
				{this.renderForms()}
			</Fragment>
		);
	}

	onLocationClick() {
		const { meeting } = this.props;
		const onPhone = isOnPhone(meeting);

		// https://www.google.com/maps/search/?api=1&query=centurylink+field
		if(onPhone) {
			window.open("tel:" + meeting.location)
		} else {
			window.open("https://www.google.com/maps/search/?api=1&query=" + meeting.location);
		}
	}

	onClientClick() {
		const { history, meeting } = this.props;

		history.push('/client/' + meeting.client.id);
	}

	closeMeeting(meeting) {
        this.props.closeMeeting(meeting);
	}

	/**
	 * Reminder Form
	 */
	renderReminderForm() {
		const {reminderFormOpened, reminderFormData} = this.state;

		if(!reminderFormOpened) {
			return null;
		}

		return (
			<ReminderForm
				closeForm={this.closeReminderForm.bind(this)}
				updateReminder={this.updateReminder.bind(this)}
				{...reminderFormData}
			/>
		);
	}

	openReminderForm(id, e) {
		const { meeting } = this.props;

		this.setState({
			reminderFormOpened: true,
			reminderFormData: {
				id: null,
				clientId: meeting.client.id,
			}
		});
	}

	closeReminderForm(e) {
		this.setState({
			reminderFormOpened: false,
		});
	}

	updateReminder(reminder) {
		// this.setState(updateRelation(this.state, 'reminders', reminder));
	}

	/**
	 * Note form
	 */
	openNoteForm() {
		this.setState({
			noteFormOpened: true,
			note: '',
		});
	}

	closeNoteForm(e) {
		this.setState({
			noteFormOpened: false,
		});
	}

	saveNote(e) {
		const { client } = this.props.meeting;

        Api.call('put', '/clients/' + client._id + '/notes', {
			notes: `Meeting (${this.props.meeting.date}) : ${this.state.note}`,
			client_meeting: this.props.meeting.id,
		})
            .then((response) => {
                /*this.props.updateClient(response.data);
                this.refreshHistory(attribute);*/
				this.closeNoteForm();
            })
            .catch(error => {

            });
	}
}

export default withRouter(UnfilledMeetingCard);
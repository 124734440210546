import * as _ from 'lodash';
import {removeAccents} from './StringHelper';
/*
filterList(meetings, filters, [
    'client.name',
    'client.address.city',
    'notes',
    'location',
    'date',
    'type',
], {
    priorityAttributes: [
        'client.name'
    ]
})
*/

export function filterList(list, filters, attributes = [], options = {}) {
    if(!filters) {
        return list;
    }

    // Create default options
    options = {
        priorityAttributes: null,
        ...options,
    }

    // Filter list
    let filteredList = list?.filter((item) => {
        let hasSearch = false;
        let i = 0;

        // Filter text search
        if(filters.search) {
            hasSearch = true;
            for(i = 0; i < attributes.length; i++) {
                const attribute = attributes[i];

                const value = _.get(item, attribute, '')?.toString().toLowerCase() || '';
                const search = filters.search.toLowerCase();

                const valueWithoutAccent = removeAccents(value);
                const searchWithoutAccent = removeAccents(search);

                if(valueWithoutAccent.indexOf(searchWithoutAccent) >= 0) {
                    return true;
                }
            }
        }

        return !hasSearch;
    });

    // Sort list
    if(options.priorityAttributes) {

    }
    

    return filteredList;
}

function filterItems() {
    
}

function sortItems() {

}
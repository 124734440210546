import React, { Component } from 'react';
import { withRouter } from 'react-router';
import MainLayout from './MainLayout';

import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PersonIcon from '@material-ui/icons/Person';
import StreetviewIcon from '@material-ui/icons/Streetview';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import BuildIcon from '@material-ui/icons/Build';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';

class AdminLayout extends Component {

    static defaultProps = {
        breadcrumbs: [],
        title: [],
    };

    static menuItems = [
        {
            icon: <DashboardIcon />,
            label: 'Dashboard',
            path: '/',
            visible: true,
            disabled: false,
        },
        {
            icon: <TrendingUpIcon />,
            label: 'Reports',
            path: '/reports',
            visible: true,
            disabled: false,
        },
        {
            icon: <PersonIcon />,
            label: 'Users',
            path: '/users',
            visible: true,
            disabled: false,
        },
        {
            icon: <ImportContactsIcon />,
            label: 'Activities',
            path: '/activities',
            visible: true,
            disabled: false,
        },
        {
            icon: <StreetviewIcon />,
            label: 'Provinces',
            path: '/provinces',
            visible: true,
            disabled: true,
        },
        {
            icon: <BuildIcon />,
            label: 'Equipment Brands',
            path: '/equipment-brands',
            visible: true,
            disabled: true,
        },
        {
            icon: <BuildIcon />,
            label: 'Equipment Distributors',
            path: '/equipment-distributors',
            visible: true,
            disabled: true,
        },
        {
            icon: <BuildIcon />,
            label: 'Equipment Types',
            path: '/equipment-types',
            visible: true,
            disabled: true,
        },
        {
            // Algorythm configurations, translations, pagination, etc.
            icon: <SettingsIcon />,
            label: 'Settings',
            path: '/settings',
            visible: true,
            disabled: true,
        },
    ];

    constructor(props) {
        super(props);
        this.menuItems = AdminLayout.menuItems;
        // get path name
        const path = props.location.pathname;
        const pathNameParts = path.split('/');
        const pathName = pathNameParts[2] ? pathNameParts[2] : "";

        const activeMenuItem = this.menuItems.findIndex((item) => item.path === "/" + pathName);

        this.state = {
            activeMenuItem: activeMenuItem,
        };
    }

    renderBreadcrumbs() {
        const breadcrumbs = [...this.props.breadcrumbs];

        breadcrumbs.unshift({
            label: 'Admin',
            url: '',
        });

        return (
            <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbs.map((menuItem, i) => {
                    const { label, url } = menuItem;

                    if (i === breadcrumbs.length - 1) {
                        return (
                            <Typography color="textPrimary" key={i}>
                                {label}
                            </Typography>
                        );
                    }

                    return (
                        <Link
                            key={i}
                            href={url}
                            color="inherit"
                            onClick={this.onBreadcrumbItemClick.bind(this, menuItem)}>
                            {menuItem.label}
                        </Link>
                    );
                })}
            </Breadcrumbs>
        );
    }

    renderMenu() {
        return (
            <Box>
                <Tabs
                    value={this.state.activeMenuItem}
                    indicatorColor="primary"
                    scrollButtons="auto"
                    variant="scrollable"
                    aria-label="scrollable auto tabs example">
                    {this.menuItems.map((menuItem, index) =>
                        typeof menuItem.visible === 'undefined' || menuItem.visible && (
                            <Tab
                                key={index}
                                icon={menuItem.icon}
                                label={menuItem.label}
                                disabled={menuItem.disabled}
                                {...this.tabProps(index)}
                                onClick={() => {
                                    this.onMenuItemClick(menuItem);
                                    this.setState({ activeMenuItem: index });
                                }}
                            />
                        ) 
                    )}
                </Tabs>
            </Box>
        );
    }

    render() {
        return (
            <MainLayout title="Admin">
                <Box m={1} className="admin-container">
                    <Grid style={{ marginBottom: 20 }}>
                        <Card>
                            <CardContent>{this.renderMenu()}</CardContent>
                        </Card>
                    </Grid>
                    <Grid>
                        <Card>
                            <CardContent>
                                {this.renderBreadcrumbs()}
                                <Typography variant="h5" gutterBottom>
                                    {this.props.title}
                                </Typography>
                                {this.props.children}
                            </CardContent>
                        </Card>
                    </Grid>
                </Box>
            </MainLayout>
        );
    }

    tabProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    onBreadcrumbItemClick(menuItem, e) {
        const { history } = this.props;

        history.push('/admin' + menuItem.url);

        e.preventDefault();
        e.stopPropagation();
    }

    onMenuItemClick(menuItem, e) {
        const { history } = this.props;

        if (menuItem.external) {
            window.open(menuItem.path, '_blank');
        } else {
            history.push('/admin' + menuItem.path);
        }
    }
}

export default withRouter(AdminLayout);

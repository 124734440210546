import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import EmailIcon from '@material-ui/icons/Email';
import RoomIcon from '@material-ui/icons/Room';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { format } from 'date-fns';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { updateRelation, deleteRelation } from '../../../utils/ObjectHelper';

import Api from '../../../api/Api';
import ClientForm from '../../form/ClientForm';
import ContactForm from '../../form/ContactForm';
import { getText } from '../../../utils/LocaleHelper';
import { confirm } from '../../../utils/UIHelper';
import { withUser } from '../../../utils/ReactWrappers';
import EmptyList from '../../layout/EmptyList';
import AddressForm from '../../form/AddressForm';
import ClientScoreToken from '../../widgets/ClientScoreToken';
import ClientFactory from '../../../factory/ClientFactory';

class ClientIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clientFormOpened: false,
            contactFormOpened: false,
            contactFormId: null,
            notesHistory: [],
            objectivesHistory: [],
            historyPopupOpened: false,
            historyPopupType: null,
            addressFormOpened: false,
            addressFormIndex: null,
            meetingStatus: null,
            notesValue: '',
            historyItemOnEditMode: null,
        };
    }

    render() {
        return (
            <Box>
                {this.renderInfoCard()}
                {this.renderNotesCard('objectives')}
                {this.renderNotesCard('notes')}
                {this.renderAddresses()}
                {this.renderContacts()}
                {this.renderClientForm()}
                {this.renderContactForm()}
                {this.renderHistoryPopup()}
                {this.renderAddressForm()}
            </Box>
        );
    }

    renderAddressForm() {
        const { client } = this.props;
        const { addressFormOpened, addressFormIndex } = this.state;

        if (!addressFormOpened) {
            return null;
        }

        return (
            <AddressForm
                index={addressFormIndex}
                client={client}
                updateClient={this.props.updateClient}
                closeForm={this.closeAddressForm.bind(this)}
            />
        );
    }

    renderClientForm() {
        const { client } = this.props;
        const { clientFormOpened } = this.state;

        if (!clientFormOpened) {
            return null;
        }

        return (
            <ClientForm
                id={client._id}
                closeForm={this.closeClientForm.bind(this)}
                updateClient={this.props.updateClient}
                user={this.props.user}
            />
        );
    }

    renderContactForm() {
        const { client } = this.props;
        const { contactFormOpened, contactFormId } = this.state;

        if (!contactFormOpened) {
            return null;
        }

        return (
            <ContactForm
                id={contactFormId}
                client={client}
                closeForm={this.closeContactForm.bind(this)}
                updateContact={this.updateContact.bind(this)}
            />
        );
    }

    renderInfoCard() {
        const { client } = this.props;
        const { sales_representative, name, region } = client;
        const { meetingStatus } = this.state;

        const infoTitleStyle = {
            textTransform: 'uppercase',
            fontSize: '0.9em',
        };

        return (
            <Box m={1}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            {name} <ClientScoreToken client={client} />
                        </Typography>
                        <Typography>
                            Rep.:{' '}
                            {sales_representative
                                ? sales_representative.first_name +
                                  ' ' +
                                  sales_representative.last_name
                                : 'N/D'}
                        </Typography>
                    </CardContent>
                    <Divider />
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h4">
                            {getText('client-informations')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={4}>
                                <Typography variant="subtitle2" style={infoTitleStyle}>
                                    {getText('client-region')}
                                </Typography>
                                <Typography variant="body2">
                                    {region ? region.name : 'N/D'}
                                    <br />
                                    Pop. {client.population || 'N/D'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="subtitle2" style={infoTitleStyle}>
                                    {getText('client-firestation_count')}
                                </Typography>
                                <Typography variant="body2">
                                    {client.fire_station_count ? client.fire_station_count : 'N/D'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="subtitle2" style={infoTitleStyle}>
                                    {getText('client-fireman_count')}
                                </Typography>
                                <Typography variant="body2">
                                    {getText('client-full_time')} :{' '}
                                    {client.firefighters_full_time_count
                                        ? client.firefighters_full_time_count
                                        : 'N/D'}
                                </Typography>
                                <Typography variant="body2">
                                    {getText('client-part_time')} :{' '}
                                    {client.firefighters_part_time_count
                                        ? client.firefighters_part_time_count
                                        : 'N/D'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="subtitle2" style={infoTitleStyle}>
                                    {getText('client-meeting_count')}
                                </Typography>
                                <Typography variant="body2">
                                    {!meetingStatus
                                        ? '-'
                                        : `${meetingStatus.count} sur ${meetingStatus.target}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="subtitle2" style={infoTitleStyle}>
                                    Score
                                </Typography>
                                <Typography variant="body2">
                                    {client.score ? client.score.total || 'N/D' : 'N/D'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="subtitle2" style={infoTitleStyle}>
                                    Fidelio IDs
                                </Typography>
                                <Typography variant="body2">
                                    {client.fidelio_ids.length > 0
                                        ? client.fidelio_ids.map((id) => id.fidelio_id).join(', ')
                                        : 'N/D'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    {!this.props.canEdit ? null : (
                        <Fragment>
                            <Divider />
                            <CardActions>
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={this.openClientForm.bind(this)}>
                                    {getText('action-edit')}
                                </Button>
                                {this.props.canDelete && (
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={this.deleteClient.bind(this)}>
                                        {getText('action-delete')}
                                    </Button>
                                )}
                            </CardActions>
                        </Fragment>
                    )}
                </Card>
            </Box>
        );
    }

    renderNotesCard(type) {
        const { client } = this.props;
        //format(date, 'yyyy-MM-dd')
        const history = this.state[type + 'History'];
        let hint = null;

        if (history.length > 0) {
            const baseHint =
                'Last updated by ' +
                history[0].user.first_name +
                ' ' +
                history[0].user.last_name +
                ' at ' +
                format(Date.parse(history[0].modification_date), 'yyyy-MM-dd HH:mm');

            if (type === 'notes') {
                // notes details
                hint = getText('client-' + type + '-latest') + ' : ' + history[0].notes;
                hint += ' | ';
                hint += baseHint;
            } else {
                hint = baseHint;
            }
        }

        return (
            <Box m={1}>
                <Card>
                    <CardContent>
                        <Typography>{getText('client-' + type)}</Typography>
                        <TextField
                            label={getText('client-' + type + '_hints')}
                            id="outlined-notes"
                            helperText={hint}
                            variant="filled"
                            fullWidth={true}
                            multiline={true}
                            size="small"
                            value={type === 'notes' ? this.state.notesValue : client[type]}
                            onChange={this.onNotesChange.bind(this, type)}
                            InputProps={{
                                readOnly: !this.props.canEdit,
                            }}
                        />
                    </CardContent>
                    {!this.props.canEdit && history.length === 0 ? null : <Divider />}
                    <CardActions>
                        {!this.props.canEdit ? null : (
                            <Button
                                size="small"
                                color="primary"
                                onClick={this.updateNotes.bind(this, type)}>
                                {getText('action-save')}
                            </Button>
                        )}
                        {history.length === 0 ? null : (
                            <Button
                                size="small"
                                color="primary"
                                onClick={this.openHistoryPopup.bind(this, type)}>
                                {getText('client-view_history')}
                            </Button>
                        )}
                    </CardActions>
                </Card>
            </Box>
        );
    }

    renderHistoryPopup() {
        const { historyPopupOpened, historyPopupType, historyItemOnEditMode } = this.state;

        const history = this.state[historyPopupType + 'History'];
        
        return (
            <Dialog
                onClose={this.closeHistoryPopup.bind(this)}
                disableEscapeKeyDown={true}
                maxWidth="md"
                fullWidth={true}
                open={historyPopupOpened}
                disableEnforceFocus>
                <DialogTitle>{getText('client-' + historyPopupType)}</DialogTitle>
                <DialogContent>
                    {!history
                        ? null
                        : history.map((entry, i) => {
                              let hint =
                                  history[0].user.first_name + ' ' + history[0].user.last_name;
                              hint +=
                                  ' at ' +
                                  format(
                                      Date.parse(history[0].modification_date),
                                      'yyyy-MM-dd HH:mm',
                                  );
                              return (
                                  <Fragment key={i}>
                                      <Box
                                          m={1}
                                          sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                          <Box
                                              sx={{
                                                  display: 'flex',
                                                  justifyContent: 'space-between',
                                                  flexDirection: 'column',
                                              }}>
                                              {historyItemOnEditMode === entry.id ? (
                                                  <TextField
                                                      size="small"
                                                      value={entry[historyPopupType]}
                                                      onChange={this.onHistoryItemChange.bind(
                                                          this,
                                                          entry.id,
                                                      )}></TextField>
                                              ) : (
                                                  <Typography variant="body2">
                                                      {entry[historyPopupType]}
                                                  </Typography>
                                              )}

                                              <Typography
                                                  variant="caption"
                                                  style={{ opacity: 0.54 }}>
                                                  {hint}
                                              </Typography>
                                          </Box>
                                          <div>
                                              {historyItemOnEditMode === entry.id ? (
                                                  <Button
                                                      size="small"
                                                      color="primary"
                                                      onClick={this.updateHistoryItem.bind(
                                                          this,
                                                          entry.id,
                                                      )}>
                                                      {getText('action-save')}
                                                  </Button>
                                              ) : (
                                                  <Button
                                                      size="small"
                                                      color="primary"
                                                      onClick={this.updateHistoryItemEditMode.bind(
                                                          this,
                                                          entry.id,
                                                      )}>
                                                      {getText('action-edit')}
                                                  </Button>
                                              )}

                                              <Button
                                                  size="small"
                                                  color="secondary"
                                                  onClick={this.deleteNote.bind(this, entry.id)}>
                                                  {getText('action-delete')}
                                              </Button>
                                          </div>
                                      </Box>
                                      <Divider />
                                  </Fragment>
                              );
                          })}
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        color="primary"
                        onClick={this.closeHistoryPopup.bind(this)}>
                        {getText('action-close')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderAddresses() {
        const addresses = this.props.client.address;

        return (
            <Box>
                <Box m={1}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h6">{getText('client-addresses')}</Typography>
                        </Grid>
                        {!this.props.canEdit ? null : (
                            <Grid item>
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={this.openAddressForm.bind(this, null)}>
                                    {getText('action-create')}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>

                <Grid container>
                    {addresses.length > 0 ? null : (
                        <EmptyList message={getText('address-empty_list')} />
                    )}
                    {addresses.map((address, i) => (
                        <Grid item key={i} xs={12} sm={12} md={6} lg={4}>
                            <Box m={1}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6">
                                            {address.name ||
                                                address.address1 + ' ' + address.address2}
                                        </Typography>
                                        <Typography variant="body2">
                                            {address.address1} {address.address2}
                                        </Typography>
                                        <Typography variant="body2">
                                            {address.city}, {address.province}
                                        </Typography>
                                        <Typography variant="body2">
                                            {address.postal_code}, {address.country}
                                        </Typography>
                                    </CardContent>
                                    <Divider />
                                    <CardActions>
                                        <Button
                                            size="small"
                                            color="primary"
                                            onClick={this.openAddressForm.bind(this, i)}>
                                            {getText('action-edit')}
                                        </Button>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            onClick={this.deleteAddress.bind(this, i)}>
                                            {getText('action-delete')}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    }

    renderContacts() {
        const { client } = this.props;
        const { client_contacts } = client;

        const textFieldStyle = {
            marginLeft: '5px',
        };

        return (
            <Box>
                <Box m={1}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h6">Contacts</Typography>
                        </Grid>
                        {!this.props.canEdit ? null : (
                            <Grid item>
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={this.openContactForm.bind(this, null)}>
                                    {getText('action-create')}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>

                <Grid container>
                    {client_contacts.length > 0 ? null : (
                        <EmptyList message={getText('contact-empty_list')} />
                    )}
                    {client_contacts.map((contact, i) => {
                        const address = client.address.find((add) => add.id === contact.address_id);

                        return (
                            <Grid item key={i} xs={12} sm={12} md={6} lg={4}>
                                <Box m={1}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">{contact.name}</Typography>
                                            <Typography variant="subtitle1">
                                                {contact.position}
                                            </Typography>

                                            <Grid
                                                container
                                                justifyContent="space-between"
                                                spacing={1}
                                                style={{ marginTop: '10px' }}>
                                                <Grid item xs={6}>
                                                    <Grid container alignItems="center">
                                                        <PhoneIcon />
                                                        <Typography
                                                            variant="body2"
                                                            style={textFieldStyle}>
                                                            {contact.phone || 'N/D'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container alignItems="center">
                                                        <PhoneAndroidIcon />
                                                        <Typography
                                                            variant="body2"
                                                            style={textFieldStyle}>
                                                            {contact.mobile || 'N/D'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container alignItems="center">
                                                        <EmailIcon />
                                                        <Typography
                                                            variant="body2"
                                                            style={textFieldStyle}>
                                                            {contact.email || 'N/D'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container alignItems="center">
                                                        <RoomIcon />
                                                        <Typography
                                                            variant="body2"
                                                            style={textFieldStyle}>
                                                            {address
                                                                ? address.name ||
                                                                  address.address1 +
                                                                      ' ' +
                                                                      address.address2
                                                                : 'N/D'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        {!this.props.canEdit ? null : (
                                            <Fragment>
                                                <Divider />
                                                <CardActions>
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        onClick={this.openContactForm.bind(
                                                            this,
                                                            contact._id,
                                                        )}>
                                                        {getText('action-edit')}
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        color="secondary"
                                                        onClick={this.deleteContact.bind(this, contact)}>
                                                        {getText('action-delete')}
                                                    </Button>
                                                </CardActions>
                                            </Fragment>
                                        )}
                                    </Card>
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        );
    }

    // Notes
    onNotesChange(attribute, e) {
        const field = e.target;
        const value = field.value;
        const data = {};

        data[attribute] = value;

        if (attribute === 'notes') {
            this.setState({
                notesValue: value,
            });
        }
        this.props.updateClient(data);
    }

    // Notes history
    onHistoryItemChange(id, e) {
        const field = e.target;
        const value = field.value;
        const { historyPopupType } = this.state;

        const historyType = this.state[`${historyPopupType}History`];

        const index = historyType.findIndex((item) => item._id === id);
        historyType[index][historyPopupType] = value;

        this.setState({
            [`${historyPopupType}History`]: historyType,
        });
    }

    updateHistoryItemEditMode(id) {

        this.setState({
            historyItemOnEditMode: id
        });
    }

    updateHistoryItem(id) {
        const { historyPopupType } = this.state;

        const { client } = this.props;

        const value = this.state[`${historyPopupType}History`].find((item) => item._id === id)[
            historyPopupType
        ];

        Api.call('put', `/clients/${client._id}/${historyPopupType}-history/${id}`, {
            [historyPopupType]: value,
        }).then((response) => {
            this.refreshHistory(historyPopupType);
            this.props.updateClient(response.data[0]);
        }); 
        
        this.setState({
            historyItemOnEditMode: null
        });
    }

    updateNotes(attribute) {
        const { client } = this.props;
        const data = {};

        data[attribute] = client[attribute];

        Api.call('put', '/clients/' + client._id + '/notes', data)
            .then((response) => {
                this.props.updateClient(response.data);
                this.refreshHistory(attribute);
            })
            .catch((error) => {});

        if (attribute === 'notes') {
            this.setState({
                notesValue: '',
            });
        }
    }

    refreshHistory(type) {
        const { client } = this.props;

        Api.call('get', '/clients/' + client._id + `/${type}-history`)
            .then((response) => {
                const data = {};
                data[type + 'History'] = response.data;

                this.setState(data);
            })
            .catch((error) => {});
    }

    openHistoryPopup(type) {
        this.setState({
            historyPopupOpened: true,
            historyPopupType: type,
        });
    }

    closeHistoryPopup() {
        this.setState({
            historyPopupOpened: false,
        });
    }

    // Forms
    openClientForm(e) {
        this.setState({
            clientFormOpened: true,
        });
    }

    closeClientForm(e) {
        this.setState({
            clientFormOpened: false,
        });
    }

    openContactForm(id, e) {
        this.setState({
            contactFormOpened: true,
            contactFormId: id,
        });
    }

    closeContactForm(e) {
        this.setState({
            contactFormOpened: false,
        });
    }

    openAddressForm(index, e) {
        this.setState({
            addressFormOpened: true,
            addressFormIndex: index,
        });
    }

    closeAddressForm(e) {
        this.setState({
            addressFormOpened: false,
        });
    }

    // Contact
    updateContact(contact) {
        const client = { ...this.props.client };

        this.props.updateClient(updateRelation(client, 'client_contacts', contact));
    }

    deleteContact(contact) {
        const client = { ...this.props.client };

        confirm({
            title: getText('contact-delete_title') + ' ' + contact.name,
            text: getText('contact-delete_message'),
            confirmLabel: getText('action-yes'),
            cancelLabel: getText('action-no'),
            onClose: (confirm) => {
                if (confirm) {
                    Api.call('delete', '/client-contacts/' + contact._id).then((response) => {
                        this.props.updateClient(
                            deleteRelation(client, 'client_contacts', contact._id),
                        );
                    });
                }
            },
        });
    }

    deleteNote(index) {
        confirm({
            title: getText('client-notes-delete-title'),
            text: getText('client-notes-delete-message'),
            confirmLabel: getText('action-yes'),
            cancelLabel: getText('action-no'),
            onClose: (confirm) => {
                if (confirm) {
                    Api.call('delete', '/clients/' + this.props.client.id + '/notes/' + index).then(
                        (response) => {
                            this.props.updateClient(response.data);

                            this.setState({
                                historyPopupOpened: false,
                            });

                            this.refreshHistory('notes');
                        },
                    );
                }
            },
        });
    }

    // Address
    deleteAddress(index) {
        confirm({
            title: getText('address-delete_title'),
            text: getText('address-delete_message'),
            confirmLabel: getText('action-yes'),
            cancelLabel: getText('action-no'),
            onClose: (confirm) => {
                if (confirm) {
                    const data = [...this.props.client.address];
                    data.splice(index);

                    // Termporary remove the address
                    this.props.updateClient({ address: data });

                    Api.call('put', '/clients/' + this.props.client.id + '/address', {
                        address: data,
                    })
                        .then((response) => {
                            this.props.updateClient(response.data);
                        })
                        .catch((error) => {});
                }
            },
        });
    }

    deleteClient() {
        confirm({
            title: getText('client-delete_title'),
            text: getText('client-delete_infos'),
            confirmLabel: getText('action-delete'),
            cancelLabel: getText('action-cancel'),
            onClose: (confirm) => {
                if (confirm) {
                    const { client } = this.props;
                    ClientFactory.forceRefresh();

                    Api.call('delete', '/clients/' + client._id).then((response) => {
                        // reload the page

                        this.props.history.push('/clients');
                    });
                }
            },
        });
    }

    // Life cycle
    componentDidMount() {
        this.refreshHistory('notes');
        this.refreshHistory('objectives');

        // Get meeting status
        Api.call('get', '/clients/' + this.props.client.id + '/meeting-status')
            .then((response) => {
                this.setState({
                    meetingStatus: response.data,
                });
            })
            .catch((error) => {});
    }
}

export default withRouter(withUser(ClientIndex));

import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Api from '../../api/Api';
import ClientIndex from './client/ClientIndex';
import ClientProjects from './client/ClientProjects';
import ClientMeetings from './client/ClientMeetings';
import ClientEquipment from './client/ClientEquipment';
import ClientReminders from './client/ClientReminders';
import { getText } from '../../utils/LocaleHelper';
import './Client.scss';
import { withUser } from '../../utils/ReactWrappers';
import ClientInvoices from './client/ClientInvoices';
import Loader from '../widgets/Loader';
import searchIcon from '../../images/search-image.svg';
class Client extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            client: null,
            canEdit: props.user.role.type !== 'consultation',
            canDelete: ['admin', 'sales_representative'].includes(props.user.role.type),
        };
    }

    render() {
        return this.renderPage();
    }

    renderPage() {
        const { isLoading, canEdit, canDelete } = this.state;
        const match = this.props.match;

        if (isLoading) {
            return <Loader />;
        }

        if (!this.state.client) {
            return (
                <Container
                    maxWidth="sm"
                    style={{
                        padding: 50,
						margin: '25% auto'
                    }}>
                    <Typography
                        variant="h5"
                        style={{
                            textAlign: 'center',
                        }}>
                        {getText('client-empty-infos')}
                    </Typography>
                    <img
                        src={searchIcon}
                        alt="search"
                        style={{
                            width: '80%',
                            height: 'auto',
                            margin: 'auto',
                            display: 'block',
                            marginTop: 100,
                        }}
                    />
                </Container>
            );
        }
        return (
            <Switch>
                <Route exact path={`${match.path}`}>
                    <ClientIndex
                        client={this.state.client}
                        canEdit={canEdit}
                        canDelete={canDelete}
                        updateClient={this.updateClient.bind(this)}
                    />
                </Route>
                <Route exact path={`${match.path}/visits`}>
                    <ClientMeetings client={this.state.client} canEdit={canEdit} />
                </Route>
                <Route exact path={`${match.path}/projects`}>
                    <ClientProjects client={this.state.client} canEdit={canEdit} />
                </Route>
                <Route exact path={`${match.path}/equipment`}>
                    <ClientEquipment client={this.state.client} canEdit={canEdit} />
                </Route>
                <Route exact path={`${match.path}/invoices`}>
                    <ClientInvoices client={this.state.client} canEdit={canEdit} />
                </Route>
                <Route exact path={`${match.path}/reminders`}>
                    <ClientReminders client={this.state.client} canEdit={canEdit} />
                </Route>
            </Switch>
        );
    }

    updateClient(data) {
        const client = {
            ...this.state.client,
            ...data,
        };

        this.setState({
            client: client,
        });
    }

    componentDidMount() {
        const params = this.props.match.params;

        if(!params.id) {
            return;
        }

        Promise.allSettled([
            //Api.call('get', '/regions2'),
            Api.call('get', '/clients/' + params.id),
        ]).then((results) => {
            const state = {
                isLoading: false,
            };

            if (results[0].status === 'fulfilled') {
                state.client = results[0].value.data;
            }

            this.setState(state);
        });
    }

    onLinkClick(url, e) {
        const { history } = this.props;

        history.push('/client/' + this.state.client.id + url);
    }
}

export default withRouter(withUser(Client));

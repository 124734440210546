import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Page from './layout/Page';
import * as UIHelper from '../utils/UIHelper';

// Pages
import Login from './pages/Login';
import Logout from './pages/Logout';
import Dashboard from './pages/Dashboard';
import Map from './pages/Map';
import Clients from './pages/Clients';
import Meetings from './pages/Meetings';
import Reminders from './pages/Reminders';
import Projects from './pages/Projects';
import MyProfile from './pages/MyProfile';

// Page - client
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Admin from './pages/Admin';
import axios from 'axios';

class App extends Component {
    constructor(props) {
        super(props);

        this.alertId = 0;

        this.state = {
            alerts: [],
            confirmationPopupOpened: false,
            confirmationPopup: {},
        };

        axios.get('https://geolocation-db.com/json/').then((response) => {
            window.ipAddress = response.data.IPv4 || response.data.IPv6 || null;
        });

        UIHelper.setAlertMethod(this.showAlert.bind(this));
        UIHelper.setConfirmMethod(this.showConfirmationPopup.bind(this));
    }

    render() {
        const { alerts } = this.state;

        return (
            <Router>
                {alerts.map((alertObj) => {
                    return (
                        <Snackbar
                            ClickAwayListenerProps={null}
                            open={alertObj.opened}
                            autoHideDuration={4000}
                            onClose={this.handleClose.bind(this, alertObj)}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                            <Alert
                                onClose={this.handleClose.bind(this, alertObj)}
                                severity={alertObj.type}>
                                {alertObj.message}
                            </Alert>
                        </Snackbar>
                    );
                })}
                {this.renderConfirmationPopup()}
                <Page isPublic={true}>
                    <Switch>
                        <Route exact path="/">
                            <Login />
                        </Route>
                        <Route exact path="/logout">
                            <Logout />
                        </Route>
                        <Route path="/dashboard">
                            <Dashboard />
                        </Route>
                        <Route path="/map">
                            <Map />
                        </Route>
                        <Route path="/clients">
                            <Clients />
                        </Route>
                        <Route path="/client/:id">
                            <Clients view={true} />
                        </Route>
                        <Route path="/meetings">
                            <Meetings />
                        </Route>
                        <Route path="/reminders">
                            <Reminders />
                        </Route>
                        <Route path="/projects">
                            <Projects />
                        </Route>
                        <Route path="/my-profile">
                            <MyProfile />
                        </Route>
                        <Route path="/admin">
                            <Admin />
                        </Route>
                    </Switch>
                </Page>
            </Router>
        );
    }

    renderConfirmationPopup() {
        const { confirmationPopup, confirmationPopupOpened } = this.state;

        const popupInfos = {
            title: null,
            text: '',
            confirmLabel: 'OK',
            cancelLabel: 'Cancel',
            onClose: (confirm) => {},
            ...confirmationPopup,
        };

        return (
            <Dialog open={confirmationPopupOpened} onClose={this.closeConfirmationPopup.bind(this)}>
                {popupInfos.title ? <DialogTitle>{popupInfos.title}</DialogTitle> : null}
                <DialogContent>
                    <DialogContentText>{popupInfos.text}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            this.closeConfirmationPopup();
                            popupInfos.onClose(false);
                        }}
                        color="secondary">
                        {popupInfos.cancelLabel}
                    </Button>
                    <Button
                        onClick={() => {
                            this.closeConfirmationPopup();
                            popupInfos.onClose(true);
                        }}
                        color="primary">
                        {popupInfos.confirmLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    closeConfirmationPopup() {
        this.setState({
            confirmationPopupOpened: false,
        });
    }

    showConfirmationPopup(data = {}) {
        this.setState({
            confirmationPopupOpened: true,
            confirmationPopup: data,
        });
    }

    showAlert(type, message) {
        this.alertId++;

        const alerts = [...this.state.alerts];

        alerts.push({
            message: message,
            type: type,
            opened: true,
            id: this.alertId,
        });

        this.setState({
            alerts: alerts,
        });
    }

    componentDidMount() {
        axios.get('https://geolocation-db.com/json/').then((response) => {
            window.ipAddress = response.data.IPv4 || response.data.IPv6 || null;
        });
    }

    handleClose(alertObj, e, reason) {
        // Skip the click away trigger
        if (reason === 'clickaway') {
            return;
        }

        // Find the alert and close it
        let alerts = [...this.state.alerts];

        let alert = alerts.find((al) => {
            return al.id === alertObj.id;
        });

        alert.opened = false;

        this.setState({
            alerts: alerts,
        });

        // Remove the alert from the array after the fadeout animation
        setTimeout(() => {
            let alerts = [...this.state.alerts];

            let index;

            alerts.find((al, i) => {
                index = i;
                return al.id === alertObj.id;
            });

            alerts.splice(index, 1);

            this.setState({
                alerts: alerts,
            });
        }, 500);
    }
}

export default App;

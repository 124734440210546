import React, { Component } from 'react';
import { withRouter } from 'react-router';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import BuildIcon from '@material-ui/icons/Build';
import DescriptionIcon from '@material-ui/icons/Description';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import NotificationsIcon from '@material-ui/icons/Notifications';

import MainLayout from '../layout/MainLayout';
import Client from './Client';
import ClientList from '../lists/ClientList';
import { withMediaQuery, withUser } from '../../utils/ReactWrappers';
import DesktopTitleBar from '../layout/DesktopTitleBar';
import { getText } from '../../utils/LocaleHelper';

class Clients extends Component {
	static defaultProps = {
        view: false,
    }

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			clients: null,
			regions: null,
			filters: null,
			clientTitle: '',
		}
	}

	render() {
		const { isDesktop } = this.props;
		const params = this.props.match.params;
		const view = this.props.view || isDesktop;
		const bottomNav = view && !isDesktop ? this.renderNav() : null;
		const onCloseClick = view ? this.onCloseClick.bind(this) : null;
		const title = "Clients"; //this.state.client ? this.state.client.name : ''

		return (
			<MainLayout
				title={title}
				bottomNavBar={bottomNav}
				onClose={onCloseClick}
				showTitleBar={false}
			>
				<Grid container>
					{this.renderClientList(isDesktop, view)}
					{this.renderClientView(isDesktop, view, params.id)}
				</Grid>
			</MainLayout>
		);
	}

	renderClientList(isDesktop, view) {
		if(!isDesktop && view) {
			return null;
		}

		const style = {};

		if(isDesktop) {
			style.width = 375;
			style.height = '100vh';
			style.overflowY = 'auto';
			style.borderRight = '1px solid #d0d0d0';
			style.flexShrink = 0;
		} else {
			style.flexGrow = 1;
		}

		return (
			<Grid item style={style}>
				{
					!isDesktop ? null :
					<DesktopTitleBar
						title={getText('menu-clients')}
					/> 
				}
				<ClientList
					onClientViewClick={this.onClientViewClick.bind(this)}
					onLoad={this.onListLoad.bind(this)}
					user={this.props.user}
				/>
			</Grid>
		)
	}

	renderClientView(isDesktop, view, id) {
		const { clients } = this.state;
		const params = this.props.match.params;
		
		if(!view) {
			return null;
		}

		const style = {
			flex: 1,
		};

		if(isDesktop) {
			style.height = '100vh';
			style.overflowY = 'scroll';
		}

		const client = clients ? clients.find(client => client.id === params.id) : null;

		return (
			<Grid item style={style}>
				{
					isDesktop && client &&
					<DesktopTitleBar
						title={client ? client.name : ''}
						actions={this.renderNav()}
					/> 
				}
				<Client key={id} isDesktop={isDesktop} user={this.props.user} />
				{
					isDesktop ? null :
					<Box style={{height: 56}}></Box>
				}
			</Grid>
		)
	}

	renderNav() {
		const style = {
			width: '100%',
			position: 'fixed',
			bottom: 0,
			zIndex: 1,
		};
		const { isDesktop, location } = this.props;

		let currentValue = 0;
		let path = location.pathname.split('/');
		path = path[path.length - 1];

		switch(path) {
			case 'visits': 
				currentValue = 1;
				break;
			case 'projects': 
				currentValue = 2;
				break;
			case 'equipment': 
				currentValue = 3;
				break;
			case 'invoices': 
				currentValue = 4;
				break;
			default :
				currentValue = 0;
				break;
		}

		const actionStyle = {
			color: '#ffffff',
		}

		if(isDesktop) {
			return (
				<Grid container>
					<BottomNavigationAction showLabel={true} style={actionStyle} label={getText('menu-record')} icon={<StoreMallDirectoryIcon />} onClick={this.onLinkClick.bind(this, '/')} />
					<BottomNavigationAction showLabel={true} style={actionStyle} label={getText('menu-meetings')} icon={<WatchLaterIcon />} onClick={this.onLinkClick.bind(this, '/visits')} />
					<BottomNavigationAction showLabel={true} style={actionStyle} label={getText('menu-projects')} icon={<DescriptionIcon />} onClick={this.onLinkClick.bind(this, '/projects')} />
					<BottomNavigationAction showLabel={true} style={actionStyle} label={getText('menu-equipments')} icon={<BuildIcon />} onClick={this.onLinkClick.bind(this, '/equipment')} />
					<BottomNavigationAction showLabel={true} style={actionStyle} label={getText('menu-invoices')} icon={<MonetizationOnIcon />} onClick={this.onLinkClick.bind(this, '/invoices')} />
					<BottomNavigationAction showLabel={true} style={actionStyle} label={getText('menu-reminders')} icon={<NotificationsIcon />} onClick={this.onLinkClick.bind(this, '/reminders')} />
				</Grid>
			)
		}

		return (
			<Paper elevation={3}>
				<BottomNavigation
					showLabels
					style={style}
					value={currentValue}
				>
					<BottomNavigationAction /*label={getText('menu-record')}*/ icon={<StoreMallDirectoryIcon />} onClick={this.onLinkClick.bind(this, '/')} />
					<BottomNavigationAction /*label={getText('menu-meetings')}*/ icon={<WatchLaterIcon />} onClick={this.onLinkClick.bind(this, '/visits')} />
					<BottomNavigationAction /*label={getText('menu-projects')}*/ icon={<DescriptionIcon />} onClick={this.onLinkClick.bind(this, '/projects')} />
					<BottomNavigationAction /*label={getText('menu-equipments')}*/ icon={<BuildIcon />} onClick={this.onLinkClick.bind(this, '/equipment')} />
					<BottomNavigationAction /*label={getText('menu-invoices')}*/ icon={<MonetizationOnIcon />} onClick={this.onLinkClick.bind(this, '/invoices')} />
					<BottomNavigationAction /*label={getText('menu-invoices')}*/ icon={<NotificationsIcon />} onClick={this.onLinkClick.bind(this, '/reminders')} />
				</BottomNavigation>
			</Paper>
		)
	}

	onClientViewClick(client, e) {
		const { history } = this.props;

		history.push('/client/' + client.id);
	}

	onLinkClick(url, e) {
		const {history} = this.props;
		const params = this.props.match.params;

		history.push('/client/' + params.id + url);
	}

	onCloseClick(url, e) {
		const {history} = this.props;

		history.push('/clients');
	}

	onListLoad(clients, e) {
		this.setState({
			clients: clients,
		});
	}
}

export default withRouter(withUser(withMediaQuery([
    ['isDesktop', theme => theme.breakpoints.up('lg'), {
        defaultMatches: true
    }]
])(Clients)));

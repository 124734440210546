import React, {Component} from 'react';
import { withRouter } from 'react-router';
import { getText } from '../../utils/LocaleHelper';
import { withUser } from '../../utils/ReactWrappers';

import MainLayout from '../layout/MainLayout';
import MeetingList from '../lists/MeetingList';

class Meetings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			
		}
	}

	render() {
		return (
			<MainLayout title={getText('menu-meetings')}>
				<MeetingList linkToClient={true}/>
			</MainLayout>
		);
	}
}

export default withRouter(withUser(Meetings));

import React, { Component } from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Svg, Path  } from '@react-pdf/renderer';
import { getText } from '../../utils/LocaleHelper';

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
    ]
})

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FFFFFF',
        fontSize: '8pt',
        fontFamily: 'Open Sans',
        flexDirection: 'column',
    },
    section: {
        margin: 10,
        padding: 10,
        flex: 1
    },
    separator: {
        borderBottom: '1px solid #000000',
        marginTop: '5px',
        marginBottom: '5px',
    },

    // Header
    header: {
        flexDirection: 'row',
    },

    // Header - Addresses
    logoContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: '10pt',
    },
    logo: {
        width: '90pt',
    },
    address: {
        borderTop: '1px solid #000000',
        marginBottom: '15pt',
    },
    addressTitle: {
        fontWeight: 700,
    },
    addressText: {
        marginLeft: '10pt',
    },

    // Header - Summary
    title: {
        fontSize: '20pt',
        fontWeight: 700,
    },
    summaryContainer: {
        flexDirection: 'row',
    },
    summaryInfoLabel: {
        fontWeight: 700,
        flex: 1,
    },
    summaryInfoValue: {
        flexGrow: 1,
        flex: 1,
    },

    // Content
    content: {
        borderTop: '1px solid #000000',
        margin: 20,
        flexGrow: 1,
    },
    lineItems: {
        flexDirection: 'row',
    },
    lineItemsColumn: {
        marginRight: 20,
        alignItems: 'flex-end',
    },
    lineItemsLabel: {
        fontWeight: 700,
        marginBottom: 10,
    },
    lineItemsCell: {
        marginBottom: 2,
    },

    // Footer
    footer: {
        borderTop: '1px solid #000000',
        margin: 20,
    },
    total: {
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    totalLine: {
        flexDirection: 'row',
    },
    totalLabel: {
        justifyContent: 'flex-end',
    },
    totalValue: {
        width: 60,
        alignItems: 'flex-end',
    },
});

class Invoice extends Component {
    constructor(props) {
		super(props);

		this.state = {

		}
	}

    render() {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        {this.renderAddresses()}
                        {this.renderSummary()}
                    </View>
                    <View style={styles.content}>
                        {this.renderLineItems()}
                    </View>
                    <View style={styles.footer}>
                        {this.renderTotal()}
                    </View>
                </Page>
            </Document>
        )
    }

    renderTotal() {
        const { transaction, lineItems } = this.props;

        // populate subtotal if empty
        if(!transaction.stot) {
            let stot = 0;

            for(let i = 0; i < lineItems.length; i++) {
                const lineItem = lineItems[i];

                stot += lineItem.prixrev;
            }

            transaction.stot = stot.toFixed(2);
        }

        return (
            <View style={styles.total}>
                <View style={styles.totalLine}>
                    <View style={styles.totalLabel}>
                        <Text>{getText('invoice_pdf-subtotal')}</Text>
                    </View>
                    <View style={styles.totalValue}>
                        <Text>{parseFloat(transaction.stot).toFixed(2)}</Text>
                    </View>
                </View>
                <View style={styles.totalLine}>
                    <View style={styles.totalLabel}>
                        <Text>TPS</Text>
                    </View>
                    <View style={styles.totalValue}>
                        <Text>{transaction.tps || (Math.round(transaction.stot * 0.05 * 100) / 100).toFixed(2)}</Text>
                    </View>
                </View>
                <View style={styles.totalLine}>
                    <View style={styles.totalLabel}>
                        <Text>TVQ</Text>
                    </View>
                    <View style={styles.totalValue}>
                        <Text>{transaction.tvq || (Math.round(transaction.stot * 0.09975 * 100) / 100).toFixed(2)}</Text>
                    </View>
                </View>
                <View style={{...styles.totalLine, marginTop: 5}}>
                    <View style={styles.totalLabel}>
                        <Text style={{fontWeight: 700}}>Total CAD</Text>
                    </View>
                    <View style={styles.totalValue}>
                        <Text style={{fontWeight: 700}}>{parseFloat(transaction.montant || transaction.total).toFixed(2)}</Text>
                    </View>
                </View>
            </View>
        );
    }

    renderLineItems() {
        const { lineItems, type } = this.props;

        let properties = [
            {
                label: 'in_items_id',
                attr: 'in_items_id',
                style: {
                    alignItems: 'flex-start',
                },
                cellStyle:  {
                    fontWeight: 700,
                },
            },
            {
                label: 'description',
                attr: 'description',
                style: {
                    flexGrow: 1,
                    alignItems: 'flex-start',
                },
            },
            type === 'quote' ? {
                label: 'delay',
                attr: 'delay',
            } : null,
            {
                label: 'in_items_um_id',
                attr: 'in_items_um_id',
                style: {
                    alignItems: 'center',
                },
            },
            {
                label: 'qte',
                attr: type === 'invoice' ? 'qteexpedie' : 'qte',
                format: (val) => val.toFixed(2),
            },
            {
                label: 'prix',
                attr: 'prix',
                format: (val) => val.toFixed(2),
            },
            {
                label: 'prixrev',
                attr: 'prixrev',
                format: (val) => val.toFixed(2),
                style: { 
                    marginRight: 0,
                }
            },
        ];

        properties = properties.filter(property => property !== null);

        // To create a table in React-PDF, loop through columns, and create all the cells within
        return (
            <View style={styles.lineItems}>
                {
                    properties.map((property, i) => {
                        const { label, attr } = property;
                        const style = property.style || {};
                        const cellStyle = property.cellStyle || {};

                        return (
                            <View style={{...styles.lineItemsColumn, ...style}} key={i}>
                                <Text style={{...styles.lineItemsLabel, ...cellStyle}}>{getText('invoice_pdf-'+label)}</Text>

                                {
                                    lineItems.map((lineItem, u) => {
                                        let value = !lineItem[attr] && lineItem[attr] !== 0 ? '' : lineItem[attr];

                                        if(property.format) {
                                            value = property.format(value);
                                        }
                                        
                                        return (
                                            <Text style={{...styles.lineItemsCell, ...cellStyle}} key={u}>
                                                {value}
                                            </Text>
                                        );
                                    })
                                }
                            </View>
                        );
                    })
                }
            </View>
        )
    }

    renderAddresses() {
        const { transaction } = this.props;

        return (
            <View style={styles.section}>
                <View style={styles.logoContainer}>
                    <View style={styles.logo}>
                        <Svg viewBox="0 0 368.4 409.34">
                            <Path d="M359.46,70C266.1-23.34,102.31-23.34,9,70,1.74,77.23,0,85.5,0,102.47v70.68C0,225,19.73,288.82,57.46,326.56S173,409.34,184.2,409.34s89-45,126.75-82.78S368.4,225,368.4,173.15V102.47C368.4,85.5,366.66,77.23,359.46,70ZM350.7,173.87c0,43.87-16.14,104.3-52.19,140.37-14.81,14.8-39,33.25-66.47,50.6-26,16.47-42.53,24.28-47.84,26.07-5.31-1.79-21.81-9.6-47.84-26.07C108.93,347.49,84.7,329,69.89,314.23c-36-36.06-52.19-96.48-52.19-140.36V104.8c0-16.16,1.9-19.27,4.79-22.16,41-41,99.91-64.47,161.71-64.47s120.74,23.5,161.72,64.47c2.89,2.89,4.78,6,4.78,22.16Z" fill="#231f20"/>
                            <Path d="M188.7,150.67a26.25,26.25,0,0,1,10.48,2.12,27,27,0,0,1,14.4,14.4,26.39,26.39,0,0,1,2.12,10.48v36a26.22,26.22,0,0,1-2.12,10.54,27.15,27.15,0,0,1-14.4,14.34,26.42,26.42,0,0,1-10.48,2.12h-9a26.22,26.22,0,0,1-10.54-2.12,27.32,27.32,0,0,1-14.34-14.34,26.22,26.22,0,0,1-2.12-10.54v-36a26.39,26.39,0,0,1,2.12-10.48,27.15,27.15,0,0,1,14.34-14.4,26.05,26.05,0,0,1,10.54-2.12Zm9,27a9,9,0,0,0-9-9h-9a9,9,0,0,0-9,9v36a9,9,0,0,0,9,9h9a9,9,0,0,0,9-9Z" fill="#231f20"/>
                            <Path d="M258.45,150.67a26.25,26.25,0,0,1,10.48,2.12,27,27,0,0,1,14.4,14.4,26.39,26.39,0,0,1,2.12,10.48v36a26.22,26.22,0,0,1-2.12,10.54,27.15,27.15,0,0,1-14.4,14.34,26.42,26.42,0,0,1-10.48,2.12h-9a26.22,26.22,0,0,1-10.54-2.12,27.32,27.32,0,0,1-14.34-14.34,26.22,26.22,0,0,1-2.12-10.54v-36a26.39,26.39,0,0,1,2.12-10.48,27.15,27.15,0,0,1,14.34-14.4,26.05,26.05,0,0,1,10.54-2.12Zm9,27a9,9,0,0,0-9-9h-9a9,9,0,0,0-9,9v36a9,9,0,0,0,9,9h9a9,9,0,0,0,9-9Z" fill="#231f20"/>
                            <Path d="M71.7,150.67H58.2A3.5,3.5,0,0,0,54.79,153c-.43.87-7.84,15.68-7.84,15.68h9v72H74V152.92A2.25,2.25,0,0,0,71.7,150.67Z" fill="#231f20"/>
                            <Path d="M331.72,158.37c-10.26-10.27-28.27-10.27-38.53,0-.8.79-1,1.7-1,3.56v7.77c0,5.7,2.17,12.72,6.32,16.87s12.7,9.1,13.93,9.1,9.78-4.95,13.93-9.1,6.32-11.17,6.32-16.87v-7.77C332.7,160.07,332.51,159.16,331.72,158.37ZM320.26,169.7c0,3.23-1.39,6.78-2.68,8.07a38.51,38.51,0,0,1-5.13,4.4,39.16,39.16,0,0,1-5.13-4.4c-1.29-1.29-2.68-4.84-2.68-8.07v-5c3.21-3.21,12.41-3.21,15.62,0Z" fill="#231f20"/>
                            <Path d="M143.7,222.67H109.45c2.17-2,27.54-25.85,29.15-27.63a35.92,35.92,0,0,0,4-5.33,22,22,0,0,0,3.32-12,26.39,26.39,0,0,0-2.12-10.48,27,27,0,0,0-14.4-14.4A26.25,26.25,0,0,0,119,150.67h-9a26.05,26.05,0,0,0-10.54,2.12,27.15,27.15,0,0,0-14.34,14.4A26.39,26.39,0,0,0,83,177.67v2.25l18-2.25a9,9,0,0,1,9-9h9a9,9,0,0,1,9,9c0,2.25-2.25,4.5-2.25,4.5L85.35,220.39c-1.79,1.7-2.4,3.22-2.4,4.52v15.76h63V224.92A2.25,2.25,0,0,0,143.7,222.67Z" fill="#231f20"/>
                        </Svg>
                    </View>
                    <View style={styles.section}>
                        <Text>162, rue Vachon</Text>
                        <Text>Trois-Rivières (Québec) G8T 1Z7</Text>
                        <Text>1 800 463-1590</Text>
                    </View>
                </View>
                <View style={styles.address}>
                    <Text style={styles.addressTitle}>{getText('invoice_pdf-bill_to')}</Text>
                    <Text style={styles.addressText}>{transaction.adresse.replaceAll("\r", "")}</Text>
                </View>
                <View style={styles.address}>
                    <Text style={styles.addressTitle}>{getText('invoice_pdf-ship_to')}</Text>
                    <Text style={styles.addressText}>{transaction.adresse_livr.replaceAll("\r", "")}</Text>
                </View>
            </View>
        );
    }

    renderSummary() {
        const { transaction, type } = this.props;
        let properties = [];

        switch(type) {
            // Invoice
            case 'invoice':
                properties = [
                    {
                        label: 'no_trans',
                        attr: 'no_trans',
                    },
                    {
                        label: 'date',
                        attr: 'date',
                        format: this.formatDate.bind(this),
                    },
                    {
                        label: 'reference',
                        attr: 'reference',
                    },
                    {
                        label: 'rep',
                        attr: 've_vendeurs_id',
                        format: this.getRepName.bind(this),
                    },
                    {
                        label: 'nocommande',
                        attr: 'nocommande',
                    },
                    {
                        label: 'nolivraison',
                        attr: 'nolivraison',
                    },
                ];
                break;
                
            // Quote
            case 'quote':
                properties = [
                    {
                        label: 'no_trans',
                        attr: 'no_trans',
                    },
                    {
                        label: 'date',
                        attr: 'date',
                        format: this.formatDate.bind(this),
                    },
                    {
                        label: 'date_echeance',
                        attr: 'date_echeance',
                        format: this.formatDate.bind(this),
                    },
                    {
                        label: 'rep',
                        attr: 've_vendeurs_id',
                        format: this.getRepName.bind(this),
                    },
                    {
                        label: 'reference_quote',
                        attr: 'reference',
                    },
                ];
                break;

            // Order
            case 'order':
                properties = [
                    {
                        label: 'no_trans',
                        attr: 'no_trans',
                    },
                    {
                        label: 'date',
                        attr: 'date',
                        format: this.formatDate.bind(this),
                    },
                    {
                        label: 'rep',
                        attr: 've_vendeurs_id',
                        format: this.getRepName.bind(this),
                    },
                    {
                        label: 'reference',
                        attr: 'reference',
                    },
                ];
                break;

            default:
                break;
        }

        return (
            <View style={styles.section}>
                <Text style={styles.title}>{getText('invoice-type_'+type)}</Text>
                <View style={styles.separator}></View>
                <View>
                    {
                        properties.map((property, i) => {
                            const { label, attr } = property;
                            let value = transaction[attr];

                            if(property.format) {
                                value = property.format(value);
                            }

                            return (
                                <View style={styles.summaryContainer} key={i}>
                                    <Text style={styles.summaryInfoLabel}>
                                        {getText('invoice_pdf-'+label)}
                                    </Text>
                                    <Text style={styles.summaryInfoValue}>
                                        {value}
                                    </Text>
                                </View>
                            );
                        })
                    }
                </View>
            </View>
        )
    }

    getRepName(id) {
        const { reps } = this.props;

        if(id) {
            const rep = reps.find(rep => rep.id === id);

            if(rep) {
                return rep.description;
            }
        }

        return '';
    }

    formatDate(value) {
        return value.split('T')[0];
    }
}

export default Invoice;
export function updateRelation(source, attr, data) {
    const attrObj = [...source[attr]];

    let isNew = true;

    for(let i = 0; i < attrObj.length; i++) {
        if(attrObj[i]._id === data._id) {
            attrObj[i] = data;
            isNew = false;
            break;
        }
    }

    if(isNew) {
        attrObj.push(data);
    }

    source[attr] = attrObj;

    return source;
}

export function deleteRelation(source, attr, id) {
    const attrObj = [...source[attr]];

    for(let i = 0; i < attrObj.length; i++) {
        if(attrObj[i]._id === id) {
            attrObj.splice(i, 1);
            break;
        }
    }

    source[attr] = attrObj;

    return source;
}